/* eslint-disable no-param-reassign */
import { uuid } from "vue-uuid";

export default {
  namespaced: true,
  state: {
    projects: [],
  },
  mutations: {
    setProjects(state, projects) {
      state.projects = projects;
    },
    createProject(state, project) {
      state.projects = [...state.projects, project];
    },
    archiveProjects(state, projectsToArhive) {
      state.projects.map((item) => {
        if (projectsToArhive.indexOf(item.id) !== -1) {
          // eslint-disable-next-line no-param-reassign
          item.status = "archived";
        }
        return item;
      });
    },
    editWorkflow(state, { projectId, workflow }) {
      const project = state.projects.find(
        (project) => project.id === projectId
      );
      project.approverWorkflowConfigurations = project.approverWorkflowConfigurations.map(
        (w) => {
          return w.processDefinitionId === workflow.processDefinitionId
            ? workflow
            : w;
        }
      );
    },
    addArea(state, { projectId, area }) {
      state.projects = state.projects.map((p) => {
        if (p.id === projectId) {
          p.areas = [...p.areas, area];
        }
        return p;
      });
    },
    removeArea(state, { projectId, areaTitleToRemove }) {
      state.projects = state.projects.map((p) => {
        if (p.id === projectId) {
          // eslint-disable-next-line no-param-reassign
          p.areas = p.areas.filter((a) => a.areaTitle !== areaTitleToRemove);
        }
        return p;
      });
    },

    editScope(state, { projectId, areaTitle, consultantId, newScope }) {
      state.projects = state.projects.map((p) => {
        if (p.id === projectId) {
          p.areas = p.areas.map((a) => {
            if (a.areaTitle === areaTitle) {
              a.consultants = a.consultants.map((c) => {
                if (c.id === consultantId) {
                  const {
                    classification: classificationA,
                    ...scopeWithoutClassification
                  } = newScope;
                  scopeWithoutClassification.classification = c.classification;

                  return scopeWithoutClassification;
                } else {
                  return c;
                }
              });
            }
            return a;
          });
        }

        return p;
      });
    },
    editContractScope(state, { projectId, areaTitle, consultantId, newScope }) {
      let project = state.projects.find((p) => p.id === projectId);
      let contracts = project.processes[0].contracts;
      contracts = contracts.map((c) => {
        c.consultants = c.consultants.map((cons) => {
          if (cons.id === consultantId) {
            const {
              classification: classificationA,
              ...scopeWithoutClassification
            } = newScope;
            scopeWithoutClassification.classification = cons.classification;

            return scopeWithoutClassification;
          } else {
            return cons;
          }
        });

        return c;
      });

      // state.projects = state.projects.map((p) => {
      //   if (p.id === projectId) {
      //     p.areas = p.areas.map((a) => {
      //       if (a.areaTitle === areaTitle) {
      //         a.consultants = a.consultants.map((c) => {
      //           if (c.id === consultantId) {
      //             const {
      //               classification: classificationA,
      //               ...scopeWithoutClassification
      //             } = newScope;
      //             scopeWithoutClassification.classification = c.classification;

      //             return scopeWithoutClassification;
      //           } else {
      //             return c;
      //           }
      //         });
      //       }
      //       return a;
      //     });
      //   }

      //   return p;
      // });
    },

    addScopeToAddedScopes(
      state,
      { projectId, areaTitle, consultantId, scope }
    ) {
      state.projects = state.projects.map((p) => {
        if (p.id === projectId) {
          p.areas = p.areas.map((a) => {
            if (a.areaTitle === areaTitle) {
              a.consultants = a.consultants.map((c) => {
                if (c.id === consultantId) {
                  c.addedScope = [...c.addedScope, scope];
                }
                return c;
              });
            }
            return a;
          });
        }

        return p;
      });
    },

    setDelivarableCompleted(
      state,
      { projectId, areaTitle, consultantId, newScope, delivarable }
    ) {
      state.projects = state.projects.map((p) => {
        if (p.id === projectId) {
          p.areas = p.areas.map((a) => {
            if (a.areaTitle === areaTitle) {
              a.consultants = a.consultants.map((c) => {
                if (c.id === consultantId) {
                  c.stages = c.stages.map((m) => {
                    m.deliverables = m.deliverables.map((d) => {
                      if (d.code === delivarable.code) {
                        return delivarable;
                      } else {
                        return d;
                      }
                    });
                    return m;
                  });
                }
                return c;
              });
            }
            return a;
          });
        }

        return p;
      });
    },
    addTender(state, { projectId, tender }) {
      const project = state.projects.find(
        (project) => project.id === projectId
      );

      // project.processes.tenders = [...project.processes.tenders, tender];
      project.processes.push({
        tender: tender,
        awards: [],
        bids: [],
        contracts: [],
        // contracts: [tender],
        parties: [],
      });
    },

    /////////----------------------------------
    addSubConsultants(state, { projectId, areaTitle, consultantId, values }) {
      const project = state.projects.find(
        (project) => project.id === projectId
      );
      const area = project.areas.find((area) => area.areaTitle === areaTitle);
      const consultant = area.consultants.find(
        (consultant) => consultant.id === consultantId
      );
      let classificationsToAdd = values.map((v) => v.classification);
      let scopesToAdd = [];
      let milestonesToAdd = [];
      values.forEach((v) => {
        v.scopes.forEach((s) => {
          scopesToAdd.push(s);
        });
      });
      values.forEach((v) => {
        v.milestones.forEach((m) => {
          milestonesToAdd.push(m);
        });
      });
      let stagesToAdd = [];
      values.forEach((v) => {
        v.stages.forEach((s) => {
          stagesToAdd.push(s);
        });
      });

      // console.log([...consultant.scopes, ...scopesToAdd]);
      consultant.scopes = [...consultant.scopes, ...scopesToAdd];
      consultant.milestones = [...consultant.milestones, ...milestonesToAdd];
      consultant.additionalClassification = [
        ...consultant.additionalClassification,
        ...classificationsToAdd,
      ];
      // consultant.stages = consultant.stages.map((s, sindex) => {
      //   let currentDelivarables = s.deliverables;
      //   values.forEach((v) => {
      //     s.deliverables = [
      //       ...currentDelivarables,
      //       ...v.stages[sindex].deliverables,
      //     ];
      //   });

      //   return s;
      // });
      consultant.stages = [...consultant.stages, ...stagesToAdd];
      area.consultants = area.consultants.filter((c) => {
        if (!!values.find((consultant) => consultant.id === c.id)) {
          return false;
        } else {
          return true;
        }
      });
    },

    ///////////////////------------------------------
    editProccesInstance(state, { id, newInstance, proccesIndex }) {
      let project = state.projects.find((p) => p.id === id);
      let processInstances = project.processInstances;

      processInstances[proccesIndex] = newInstance;
      project.processInstances = processInstances;
    },
    addProccessInstance(state, { id, instance }) {
      let project = state.projects.find((p) => p.id === id);
      project.processInstances = [...project.processInstances, instance];
    },

    removeConsultantById(state, { projectId, consultant, subConsultant }) {
      const project = state.projects.find(
        (project) => project.id === projectId
      );

      const area = project.areas.find(
        (area) => area.id === consultant.area[0].id
      );
      const innerConsultant = area.consultants.find(
        (c) => c.id === consultant.id
      );

      let newConsultant = {
        classification: subConsultant,
        area: consultant.area,
        contractType: consultant.contractType,
        milestones: consultant.milestones.filter((m) => {
          return m.classification.id === subConsultant.id;
        }),
        additionalClassification: [],
        status: "draft",
        scopes: consultant.scopes.filter((s) => {
          return s.classification.id === subConsultant.id;
        }),
        stages: consultant.stages.map((s) => {
          return s;
        }),
        id: uuid.v1(),
      };
      newConsultant.stages = newConsultant.stages.filter((nc) => {
        return nc.classification.id === newConsultant.classification.id;
      });

      innerConsultant.additionalClassification = innerConsultant.additionalClassification.filter(
        (ac) => {
          return ac.id !== subConsultant.id;
        }
      );
      //видаляємо усі поля де ід класифікації співпадає з ід іншого консультанта
      innerConsultant.milestones = innerConsultant.milestones.filter((m) => {
        return m.classification.id !== newConsultant.classification.id;
      });
      innerConsultant.scopes = innerConsultant.scopes.filter(
        (s) => s.classification.id !== newConsultant.classification.id
      );
      console.log(innerConsultant.stages);
      innerConsultant.stages = innerConsultant.stages.filter((s) => {
        let result = s.deliverables.filter((d) => {
          return d.classification.id === newConsultant.classification.id;
        });
        return result.length === 0;
      });

      //Повернемо консультанта якого ми видалили з саб конультанта назад в area
      area.consultants = [...area.consultants, newConsultant];
      console.log("innerConsultant", innerConsultant);
    },
    addVariation(state, { variation, projectId }) {
      let project = state.projects.find((p) => p.id === projectId);
      let processes = project.processes;
      let contract = null;
      processes.forEach((p) => {
        p.contracts.forEach((c) => {
          if (c.awardID === variation.awardID) {
            contract = c;
            p.contracts = [...p.contracts, variation];
          }
        });
      });
    },
    removeVariation(state, { variationId, projectId }) {
      let project = state.projects.find((p) => p.id === projectId);
      console.log("projectId", projectId);
      console.log("project", project);
      let processes = project.processes;
      processes = processes.map((p) => {
        p.contracts = p.contracts.filter((c) => {
          if (c.variationId === variationId) {
            return false;
          } else {
            return true;
          }
        });
        return p;
      });
    },
  },

  getters: {
    getProjectById: (state) => (id) => {
      return state.projects.find((project) => project.id === id);
    },
  },
};
