`<template>
  <div class="dialog">
    <div>
      <v-row>
        <v-col sm="6">
          <div class="field">
            <v-autocomplete
              v-model="industryType"
              outlined
              background-color="white"
              color="primary"
              label="Industry Type*"
              :items="json.industryTypes"
            />
          </div>
        </v-col>
        <v-col sm="6">
          <div class="field">
            <v-autocomplete
              v-model="questions"
              :items="questionsItems"
              outlined
              background-color="white"
              color="primary"
              label="Pre-Qualification Questions*"
            />
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="6">
          <div class="field">
            <v-text-field
              v-model="projectName"
              outlined
              background-color="white"
              color="primary"
              label="Project Name*"
            />
          </div>
        </v-col>
        <v-col sm="6">
          <div class="field">
            <v-text-field
              v-model="projectCode"
              outlined
              background-color="white"
              color="primary"
              label="Project Code*"
            />
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="6">
          <div class="field">
            <v-autocomplete
              v-model="developmentTypes"
              outlined
              background-color="white"
              color="primary"
              label="Development Type*"
              :items="json.developmentTypes"
              clearable
              multiple
            />
          </div>
        </v-col>
        <!-- <v-col sm="6">
          <div class="field">
            <v-autocomplete
              v-model="areas"
              outlined
              background-color="white"
              color="primary"
              placeholder="Areas*"
              multiple
              clearable
              :items="json.areaType"
            />
          </div>
        </v-col> -->
      </v-row>
      <v-row class="documents-title">
        <div class="ml-3 d-flex">
          <div>
            Documents
          </div>
          <div class="ml-2">
            <v-tooltip top color="black">
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on">
                  mdi-help-circle-outline
                </v-icon>
              </template>
              <span v-html="'Overall Project Brief can be uploaded here'" />
            </v-tooltip>
          </div>
        </div>
      </v-row>
      <v-row>
        <div class="d-flex justify-center c-wrapper">
          <file-upload
            ref="upload"
            v-model="files"
            class="btn btn-primary"
            post-action="/upload/post"
            extensions="gif,jpg,jpeg,png,webp"
            :multiple="true"
            :size="1024 * 1024 * 10"
          >
            <div class="c-file-upload">
              <v-icon>
                mdi-cloud-upload
              </v-icon>
              <div class="c-file-upload__label">
                Chose files to upload
              </div>
              <div class="c-file-upload__subLabel">
                Or drag and drop them here
              </div>
            </div>
          </file-upload>
        </div>
      </v-row>
      <div class="files-wrapper">
        <v-row v-for="(file, i) in files" :key="`file - ${i}`">
          <v-col class="d-flex">
            <div class="icon-wrap">
              <!-- <v-icon> {{ `mdi-file-${getFileExtention(file)}` }} </v-icon> -->
              <v-icon>mdi-file </v-icon>
            </div>
            <div class="d-flex justify-center align-center">
              {{ file.name }}
            </div>
          </v-col>
          <v-col class="d-flex justify-end icon-wrap">
            <v-icon @click="deleteFile(i)">
              mdi-delete
            </v-icon>
          </v-col>
        </v-row>
      </div>

      <v-row class="buttons-wrapper">
        <div>
          <v-btn width="130px" height="45px" @click="closeModal()">
            Cancel
          </v-btn>
          <v-btn
            class="ml-2"
            color="primary"
            width="130px"
            height="45px"
            :disabled="canCreate"
            @click="createNewProject()"
          >
            Save
          </v-btn>
        </div>
      </v-row>
    </div>
  </div>
</template>
<script>
import FileUpload from "vue-upload-component";
import json from "@/createNewProjectData.json";

export default {
  name: "CreateNewProject",
  components: {
    FileUpload,
  },
  data: function() {
    return {
      json: json,
      industryType: "",
      projectName: "",
      projectCode: "",

      developmentTypes: [],
      questions: "",
      areas: [],
      files: [],
      questionsItems: ["Developer ABC", "Developer DEF", "Developer XYZ"],
    };
  },
  computed: {
    canCreate() {
      return (
        this.checkStr(this.industryType) ||
        this.checkStr(this.projectName) ||
        this.checkStr(this.questions) ||
        this.checkStr(this.projectCode) ||
        this.developmentTypes.length === 0
      );
    },
  },
  methods: {
    checkStr(str) {
      return str === "";
    },
    closeModal() {
      this.$emit("toggleModal");
      this.reset();
    },
    getFileExtention(file) {
      return /[.]/.exec(file.name) ? /[^.]+$/.exec(file.name)[0] : undefined;
    },
    reset() {
      Object.assign(this.$data, this.$options.data());
    },
    deleteFile(id) {
      this.files = this.files.filter((e, i) => i !== id);
    },
    createNewProject() {
      this.$store.commit("projectsModule/createProject", {
        approverWorkflowConfigurations: this.getApproverWorkflowConfiguration(),
        id: this.generateProjectId(),
        industryType: this.industryType,
        projectCode: this.projectCode,
        projectName: this.projectName,
        developmentType: this.developmentTypes,
        processInstances: [],
        areas: [],
        parties: [],
        process: {
          tenders: [],
        },
        zones: this.areas,
        status: "active",
        files: this.files.map((f) => {
          return `https://files.precontrax.quintagroup.com/01/${f.name}`;
        }),
      });
      this.reset();
      this.closeModal();
      this.$emit("onProjectCreate");
    },
    generateProjectId() {
      let lastPrjectId = this.$store.state.projectsModule.projects;

      return (lastPrjectId.length + 1).toString();
    },
    formatAreasData() {
      return this.areas.map((a) => {
        return {
          areaTitle: a,
        };
      });
    },
    getApproverWorkflowConfiguration() {
      return [
        {
          processDefinitionId: "001",
          projectId: this.generateProjectId(),
          description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. In tellus integer feugiat scelerisque varius morbi enim nunc faucibus.",
          actions: [
            {
              id: "001-approval-1",
              type: "userAction",
              actor: {
                id: "0cac2381-0815-48c6-8669-759f19298111",
                name: "Michael Shelton",
              },
              expectedDuration: 1,
            },
            {
              id: "001-approval-2",
              type: "userAction",
              actor: {
                id: "96dbd65d-ca91-4e3b-84c9-341a1badc35e",
                name: "Adriana Spiker",
              },
              expectedDuration: 2,
            },
          ],
        },
        {
          processDefinitionId: "002",
          projectId: this.generateProjectId(),
          description:
            "Duis convallis convallis tellus id interdum velit. Ut eu sem integer vitae justo eget magna. Tellus mauris a diam maecenas sed enim ut.",
          actions: [
            {
              id: "001-approval-1",
              type: "userAction",
              actor: {
                id: "0cac2381-0815-48c6-8669-759f19298111",
                name: "Michael Shelton",
              },
              expectedDuration: 1,
            },
            {
              id: "001-approval-2",
              type: "userAction",
              actor: {
                id: "96dbd65d-ca91-4e3b-84c9-341a1badc35e",
                name: "Adriana Spiker",
              },
              expectedDuration: 2,
            },
          ],
        },
        {
          processDefinitionId: "003",
          projectId: this.generateProjectId(),
          description:
            "Pharetra magna ac placerat vestibulum. Eget gravida cum sociis natoque.",
          actions: [
            {
              id: "001-approval-1",
              type: "userAction",
              actor: {
                id: "0cac2381-0815-48c6-8669-759f19298111",
                name: "Michael Shelton",
              },
              expectedDuration: 1,
            },
            {
              id: "001-approval-2",
              type: "userAction",
              actor: {
                id: "96dbd65d-ca91-4e3b-84c9-341a1badc35e",
                name: "Adriana Spiker",
              },
              expectedDuration: 2,
            },
          ],
        },
        {
          processDefinitionId: "004",
          projectId: this.generateProjectId(),
          description:
            "Amet venenatis urna cursus eget nunc. Hendrerit gravida rutrum quisque non tellus orci ac auctor augue.",
          actions: [
            {
              id: "001-approval-1",
              type: "userAction",
              actor: {
                id: "0cac2381-0815-48c6-8669-759f19298111",
                name: "Michael Shelton",
              },
              expectedDuration: 1,
            },
            {
              id: "001-approval-2",
              type: "userAction",
              actor: {
                id: "96dbd65d-ca91-4e3b-84c9-341a1badc35e",
                name: "Adriana Spiker",
              },
              expectedDuration: 2,
            },
          ],
        },
        {
          processDefinitionId: "005",
          projectId: this.generateProjectId(),
          description:
            "Nec feugiat nisl pretium fusce id. Sit amet consectetur adipiscing elit ut. Turpis egestas sed tempus urna et pharetra pharetra massa.",
          actions: [
            {
              id: "001-approval-1",
              type: "userAction",
              actor: {
                id: "0cac2381-0815-48c6-8669-759f19298111",
                name: "Michael Shelton",
              },
              expectedDuration: 1,
            },
            {
              id: "001-approval-2",
              type: "userAction",
              actor: {
                id: "96dbd65d-ca91-4e3b-84c9-341a1badc35e",
                name: "Adriana Spiker",
              },
              expectedDuration: 2,
            },
          ],
        },
        {
          processDefinitionId: "006",
          projectId: this.generateProjectId(),
          description:
            "Dui nunc mattis enim ut. At tellus at urna condimentum mattis pellentesque. Vulputate enim nulla aliquet porttitor lacus.",
          actions: [
            {
              id: "001-approval-1",
              type: "userAction",
              actor: {
                id: "0cac2381-0815-48c6-8669-759f19298111",
                name: "Michael Shelton",
              },
              expectedDuration: 1,
            },
            {
              id: "001-approval-2",
              type: "userAction",
              actor: {
                id: "96dbd65d-ca91-4e3b-84c9-341a1badc35e",
                name: "Adriana Spiker",
              },
              expectedDuration: 2,
            },
          ],
        },
        {
          processDefinitionId: "007",
          projectId: this.generateProjectId(),
          description:
            "Ornare arcu dui vivamus arcu felis bibendum ut tristique. Justo laoreet sit amet cursus sit amet. Morbi leo urna molestie at elementum eu.",
          actions: [
            {
              id: "001-approval-1",
              type: "userAction",
              actor: {
                id: "0cac2381-0815-48c6-8669-759f19298111",
                name: "Michael Shelton",
              },
              expectedDuration: 1,
            },
            {
              id: "001-approval-2",
              type: "userAction",
              actor: {
                id: "96dbd65d-ca91-4e3b-84c9-341a1badc35e",
                name: "Adriana Spiker",
              },
              expectedDuration: 2,
            },
          ],
        },
        {
          processDefinitionId: "008",
          projectId: this.generateProjectId(),
          description:
            "A arcu cursus vitae congue. Ut ornare lectus sit amet est. Tellus elementum sagittis vitae et leo duis ut diam quam.",
          actions: [
            {
              id: "001-approval-1",
              type: "userAction",
              actor: {
                id: "0cac2381-0815-48c6-8669-759f19298111",
                name: "Michael Shelton",
              },
              expectedDuration: 1,
            },
            {
              id: "001-approval-2",
              type: "userAction",
              actor: {
                id: "96dbd65d-ca91-4e3b-84c9-341a1badc35e",
                name: "Adriana Spiker",
              },
              expectedDuration: 2,
            },
          ],
        },
        {
          processDefinitionId: "009",
          projectId: this.generateProjectId(),
          description:
            "Vel facilisis volutpat est velit egestas dui id ornare. Mi eget mauris pharetra et ultrices neque ornare. Nisi lacus sed viverra tellus in hac habitasse platea dictumst.",
          actions: [
            {
              id: "001-approval-1",
              type: "userAction",
              actor: {
                id: "0cac2381-0815-48c6-8669-759f19298111",
                name: "Michael Shelton",
              },
              expectedDuration: 1,
            },
            {
              id: "001-approval-2",
              type: "userAction",
              actor: {
                id: "96dbd65d-ca91-4e3b-84c9-341a1badc35e",
                name: "Adriana Spiker",
              },
              expectedDuration: 2,
            },
          ],
        },
      ];
    },
  },
};
</script>
<style scoped lang="scss">
.dialog {
  background: white;
  height: 880px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.field {
  width: 420px;
  height: 58px;
}

::v-deep.file-uploads {
  width: 100% !important;
}
.c-wrapper {
  width: 100%;
}
.files-wrapper {
  height: 148px;
  margin-top: 30px;
  overflow-y: auto;
  overflow-x: hidden;
}
.buttons-wrapper {
  justify-content: flex-end;
  margin-top: 30px;
}
::v-deep .v-select__slot {
  max-height: 80px;
  overflow-y: auto;
  overflow-x: hidden;
}
.documents-title {
  margin-top: 50px !important;
}
.icon-wrap {
  .v-icon {
    font-size: 40px !important;
  }
}
::v-deep .v-select__selections {
}
::v-deep .v-text-field--outlined .v-label--active {
  top: 22px !important;
}
::v-deep .v-list-item__title {
  text-align: left !important;
}
</style>
