<template>
  <div class="areas-table-wrapper">
    <v-dialog v-model="isScopeDialogOpen" width="1500">
      <div class="pos-relative">
        <div class="icon-close-wrapper pointer" @click="toogleScopeModalOpen()">
          <v-icon>
            mdi-close
          </v-icon>
        </div>
        <ScopeDialog />
      </div>
    </v-dialog>

    <h3 class="card-title">
      AREAS IN DELAY
    </h3>
    <div class="content">
      <div class="d-flex">
        <div class="select-small">
          <v-select
            v-model="selectValue"
            :items="months"
            label="Month"
            outlined
            class="min-height-select"
            height="40px"
            :menu-props="{ offsetY: true }"
            clearable
          />
        </div>
        <div class="select-small ml-1">
          <v-text-field
            v-model="searchValue"
            outlined
            height="30px"
            class="min-height-select"
            label="Search"
          />
        </div>
      </div>
      <v-row class="mt-5">
        <v-col sm="12">
          <v-data-table
            :header-props="{ sortIcon: null }"
            :headers="headers"
            :items="filtredItems"
            hide-default-footer
            :items-per-page="5"
            class="elevation-1"
          >
            <!-- eslint-disable -->
            <template v-slot:item.action="{ item }">
              <div v-if="item.action">
                <v-btn
                  rounded
                  color="primary"
                  dark
                  height="29px"
                  @click="toogleScopeModalOpen()"
                >
                  Open
                </v-btn>
              </div>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import ScopeDialog from "../../../ScopeOfWorks/components/ScopeDialog/ScopeDialog.vue";
import { itemsFilter } from "@/utils/itemsFilter/itemsFilter";
export default {
  name: "Areas",
  components: { ScopeDialog },
  data: function() {
    return {
      isScopeDialogOpen: false,
      searchValue: "",
      selectValue: "",
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      headers: [
        { text: "Delay Type", value: "delayType" },
        { text: "Action By", value: "consultant" },
        { text: "Stage", value: "Stage" },
        { text: "Duration", value: "Duration" },
        { text: "", value: "action" },
      ],
      items: [
        {
          delayType: "Design",
          consultant: "Interior design",
          Stage: "Concept Design",
          Duration: "30 days",
          action: true,
          month: "January",
        },
        {
          delayType: "Payment approval",
          consultant: "Project Manager",
          Stage: "Concept Design",
          Duration: "30 days",
          month: "March",
        },

        {
          delayType: "Approval of Appointment",
          consultant: "Developer",
          Stage: "Contract execution",
          Duration: "15 days",
          month: "October",
        },
        {
          delayType: "Approval of SOW",
          consultant: "Developer",
          Stage: "RFP issued for approval",
          Duration: "7 days",
          month: "October",
        },
      ],
    };
  },
  computed: {
    filtredItems() {
      return itemsFilter(
        this.items,
        ["delayType", "consultant", "Stage"],
        this.searchValue,
        this.selectValue,
        "month"
      );
    },
  },
  methods: {
    toogleScopeModalOpen() {
      this.isScopeDialogOpen = !this.isScopeDialogOpen;
    },
  },
};
</script>
<style lang="scss" scoped>
.areas-table-wrapper {
  overflow-y: auto;
  overflow-x: hidden;
}
.content {
  position: relative;
  margin-top: 20px;
}
::v-deep .sortable {
}
</style>
