<template>
  <div class="add-dialog">
    <div class="dialog-head">
      <div class="ml-6 add-title">ADD {{ type }}</div>
    </div>
    <div class="items-wrapper">
      <div>
        <v-textarea
          v-model="value"
          class="mt-5"
          outlined
          width="100px"
          name="input-7-4"
          :label="type"
          height="200"
        />
      </div>
      <div class="buttons">
        <v-btn width="130px" height="45px">
          Cancel
        </v-btn>
        <v-btn color="primary" width="130px" height="45px" @click="onAdd">
          Save
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ScopeDialogAddNewItem",
  props: {
    editContract: {
      type: Boolean,
      default: false,
    },

    isAddDialogOpen: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "",
    },
    currentEditDelivarable: {
      type: [Object, String],
      default: null,
    },
  },
  data: function() {
    return {
      value: "",
    };
  },

  computed: {},
  watch: {
    isAddDialogOpen: function(val) {
      if (this.type === "Transmital") {
        if (this.editContract) {
          console.log("dsada");

          this.value = this.$store.state.scopeOfWorksModule.pageData.documents.find(
            (d) => d.relatedItem === this.currentEditDelivarable.id
          ).url;
        } else {
          this.value = this.currentEditDelivarable.transmittal;
        }
      } else {
        this.value = "";
      }
    },
  },

  methods: {
    onAdd() {
      this.$emit("onAdd", this.type, this.value);
      this.value = "";
    },
  },
};
</script>
<style scoped lang="scss">
.add-dialog {
  height: 600px;
  background: white;
}
.dialog-head {
  display: flex;
  justify-content: flex-start;
  height: 100px;
  background-color: var(--v-primary-base) !important;
  color: white;
  align-items: center;
  width: 100%;
}
::v-deep .v-input {
  width: 100% !important;
}
.items-wrapper {
  display: flex;
  padding: 0px 20px;
  flex-direction: column;
}
.buttons {
  display: flex;
  justify-content: flex-end;
  column-gap: 10px;
}
.add-title {
  text-transform: uppercase;
}
</style>
