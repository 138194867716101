<template>
  <tbody>
    <tr>
      <td class="text-left">
        <div class="d-flex">
          <v-icon @click="toggleOpen">
            mdi-arrow-down-drop-circle-outline
          </v-icon>
          <div class="ml-3">
            {{ item.areaType }}
          </div>
        </div>
      </td>
      <td class="text-left">
        {{ item.areaTitle }}
      </td>
      <td class="text-left">
        <div v-if="item.briefs.length !== 0">
          {{ item.briefs[0].name }}
        </div>
        <div v-else />
      </td>
      <td class="text-left">
        {{ item.disciplinesAssigned[0] }}
      </td>
      <td class="text-left">
        {{ item.consultantName }}
      </td>
      <td class="text-left">
        {{ item.subConsultantName }}
      </td>
      <td class="d-flex justify-end align-center">
        <v-menu left bottom offset-y class="c-menu">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on">
              mdi-dots-vertical
            </v-icon>
          </template>

          <v-list class="dialog-actions-list">
            <div
              class="delete-btn pointer dialog-actions-list__item"
              @click="onDelete(item)"
            >
              Delete
            </div>
          </v-list>
        </v-menu>
      </td>
    </tr>
    <template v-if="isOpened">
      <tr
        v-for="(discipline, d) in item.disciplinesAssigned.slice(1)"
        :key="`area-discipline-${d}`"
      >
        <td />
        <td />
        <td />
        <td class="text-left">
          {{ discipline }}
        </td>
        <td />
        <td />
        <td />
      </tr>
    </template>
  </tbody>
</template>
<script>
export default {
  name: "AreaTableItem",
  props: ["item"],
  data: function() {
    return {
      isOpened: false,
    };
  },
  methods: {
    toggleOpen() {
      this.isOpened = !this.isOpened;
    },
    onDelete(area) {
      this.$emit("onDelete", area);
    },
  },
};
</script>
<style scoped lang="scss">
::v-deep .v-menu__content {
  width: 200px;
  height: 200px;
}
</style>
