<template>
  <div class="scoped-of-work-page">
    <div class="tabs" style="margin-bottom: 30px">
      <div
        v-for="(tab, t) in tabs"
        :key="`scope of works tab - ${t}`"
        class="tab"
        :class="currentTab === tab && 'active'"
        @click="setCurrentTab(tab)"
      >
        {{ tab }}
      </div>
    </div>
    <div v-if="currentTab === 'Scope to be appointed'">
      <ScopeTable
        :headers="headers"
        :items="items"
        :current-tab="currentTab"
        @openScopeDialog="(dialog) => openDialog(dialog, false)"
        @setCurrentScopeItemInDialog="setCurrentScopeItemInDialog"
      />
    </div>
    <div v-if="currentTab === 'Appointed scope'">
      <AppointedScopeTable
        :current-tab="currentTab"
        :headers="headers"
        :items="proccess"
        :appointed-scope-headers="appointedScopeHeaders"
        @setCurrentTab="setCurrentTab"
        @setCurrentScopeItemInDialog="setCurrentScopeItemInDialog"
        @openScopeDialog="(dialog) => openDialog(dialog, true)"
      />
    </div>
    <div v-if="currentTab === 'Approved scope'">
      <ScopeTable
        :current-tab="currentTab"
        :headers="getApprovedScopeHeaders(headers)"
        :items="approvedScopeOfWorks"
        @openScopeDialog="(dialog) => openDialog(dialog, false)"
        @setCurrentScopeItemInDialog="setCurrentScopeItemInDialog"
      />
    </div>
    <div v-if="currentTab === 'Variations'">
      <AppointedScopeTable
        :current-tab="currentTab"
        :headers="variationHeaders"
        :items="proccess"
        @openScopeDialog="(dialog) => openDialog(dialog, false)"
        @setCurrentScopeItemInDialog="setCurrentScopeItemInDialog"
      />
    </div>

    <v-dialog v-model="isScopeDialogOpen" width="1500">
      <div class="pos-relative">
        <div class="icon-close-wrapper pointer" @click="closeDialog()">
          <v-icon>
            mdi-close
          </v-icon>
        </div>
        <ScopeDialog
          :previewed-scope="currentScopeItemInDialog"
          :view="'consultant'"
          :is-edit-mode="isEditModeInWScopeActive"
          :can-edit="currentTab === 'Approved scope' ? false : true"
          :edit-contract="currentTab === 'Variations' ? true : false"
          @toggleEditMode="toggleEditMode"
        />
      </div>
    </v-dialog>
    <v-dialog v-model="isSetProgramDialogOpen" width="962">
      <div class="pos-relative">
        <div class="icon-close-wrapper pointer" @click="closeDialog()">
          <v-icon>
            mdi-close
          </v-icon>
        </div>
        <SetProgramDialog
          :is-all-items-disabled="isAllItemsInSetProgramDisabled"
          :current-scope-item-in-dialog="currentScopeItemInDialog"
          :is-appointed="isAppointed"
          :is-two-first-colums-disabled="currentTab === 'Approved scope'"
          @closeDialog="closeDialog"
        />
      </div>
    </v-dialog>
  </div>
</template>
<script>
import AppointedScopeTable from "./components/AppointedScopeTable/AppointedScopeTable.vue";
import SetProgramDialog from "./components/SetProgramDialog/SetProgramDialog.vue";
import ScopeDialog from "./components/ScopeDialog/ScopeDialog.vue";
import ScopeTable from "./components/ScopeTable/ScopeTable.vue";
import { variationHeaders } from "./variationsHeaders.js";

export default {
  name: "ScopeOfWorks",
  components: {
    ScopeTable,
    ScopeDialog,
    SetProgramDialog,
    AppointedScopeTable,
    AppointedScopeTable,
  },

  data: function() {
    return {
      variationHeaders,
      tabs: [
        "Scope to be appointed",
        "Approved scope",
        "Appointed scope",
        "Variations",
      ],
      currentTab: "Scope to be appointed",
      currentScopeItemInDialog: false,
      isAllItemsInSetProgramDisabled: false,
      isEditModeInWScopeActive: false,
      headers: [
        {
          title: "Area tag",
          background: true,
        },
        {
          title: "Discipline",
          tooltip: `Autofills from project type.  </br>Editable only by project admin`,
        },

        {
          title: "Sub consultants",
          tooltip: `
          Transfer discipline under
           </br>
another discipline
 </br>
by adding
          `,
        },

        {
          title: "Scope of works",
          tooltip: `
        Autofills from project type.
        </br>
Editable only by project admin
          `,
        },
        {
          title: "Set Program",
          background: true,
        },
        {
          title: "Scope  deleted",
          background: true,
        },
        {
          title: "Scope  added",
          background: true,
        },

        //
        {
          title: "",
          background: true,
        },
      ],
      appointedScopeHeaders: [
        {
          title: "Area tag",
          background: false,
        },
        {
          title: "Discipline",
          tooltip: `Autofills from project type.  </br>Editable only by project admin`,
        },

        {
          title: "Sub consultants",
          tooltip: `
          Transfer discipline under
           </br>
another discipline
 </br>
by adding
          `,
        },

        {
          title: "Scope of works",
          tooltip: `
        Autofills from project type.
        </br>
Editable only by project admin
          `,
        },
        {
          title: "Program",
          background: false,
        },
        {
          title: "Scope  deleted",
          background: false,
        },
        {
          title: "Scope  added",
          background: false,
        },

        //appointed scope
        {
          title: "Variations",
          background: true,
        },
        {
          title: "Current </br> completed stage",
          background: true,
        },
        {
          title: `Payment </br> Requested`,
          background: true,
        },
        {
          title: `Approval Status`,
          background: true,
        },
        {
          title: `Payment Status`,
          background: true,
          appointedTable: true,
        },
        {
          title: "",
          background: true,
        },
      ],
      isScopeDialogOpen: false,
      isSetProgramDialogOpen: false,
      isAppointed: false,
    };
  },
  computed: {
    proccess() {
      return this.$store.getters["projectsModule/getProjectById"](this.id)
        .processes;
    },
    approvedScopeOfWorks() {
      return this.getScopesByConsultantType("approved");
    },
    id() {
      return this.$route.params.id;
    },
    items() {
      return this.getScopesByConsultantType("draft");

      // return this.$store.getters["projectsModule/getProjectById"](this.id)
      //   .areas;
    },
  },

  methods: {
    setCurrentTab(val) {
      this.currentTab = val;
    },
    toggleEditMode() {
      this.isEditModeInWScopeActive = !this.isEditModeInWScopeActive;
    },
    getScopesByConsultantType(type) {
      let areas = JSON.parse(
        JSON.stringify(
          this.$store.getters["projectsModule/getProjectById"](this.id).areas
        )
      );
      areas = areas.map((a) => {
        // eslint-disable-next-line no-param-reassign
        a.consultants = a.consultants.filter((c) => {
          return c.status === type;
        });
        return a;
      });
      return areas.filter((a) => {
        return a.consultants.length > 0;
      });
    },
    openDialog(dialog, isAppointed) {
      if (dialog === "setProgram") {
        this.isSetProgramDialogOpen = true;
        this.isAppointed = isAppointed;
      }
      if (this.currentTab === "Approved scope") {
        this.isAppointed = true;
      }
    },
    setCurrentScopeItemInDialog({ item, area, id, isDisabled }) {
      console.log(isDisabled);
      if (isDisabled) {
        this.isAllItemsInSetProgramDisabled = true;
      }
      console.log("item scope of works ", item);
      let itemToOpen;
      if (id === 1) {
        itemToOpen = item.stages[0].type;
        this.isScopeDialogOpen = true;
      }
      if (id === 3) {
        itemToOpen = "Deleted scope and deliverables";
        this.isScopeDialogOpen = true;
      }
      if (id === 4) {
        itemToOpen = "Added scope and deliverables";
        this.isScopeDialogOpen = true;
      }
      this.$store.commit("scopeOfWorksModule/setCurrentDialog", itemToOpen);
      this.$store.commit("scopeOfWorksModule/setCurrentArea", area.areaTitle);
      this.$store.commit("scopeOfWorksModule/setCurrentConsultant", item.id);

      this.currentScopeItemInDialog = item;
    },
    closeDialog() {
      this.isSetProgramDialogOpen = false;
      this.isScopeDialogOpen = false;
    },
    getApprovedScopeHeaders() {
      return this.headers.map((h) => {
        if (h.title === "Set Program") {
          return {
            title: "Program",
            background: true,
          };
        } else {
          return h;
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
.scoped-of-work-page {
  overflow: auto;
  .tabs {
    display: flex;
    .tab {
      background: whitesmoke;

      cursor: pointer;
      padding: 20px;
      min-width: 200px;
      height: 64px;
      &:not(:last-of-type) {
        margin-right: 10px;
      }
      &.active {
        color: white;
        background-color: var(--v-primary-base) !important;
      }
    }
  }
}
</style>
