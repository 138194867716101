const currentUser = localStorage.getItem("currentUser");

export default {
  namespaced: true,
  state: {
    users: [],
    currentUser: currentUser ? JSON.parse(currentUser) : null,
  },
  getters: {
    isAuth: (s) => !!s.currentUser,
  },
  mutations: {
    setUsers(state, users) {
      state.users = users;
    },
    setCurrentUser(state, user) {
      state.currentUser = user;
    },
    updatePassword(state, { email, password }) {
      state.users.find((u) => u.emailAddress === email).password = password;
    },
    addUser(state, user) {
      state.users.push(user);
    },
    updateUser(state, data) {
      const candidate = state.users.find((u) => u.id === state.currentUser.id);
      for (const key of Object.keys(data)) {
        candidate[key] = data[key];
      }
    },
    updateCurrentUser(state, data) {
      for (const key of Object.keys(data)) {
        state.currentUser[key] = data[key];
      }
    },
    updateCurrentUserPassword(state, password) {
      const candidate = state.users.find((u) => u.id === state.currentUser.id);
      candidate.password = password;
      state.currentUser.password = password;
    },
  },
  actions: {
    login({ state, commit }, { email, password }) {
      return new Promise((res, rej) => {
        const candidate = state.users.find(
          (u) => u.emailAddress === email && password === password
        );
        if (candidate) {
          commit("setCurrentUser", candidate);
          localStorage.setItem("currentUser", JSON.stringify(candidate));
          res();
        } else {
          rej({
            message: "Incorrect credentials",
          });
        }
      });
    },
    logout({ commit }) {
      commit("setCurrentUser", null);
      localStorage.removeItem("currentUser");
    },
    resetPassword({ commit, state }, { email, password }) {
      return new Promise((res, rej) => {
        const candidate = state.users.find((u) => u.emailAddress === email);
        if (candidate) {
          commit("updatePassword", { email, password });
          res();
        } else {
          rej({
            message: "User with such email not found",
          });
        }
      });
    },
    register(
      { commit, state },
      { firstName, lastName, emailAddress, password }
    ) {
      return new Promise((res, rej) => {
        const candidate = state.users.find(
          (u) => u.emailAddress === emailAddress
        );
        if (candidate) {
          rej({
            message: "User with such email already exists",
          });
        } else {
          const user = {
            id: Date.now(),
            firstName,
            lastName,
            emailAddress,
            password,
          };
          commit("addUser", user);
          res();
        }
      });
    },
    updateInfo({ commit, state }, data) {
      return new Promise((res) => {
        commit("updateUser", data);
        commit("updateCurrentUser", data);
        const user = JSON.parse(localStorage.getItem("currentUser"));
        for (const key of Object.keys(data)) {
          user[key] = data[key];
        }
        localStorage.setItem("currentUser", JSON.stringify(user));
        res();
      });
    },
    changePassword({ commit, state }, { currentPassword, newPassword }) {
      return new Promise((res, rej) => {
        if (currentPassword !== state.currentUser.password) {
          rej({
            message: "Current password is incorrect",
          });
        } else {
          commit("updateCurrentUserPassword", newPassword);
          const user = JSON.parse(localStorage.getItem("currentUser"));
          user.password = newPassword;
          localStorage.setItem("currentUser", JSON.stringify(user));
          res();
        }
      });
    },
  },
};
