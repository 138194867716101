<template>
  <div class="dialog">
    <div class="d-flex">
      <v-btn color="primary">
        Engineering menu
      </v-btn>
    </div>
    <div class="e-menu-btn">
      <v-textarea
        v-model="textAreaData"
        outlined
        name="input-7-4"
        label="Sample data"
        height="600"
      />
    </div>
    <div class="e-menu-btn">
      <div class="d-flex justify-space-between">
        <div>
          <v-btn width="266" height="45px" class="btn-dark" @click="onRestore">
            RESTORE SAMPLE DATA
          </v-btn>
        </div>
        <div>
          <v-btn width="130" height="45" @click="toggleDialog">
            Cancel
          </v-btn>
          <v-btn
            width="130"
            height="45"
            color="primary"
            class="ml-1"
            @click="setDataToStore"
          >
            Save
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import json from "@/simpleData.json";
import { formatStoreDataToCorrectJSON } from "@/utils/formatStoreDataToCoorectJSON/formatStoreDataToCorrectJSON";
export default {
  name: "ConfigurationMenu",
  data: function() {
    return {
      json: json,

      textAreaData: JSON.stringify(
        formatStoreDataToCorrectJSON(this.$store.state),
        null,
        4
      ),
    };
  },
  computed: {},
  methods: {
    // formatStoreDataToCorrectJSON(store) {
    //   return {
    //     scopeOfWorks: store.controlRoomModule.scopes,
    //     projects: store.projectsModule.projects,
    //     myScopeOfWorks: store.controlRoomModule.myScopes,
    //     myFramework: store.myFrameworkModule.frameworks,
    //   };
    // },
    setDataToStore() {
      let val = JSON.parse(this.textAreaData);
      this.$store.commit("projectsModule/setProjects", val.projects);
      this.$store.commit("controlRoomModule/setScopes", val.scopeOfWorks);
      this.$store.commit("controlRoomModule/setMyScopes", val.myScopeOfWorks);
      this.$store.commit("myFrameworkModule/setFrameworks", val.myFramework);
      this.$store.commit("auth/setUsers", val.users);
    },
    onRestore() {
      this.$store.commit("projectsModule/setProjects", json.projects);
      this.$store.commit("controlRoomModule/setScopes", json.scopeOfWorks);
      this.$store.commit("controlRoomModule/setMyScopes", json.myScopeOfWorks);
      this.$store.commit("myFrameworkModule/setFrameworks", json.myFramework);
      this.$store.commit("auth/setUsers", json.users);

      this.textAreaData = JSON.stringify(
        formatStoreDataToCorrectJSON(this.$store.state),
        null,
        4
      );
    },
    toggleDialog() {
      this.$emit("toggleDialog");
    },
  },
};
</script>
<style lang="scss" scoped>
.dialog {
  min-height: 800px;
  background: white;
  padding-left: 26px;
  padding-right: 26px;
  padding-top: 47px;
  padding-bottom: 47px;
}
.e-menu-btn {
  margin-top: 47px;
}
.btn-dark {
  background-color: #333333 !important;
  color: white !important;
}
::v-deep .v-btn__content {
  text-transform: capitalize;
}
</style>
