<template>
  <v-form class="preferences-form px-5 px-lg-15" @submit.prevent>
    <h3 class="form-title">
      User Preferences
    </h3>
    <v-checkbox
      v-for="(item, i) in list"
      :key="i"
      v-model="item.checked"
      class="ma-0 mb-1"
      :label="item.label"
      hide-details
    />
    <p class="mt-3">
      Viverra tellus in hac habitasse platea dictumst vestibulum rhoncus.
    </p>
    <FormButtons class="mt-10" />
  </v-form>
</template>

<script>
import FormButtons from "./FormButtons";
export default {
  components: {
    FormButtons,
  },
  data() {
    return {
      list: [
        {
          label: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
          checked: false,
        },
        {
          label: 'Mi in nulla posuere sollicitudin aliquam ultrices',
          checked: true,
        },
        {
          label: 'Ornare quam viverra orci sagittis eu volutpat odio facilisis',
          checked: false,
        },
        {
          label: 'Consectetur lorem donec massa sapien faucibus et',
          checked: false,
        },
        {
          label: 'Aliquam sem et tortor consequat id porta',
          checked: true,
        },
        {
          label: 'Mattis enim ut tellus elementum sagittis',
          checked: false,
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
.form-title {
  margin-bottom: 20px;
  font-size: 16px;
  text-transform: uppercase;
}
</style>