export const formatStoreDataToCorrectJSON = (store) => {
  return {
    scopeOfWorks: store.controlRoomModule.scopes,
    projects: store.projectsModule.projects,
    myScopeOfWorks: store.controlRoomModule.myScopes,
    myFramework: store.myFrameworkModule.frameworks,
    users: store.auth.users,
    workflows: store.workflowsModule.workflows,
  };
};
