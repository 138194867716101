<template>
  <div class="d-flex justify-center">
    <div class="wrapper">
      <div class="button-wrapper">
        <v-btn rounded color="primary" height="29">
          Add+
        </v-btn>
      </div>
      <v-autocomplete
        ref="autocomplete"
        v-model="values"
        :search-input="value"
        class="mt-2 autocomplete-wrapper pointer"
        :items="filteredItems"
        outlined
        dense
        label="Outlined"
        multiple
        :item-text="'text'"
        :menu-props="{ center: true, offsetY: false }"
      >
        <!-- <template v-slot:item="data">
          <div>
            {{ getConsultantDisciplineById(data.item.text) }}
          </div>
        </template> -->
        <template v-slot:selection="data">
          {{ getConsultantDisciplineById(data.item.text) }}
        </template>
        <!-- getConsultantDisciplineById(data)  -->
        <template v-slot:prepend-item>
          <v-btn width="100%" color="primary" @click="onSubConsultantsAdd">
            Save
          </v-btn>
          <v-text-field
            v-model="value"
            class="mt-1 mb-3"
            outlined
            placeholder="Search"
          />
        </template>
      </v-autocomplete>
    </div>
  </div>
</template>

<script>
import { getConsultantDisciplineById } from "@/utils/getValueFromJSONById/getValueFromJSONById";

export default {
  name: "ButtonAdd",
  props: {
    consultant: Object,
    items: Array,
  },
  data: function() {
    return {
      value: "",
      isOpen: false,
      values: [],
    };
  },

  computed: {
    filteredItems() {
      return this.items
        .filter((i) => {
          return (
            i.classification.id !== this.consultant.classification.id &&
            i.additionalClassification.length === 0 &&
            !this.consultant.additionalClassification.find(
              (ac) => ac.id === i.classification.id
            )
          );
        })
        .map((i) => {
          return {
            value: i,
            text: this.getConsultantDisciplineById(i.classification.id),
          };
        });
    },
  },
  watch: {
    isOpen: function() {
      if (this.isOpen) {
      }
      this.$refs.autocomplete.isMenuActive = true; // open item list
    },
    consultant: function() {
      this.values = [];
    },
  },

  mounted: function() {
    // this.$refs.autocomplete.isMenuActive = true;
  },

  methods: {
    getConsultantDisciplineById,

    onSubConsultantsAdd() {
      this.$emit("onSubConsultantsAdd", this.consultant, this.values);
      this.values = [];
      this.$refs.autocomplete.isMenuActive = false;
    },
    getSubConsultants() {
      // return this.consultant.subConsultants.map((s) => {
      //   return {
      //     value: s.id,
      //     text: s.discipline,
      //   };
      // });
      return [];
    },
  },
};
</script>
<style scoped lang="scss">
.button-wrapper {
  position: absolute;
  top: 7px;
}
.autocomplete-wrapper {
  width: 75px;
  opacity: 0;
  height: 36px;
}
.wrapper {
  position: relative;
}

::v-deep .v-text-field__details {
  display: none;
}
::v-deep .v-select__selection--comma {
  display: none !important;
}
::v-deep .v-autocomplete__content {
  display: block !important;
}
::v-deep fieldset,
.v-text-field__slot {
  height: 30px !important;
}
::v-deep .v-input__slot {
  min-height: 23px !important;
}
::v-depp .v-list {
  padding: 0px !important;
}
::v-deep .v-list-item__title {
  text-align: left !important;
}
</style>
