<template>
  <div class="payment">
    <div class="total-price">
      TOTAL PRICE
    </div>
    <div class="price mt-3">
      {{ formatMoney(amount) }}
    </div>
    <div class="inp-card-wrapper mt-3">
      <div class="form-group">
        <label class="d-flex">Card number</label>
        <div class="field-card-number">
          <v-text-field
            v-model="cardNumber"
            v-cardformat:formatCardNumber
            class="form-control"
            outlined
            placeholder="Card number"
          />
        </div>
      </div>
      <div class="form-group">
        <div class="d-flex">
          <div>
            <label class="d-flex">Valid until</label>
            <div class="second-field">
              <v-text-field
                v-model="until"
                v-cardformat:formatCardExpiry
                placeholder="Month/Year"
                outlined
                class="form-control"
              />
            </div>
          </div>
          <div>
            <label class="d-flex cvc-field">CVC</label>
            <div class="second-field ml-3">
              <v-text-field
                v-model="cvc"
                v-cardformat:formatCardCVC
                outlined
                class="form-control"
                placeholder="CVC"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="form-group mt-7">
        <label class="d-flex">Card holder</label>
        <div class="field-card-number">
          <v-text-field
            v-model="holder"
            class="form-control"
            outlined
            placeholder="Your name and surname"
          />
        </div>
      </div>
    </div>
    <div class="agree-text">
      <div>
        <v-checkbox v-model="isAgree" />
      </div>
      <div class="agree-text__text">
        I have read and agree to the Terms of Service
      </div>
    </div>
    <div class="buttons-wrapper">
      <div class="buttons">
        <v-btn width="130px" height="45px" @click="onCancel">
          Cancel
        </v-btn>
        <v-btn
          color="primary"
          width="130px"
          height="45px"
          :disabled="canPay()"
          @click="onBuySuite"
        >
          PAY
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Payment",
  props: {
    amount: {
      type: Number,
      default: 0,
    },
  },
  data: function() {
    return {
      cardNumber: "",
      until: "",
      cvc: "",
      holder: "",
      isAgree: false,
    };
  },
  methods: {
    formatMoney(val) {
      return val.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    },
    onBuySuite() {
      this.$emit("onBuySuite");
      Object.assign(this.$data, this.$options.data());
    },
    canPay() {
      if (
        this.cardNumber &&
        this.until &&
        this.cvc &&
        this.holder &&
        this.isAgree
      ) {
        return false;
      } else {
        return true;
      }
    },
    onCancel() {
      this.$emit("onCancel");
    },
  },
};
</script>
<style scoped lang="scss">
.payment {
  background: white;
  padding: 25px;
  min-height: 600px;
}
.total-price {
  font-size: 23px;
  display: flex;
}
.price {
  display: flex;
  font-size: 32px;
  color: var(--v-primary-base) !important;
}
.inp-card-wrapper {
  display: flex;
  background: whitesmoke;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 18px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.field-card-number {
  .v-input {
    width: 400px;
    // border-radius: 20px;
  }
}
::v-deep fieldset {
  background: white !important;
}
.buttons-wrapper {
  margin-top: 70px;
  display: flex;
  justify-content: flex-end;
  .buttons {
    display: flex;
    column-gap: 10px;
  }
}
.second-field {
  .v-input {
    // border-radius: 20px;
    height: 50px;
    width: 190px;
  }
}
.cvc-field {
  position: relative;
  left: 15px;
}
.agree-text {
  display: flex;
  &__text {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
