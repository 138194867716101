<template>
  <div class="scoped-of-work-page">
    <v-row class="mx-0 mb-table mt-0">
      <v-btn color="primary" tile class="text-none add-area-btn" width="height">
        Create new Request for Payment +
      </v-btn>
    </v-row>
    <DirectoryTable :headers="headers" :items="items" />
  </div>
</template>
<script>
import DirectoryTable from "./components/DirectoryTable";
export default {
  name: "Payments",
  components: { DirectoryTable },

  data: function() {
    return {
      items: [
        {
          title: "Payment Request ABC-123",
          ariaTitle: "Hotel 01",
          consultant: "Lighting Consultant",
          designStage: "Preparation & Briefing",
          date: "2021-03-26",
          status: "Paid",
          value: "$ ----.--",
        },
        {
          title: "Payment Request ABC-123",
          ariaTitle: "Hotel 01",
          consultant: "Lighting Consultant",
          designStage: "Preparation & Briefing",
          date: "2021-03-26",
          status: "Approved",
          value: "$ ----.--",
        },
        {
          title: "Payment Request ABC-123",
          ariaTitle: "Hotel 01",
          consultant: "Lighting Consultant",
          designStage: "Preparation & Briefing",
          date: "2021-03-26",
          status: "Pending",
          value: "$ ----.--",
        },
        {
          title: "Payment Request ABC-123",
          ariaTitle: "Hotel 01",
          consultant: "Lighting Consultant",
          designStage: "Preparation & Briefing",
          date: "2021-03-26",
          status: "Rejected",
          value: "$ ----.--",
        },
      ],
      headers: [
        {
          title: "Title",
          background: true,
        },
        {
          title: "Area Title",
          background: true,
        },
        {
          title: "Consultant",
          background: true,
        },
        {
          title: "Design Stage",
          background: true,
        },
        {
          title: "Date",
          background: true,
        },
        {
          title: "Status",
          background: true,
        },
        {
          title: "Value",
          background: true,
        },
      ],
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
  },

  methods: {},
};
</script>
<style scoped lang="scss">
.scoped-of-work-page {
  overflow: auto;
}
.add-area-btn {
  font-size: 16px !important;
  border-radius: 0px !important;
  min-width: 200px !important;
  padding: 0 20px !important;
  height: 64px !important;
}
.mb-table {
  margin-bottom: 30px;
}
</style>
