<template>
  <div class="order-summary">
    <div class="d-flex title">
      Order Summary
    </div>
    <Card>
      <div class="content-wrapper">
        <v-row v-for="(order, i) in orders" :key="`orders-${i}`" class="c-row">
          <v-col sm="6" class="d-flex justify-start">
            <div class="text-left">
              <div>
                Scope of work for:
              </div>
              <div>
                {{
                  getIndustryTypeById(order.industryType) +
                    " " +
                    getContractTypeById(order.contractType) +
                    " " +
                    getConsultantDisciplineById(order.classification.id)
                }}
              </div>
            </div>
          </v-col>
          <v-col sm="6" class="d-flex justify-end align-end">
            <div>${{ order.value.amount }}</div>
            <div>
              <v-icon @click="deleteOrder(i)">
                mdi-delete
              </v-icon>
            </div>
          </v-col>
        </v-row>
        <v-row v-if="orders.length === 0" class="c-row">
          <div class="text-left empty-card-text">
            Your Cart Is Currently Empty!
          </div>
          <div class="text-left empty-card-text">
            Before proceed to checkout you must add some Scope of Works
            <br>documents to your shopping cart
          </div>
        </v-row>
      </div>
      <div class="second-content">
        <v-row>
          <v-col sm="7" />
          <v-col sm="5">
            <h4 class="d-flex justify-space-between">
              <div>
                SUB TOTAL
              </div>
              <div class="ml-2">
                <div v-if="orders.length !== 0">
                  {{ formatMoney(calcOrderSummary) }}
                </div>
                <div v-else>
                  $--.--
                </div>
              </div>
            </h4>
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="8" />
          <v-col sm="4">
            <h4 class="d-flex justify-space-between">
              <div>
                VAT
              </div>
              <div class="ml-2">
                <div v-if="orders.length !== 0">
                  {{ formatMoney(calcVat) }}
                </div>
                <div v-else>
                  $--.--
                </div>
              </div>
            </h4>
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="8" />
          <v-col sm="4">
            <h4 class="d-flex justify-space-between">
              <div>
                TOTAL
              </div>
              <div v-if="orders.length !== 0" class="ml-2">
                {{ formatMoney(calcTotal) }}
              </div>
              <div v-else>
                $--.--
              </div>
            </h4>
          </v-col>
        </v-row>
      </div>
      <v-row>
        <v-col sm="12">
          <v-btn
            :disabled="orders.length === 0"
            width="100%"
            color="black"
            class="white--text"
            height="60px"
            @click="onPay"
          >
            CONTINUE TO CHECKOUT
          </v-btn>
        </v-col>
      </v-row>
    </Card>
  </div>
</template>
<script>
import Card from "@/components/Card/Card.vue";
import {
  getConsultantDisciplineById,
  getIndustryTypeById,
  getContractTypeById,
} from "@/utils/getValueFromJSONById/getValueFromJSONById";

export default {
  name: "OrderSummary",
  components: {
    Card,
  },
  props: {
    orders: Array,
    default: [],
  },
  computed: {
    calcOrderSummary() {
      let sum = 0;
      this.orders.forEach((o) => {
        sum += o.value.amount;
      });
      return sum;
    },
    calcVat() {
      return (this.calcOrderSummary * 5) / 100;
    },
    calcTotal() {
      return this.calcOrderSummary + this.calcVat;
    },
  },
  methods: {
    deleteOrder(id) {
      this.$emit("deleteFromOrder", id);
    },
    getConsultantDisciplineById,
    getIndustryTypeById,
    getContractTypeById,
    formatMoney(val) {
      return val.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    },
    onPay() {
      this.$emit("onPay", this.calcTotal);
    },
  },
};
</script>
<style lang="scss" scoped>
.order-summary {
  min-height: 600px;
  .title {
    font-weight: 800;
  }
}
.content-wrapper {
  padding: 30px;
  display: flex;
  row-gap: 20px;
  flex-direction: column;
  min-height: 130px;
}
.second-content {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 30px;
  padding-left: 30px;
}
.c-row {
  background: white;
}
.empty-card-text {
  padding-left: 10px;
  font-size: 15px;
  padding-top: 6px;
}
</style>
