<template>
  <div class="dialog">
    <v-dialog v-model="isScopeDialogOpen" width="1600">
      <div class="pos-relative">
        <div class="icon-close-wrapper pointer" @click="toogleScopeModalOpen()">
          <v-icon>
            mdi-close
          </v-icon>
        </div>
        <ScopeDialog
          :previewed-scope="previwedScope"
          :is-edit-mode="false"
          :can-edit="false"
          @toggleDialog="toogleScopeModalOpen"
        />
      </div>
    </v-dialog>
    <div class="dialog__title">
      tender preview
    </div>

    <div class="dialog__content">
      <div class="container">
        <div class="info-item">
          <div class="info-item__data">
            Tender title
          </div>
          <div class="info-item__data">
            {{ tender.title }}
          </div>
        </div>

        <div class="info-item">
          <div class="info-item__data">
            Tender Opening Date
          </div>
          <div class="info-item__data">
            {{ tender.tenderPeriod.startDate }}
          </div>
        </div>
        <div class="info-item">
          <div class="info-item__data">
            Tender Query Period Closing
          </div>
          <div class="info-item__data">
            {{ tender.tenderPeriod.endDate }}
          </div>
        </div>
        <div class="info-item">
          <div class="info-item__data">
            Awarding Date
          </div>
          <div class="info-item__data">
            {{ tender.awardPeriod.endDate }}
          </div>
        </div>
        <div class="info-item">
          <div class="info-item__data">
            Contract Start Date
          </div>
          <div class="info-item__data">
            {{ tender.contractPeriod.startDate }}
          </div>
        </div>
        <div class="info-item">
          <div class="info-item__data">
            Contract Finish Date
          </div>
          <div class="info-item__data">
            {{ tender.contractPeriod.endDate }}
          </div>
        </div>
        <div class="info-item">
          <div class="info-item__data">
            Contact Person
          </div>
          <div class="info-item__data">
            -
          </div>
        </div>
        <div class="attachments">
          <div class="attachments__title">
            Attachments
          </div>
          <div
            v-for="(document, d) in tender.documents"
            :key="`documnent-${d}`"
            class="attachments__document"
          >
            {{ document.title }}
          </div>
          <div
            class="attachments__document pointer"
            @click="onScopeModalOpen(tender.items[0].classification)"
          >
            {{ getConsultantDisciplineById(tender.items[0].classification.id) }}
          </div>
          <div
            v-for="(item, i) in tender.items[0].additionalClassification"
            :key="`sub-conultant-${i}`"
            class="attachments__document pointer"
            @click="
              onScopeModalOpen({
                id: item.id,
              })
            "
          >
            {{ getConsultantDisciplineById(item.id) }}
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="dialog__buttons">
        <div class="c-btn-red">
          DECLINE
        </div>
        <div class="btn-group">
          <div class="c-btn-blue">
            CREATE QUERY
          </div>
          <v-btn color="primary" width="auto" height="45px">
            CREATE SUBMISSION
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ScopeDialog from "../../ScopeOfWorks/components/ScopeDialog/ScopeDialog.vue";
import {
  getConsultantDisciplineById,
  getConsultantCodeById,
  getStageCodeById,
} from "@/utils/getValueFromJSONById/getValueFromJSONById";
export default {
  name: "EtenderingViewModal",
  components: { ScopeDialog },
  props: {
    tender: {
      type: Object,
      default: null,
    },
  },
  data: function() {
    return {
      isScopeDialogOpen: false,
      previwedScope: null,
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
  },
  methods: {
    getConsultantDisciplineById,
    onScopeModalOpen(classification) {
      let findedCons;
      let areas = this.$store.getters["projectsModule/getProjectById"](this.id)
        .areas;
      areas.forEach((a) => {
        a.consultants.forEach((c) => {
          if (c.classification.id === this.tender.items[0].classification.id) {
            findedCons = c;
          }
        });
      });

      this.previwedScope = JSON.parse(JSON.stringify(findedCons));
      this.previwedScope.classification = classification;
      this.$store.commit(
        "scopeOfWorksModule/setCurrentDialog",
        "t-prepAndBrief"
      );
      this.toogleScopeModalOpen();
    },
    toogleScopeModalOpen() {
      this.isScopeDialogOpen = !this.isScopeDialogOpen;
    },
    getScopeById(consultant) {
      console.log("getScopeById props", consultant);
      let findedCons;
      let areas = this.$store.getters["projectsModule/getProjectById"](this.id)
        .areas;
      areas.forEach((a) => {
        a.consultants.forEach((c) => {
          if (c.classification.id === consultant.classification.id) {
            findedCons = c;
          }
        });
      });

      console.log("findedCons", findedCons);
      return findedCons;
    },
  },
};
</script>
<style scoped lang="scss">
.dialog {
  background: white;
  height: 697px;
  flex-direction: column;
  overflow: auto;
  overflow-x: hidden;
  display: flex;
  &__content {
    height: 509px;
    overflow: auto;
  }
  &__title {
    text-transform: uppercase;
    min-height: 95px;
    width: 100%;
    background-color: var(--v-primary-base) !important;
    display: flex;
    color: white;
    padding-left: 50px;
    align-items: center;
  }
  &__buttons {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    margin-top: 20px;
    column-gap: 10px;
    bottom: 23px;
  }
}
.info-item {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  &__data {
    display: flex;
    width: 206px;
    text-align: left;
  }
}
.container {
  padding-left: 50px;
  padding-right: 50px;
}
.attachments {
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  &__title {
    display: flex;
  }
  &__document {
    display: flex;
    color: #5085a5;

    text-decoration: underline;
  }
}
.btn-group {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 30px;
}
.c-btn-blue {
  color: var(--v-primary-base) !important;
  cursor: pointer;
}
.c-btn-red {
  cursor: pointer;

  color: red;
}
</style>
