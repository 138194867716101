<template>
  <div class="page-wrapper">
    <div class="row">
      <div class="col-sm-6 col-md-4">
        <PersonalInfo />
      </div>
      <div class="col-sm-6 col-md-4">
        <SecurityInfo />
      </div>
      <div class="col-sm-6 col-md-4">
        <UserPreferences />
      </div>
    </div>
  </div>
</template>

<script>
import PersonalInfo from "@/views/Setup/PersonalInfo/Forms/PersonalInfo";
import SecurityInfo from "@/views/Setup/PersonalInfo/Forms/SecurityInfo";
import UserPreferences from "@/views/Setup/PersonalInfo/Forms/UserPreferences";

export default {
  components: {
    PersonalInfo,
    SecurityInfo,
    UserPreferences,
  },
};
</script>

<style scoped lang="scss">
.page-wrapper {
  padding: 30px 0;
}
</style>