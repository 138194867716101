export default {
  namespaced: true,
  state: {
    frameworks: [],
  },
  mutations: {
    setFrameworks(state, data) {
      state.frameworks = data;
    },
    addFramewrok(state, { frameworkId, scopeId }) {
      state.frameworks = state.frameworks.map((f) => {
        if (f.id === frameworkId) {
          // eslint-disable-next-line no-param-reassign
          f.allocatedDisciplines = [...f.allocatedDisciplines, scopeId];
        }
        return f;
      });
    },
    removeAllocatedDiscipline(state, { frameworkId, disciplineId }) {
      state.frameworks = state.frameworks.map((f) => {
        if (f.id === frameworkId) {
          // eslint-disable-next-line no-param-reassign
          f.allocatedDisciplines = f.allocatedDisciplines.filter(
            (d) => d !== disciplineId
          );
        }
        return f;
      });
    },
    getAllocatedByAreaType(state, areaType) {
      return state.framewroks.filter((f) => f.developmentType === areaType);
    },
  },

  getters: {
    getAllocatedByAreaType: (state) => (areaType) => {
      let allocatedList = state.frameworks.find(
        (f) => f.developmentType === areaType
      );

      return allocatedList ? [...allocatedList.allocatedDisciplines] : [];
    },
  },
};
