<template>
  <div>
    <Card>
      <div class="dashboard-tabs">
        <v-row>
          <v-col
            v-for="(tab, i) in tabs"
            :key="`tabs - ${i}`"
            sm="2"
            :class="tab === currentTab && 'active'"
            class="tab"
            @click="setCurrentTab(tab)"
          >
            {{ tab }}
          </v-col>
        </v-row>
        <div class="content">
          <div v-if="currentTab === 'PROGRAM'">
            <ProgramTab :headers="programTabHeaders" :items="projectAreas" />
          </div>
          <div v-if="currentTab === 'AREA & SOW'">
            <AreaTab :items="projectAreas" />
          </div>
          <div v-if="currentTab === 'VARIATIONS'">
            <VariationsTab
              :headers="variationsTabHeaders"
              :items="variationsTabItems"
            />
          </div>
          <div v-if="currentTab === 'REPORTING'">
            <ReportingTab />
          </div>
        </div>
      </div>
    </Card>
    <div class="d-flex justify-end">
      <v-btn color="primary" dark @click="exportToPDF()">
        Export to PDF
        <v-icon class="ml-3">
          mdi-play-circle
        </v-icon>
      </v-btn>
    </div>
  </div>
</template>
<script>
import VariationsTab from "./tabs/VariationsTab.vue";
import ProgramTab from "./tabs/ProgramTab.vue";
import ReportingTab from "@/views/Dashboard/components/DashboardTabs/tabs/ReportingTab";
import AreaTab from "./tabs/AreaTab.vue";
import Card from "@/components/Card/Card.vue";
import getProgramTabHeaders from "@/utils/programTabHeaders/programTabHeaders";

export default {
  name: "DashboardTabs",
  components: {
    ProgramTab,
    AreaTab,
    Card,
    ReportingTab,
    VariationsTab,
  },
  props: {
    projectAreas: Array,
  },
  data: function() {
    return {
      tabs: ["PROGRAM", "AREA & SOW", "VARIATIONS", "REPORTING"],
      currentTab: "PROGRAM",

      areaTabItems: [
        {
          area: "Hotel 01",
          arch: true,
          struc: true,
          mep: true,
          eng: true,
          aor: true,
          id: true,
        },
        {
          area: "F&B 1",
          arch: true,
          struc: true,
          mep: true,
          eng: true,
          aor: true,
          id: true,
        },
      ],

      variationsTabHeaders: [
        "Variation",
        "Area",
        "Consultant",
        "Sub-consultant",
        "Stage 1",
        "Stage 2",
        "Stage 3",
        "Stage 4",
        "Stage 5",
        "Stage 6",
        "Stage 7",
        "Stage 8",
      ],
    };
  },
  computed: {
    programTabHeaders() {
      return [
        "Area",
        "Consultant",
        "Sub-consultant",
        "Stage 1",
        "Stage 2",
        "Stage 3",
        "Stage 4",
        "Stage 5",
        "Stage 6",
        "Stage 7",
        "Stage 8",
      ];
    },
    id() {
      return this.$route.params.id;
    },
    variationsTabItems() {
      return this.$store.getters["projectsModule/getProjectById"](this.id)
        .processes[0].contracts;
    },
  },

  methods: {
    setCurrentTab(val) {
      this.currentTab = val;
    },
    exportToPDF() {
      window.print();
    },
  },
};
</script>
<style lang="scss" scoped>
.tab {
  border: 1px solid white;
  cursor: pointer;
  &.active {
    background: #5085a5;
    color: white;
  }
}
.content {
  min-height: 400px;
}
.dashboard-tabs {
  position: relative;
}
</style>
