<template>
  <div class="dialog">
    <div class="select-area">
      <div class="d-flex">
        <div>
          <v-autocomplete
            v-model="industryType"
            outlined
            label="Industry Type"
            :items="sampleData.precontraxIndustryTypes"
            item-text="title"
            item-value="id"
            clearable
          />
        </div>
        <div class="ml-2">
          <v-autocomplete
            v-model="contractType"
            outlined
            item-value="id"
            item-text="title"
            label="Contract Type"
            :items="sampleData.precontraxContractTypes"
            clearable
          />
          <div v-if="currentStages.length > 0" class="contract-stages">
            <div
              v-for="(item, i) in currentStages[0]"
              :key="`stages- ${i}`"
              class="contract-stages__item"
            >
              {{ `${i + 1}. ${item}` }}
            </div>
          </div>
        </div>
      </div>

      <div>
        <v-autocomplete
          v-model="discipline"
          outlined
          item-value="id"
          item-text="title"
          label="Discipline"
          :items="sampleData.precontraxDisciplines"
          return-object
          clearable
        />
      </div>
    </div>
    <div class="buttons">
      <v-btn width="130px" height="45px">
        Cancel
      </v-btn>
      <v-btn
        color="primary"
        width="130px"
        height="45px"
        :disabled="!isBtnDisabled()"
        @click="onButtonContinueClick"
      >
        Continue
      </v-btn>
    </div>
  </div>
</template>
<script>
import json from "@/createNewProjectData.json";
import sampleData from "@/simpleData.json";
import { uuid } from "vue-uuid";
import { generateMiletones } from "@/utils/generateMilestonesForConsultant/index";

export default {
  name: "CreateNewScope",
  data: function() {
    return {
      json: json,
      sampleData: sampleData,
      industryType: "",
      contractType: "",
      developmentType: "",
      discipline: "",
      milestones: [
        {
          code: "PB",
          deliverables: [],
          stageNumber: 1,
          title: "Preparation & Briefing",
          type: "delivery",
        },
        {
          code: "CD",
          deliverables: [],
          stageNumber: 2,
          title: "Concept Design",
          type: "delivery",
        },
        {
          code: "SD",
          deliverables: [],
          stageNumber: 3,
          title: "Schematic Design",
          type: "delivery",
        },
        {
          code: "DD",
          deliverables: [],
          stageNumber: 4,
          title: "Detailed Design",
          type: "delivery",
        },
        {
          code: "IFT",
          deliverables: [],
          stageNumber: 5,
          title: "Issued For Tender",
          type: "delivery",
        },
        {
          code: "TS",
          deliverables: [],
          stageNumber: 6,
          title: "Tender Services",
          type: "delivery",
        },
        {
          code: "IFC",
          deliverables: [],
          stageNumber: 7,
          title: "Issued For Construction",
          type: "delivery",
        },
        {
          code: "PC",
          deliverables: [],
          stageNumber: 8,
          title: "Post Contract Services",
          type: "delivery",
        },
      ],
      scopes: [],
      uuid: uuid.v1(),
    };
  },
  computed: {
    currentStages() {
      return this.json.contractTypesStages
        .filter((s) => {
          return (
            s.stageName ===
            this.contractType.charAt(0).toUpperCase() +
              this.contractType.slice(1)
          );
        })
        .map((s) => s.stages);
    },
  },
  methods: {
    generateMiletones,

    isBtnDisabled() {
      return (
        this.industryType !== "" &&
        this.industryType !== null &&
        this.contractType !== "" &&
        this.contractType !== null &&
        this.discipline !== "" &&
        this.discipline !== null
      );
    },
    getStages() {
      return [
        {
          classification: {
            id: this.discipline.id,
            scheme: "precontraxDisciplines",
          },
          deliverables: [],
          // id: "PB",
          type: "t-prepAndBrief",
        },
        {
          classification: {
            id: this.discipline.id,
            scheme: "precontraxDisciplines",
          },
          deliverables: [],
          // id: "CD",
          type: "t-conceptDesign",
        },
        {
          classification: {
            id: this.discipline.id,
            scheme: "precontraxDisciplines",
          },
          deliverables: [],
          // id: "SD",
          type: "t-schematicDesign",
        },
        {
          classification: {
            id: this.discipline.id,
            scheme: "precontraxDisciplines",
          },
          deliverables: [],
          // id: "DD",
          type: "t-detailedDesign",
        },
        {
          classification: {
            id: this.discipline.id,
            scheme: "precontraxDisciplines",
          },
          deliverables: [],
          // id: "IFT",
          type: "t-issuedForTender",
        },
        {
          classification: {
            id: this.discipline.id,
            scheme: "precontraxDisciplines",
          },
          deliverables: [],
          // id: "TS",
          type: "t-tenderServices",
        },
        {
          classification: {
            id: this.discipline.id,
            scheme: "precontraxDisciplines",
          },

          deliverables: [],
          // id: "IFC",
          type: "t-issuedForConstruction",
        },
        {
          classification: {
            id: this.discipline.id,
            scheme: "precontraxDisciplines",
          },

          deliverables: [],
          // id: "PC",
          type: "t-postContractServices",
        },
      ];
    },
    onButtonContinueClick() {
      this.$emit("onBtnContinueClick", {
        industryType: this.industryType,
        contractType: this.contractType,
        developmentType: this.developmentType,
        classification: {
          id: this.discipline.id,
          scheme: "precontraxDisciplines",
        },
        scopes: this.scopes,
        id: this.uuid,
        stages: this.getStages(),
        isCustom: true,
      });
      this.$store.commit(
        "scopeOfWorksModule/setCurrentDialog",
        "t-prepAndBrief"
      );
      Object.assign(this.$data, this.$options.data());
    },
  },
};
</script>
<style scoped lang="scss">
.dialog {
  padding: 19px 97px;
  min-height: 632px;
  background: white;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
}
::v-deep .v-select {
  width: 300px;
}
.buttons {
  display: flex;
  width: 100%;
  margin-top: 72px;
  column-gap: 10px;
  justify-content: flex-end;
}
::v-deep .v-list-item__title {
  text-align: left !important;
}
::v-deep .v-list-item__title {
  max-width: 300px !important;
  padding: 0px !important;
  text-align: left;
}
.contract-stages {
  position: absolute;
  height: 300px;
  width: 300px;
  padding: 10px;
  top: 153px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  background: whitesmoke;
  &__item {
    display: flex;
  }
}
</style>
