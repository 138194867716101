<template>
  <div :key="key" class="actions-page">
    <v-dialog v-model="isScopeDialogOpen" width="1500">
      <div class="pos-relative">
        <div class="icon-close-wrapper pointer" @click="toogleScopeModalOpen()">
          <v-icon>
            mdi-close
          </v-icon>
        </div>
        <ScopeDialog />
      </div>
    </v-dialog>

    <h3 class="card-title">
      MY ACTIONS
    </h3>
    <div class="table-wrapper">
      <v-simple-table>
        <thead>
          <tr>
            <th v-for="(header, i) in headers" :key="`action-header - ${i}`">
              <div>
                {{ header.text }}
              </div>
            </th>
            <th
              v-for="(approvals, aid) in allApprovalsCount"
              :key="`approval-head-${aid}`"
            >
              {{ "Approval " + (aid + 1) }}
            </th>
          </tr>
        </thead>
        <tbody v-if="processInstances.length > 0">
          <tr
            v-for="(proccess, i) in processInstances"
            :key="`proccess item - ${i}`"
          >
            <td class="text-left">
              <div class="proccess">
                {{
                  getProccessDefinitionTypeById(proccess.processDefinitionId)
                }}
              </div>
            </td>
            <td class="text-left">
              {{ proccess.initiator.name }}
            </td>
            <td>
              <v-btn color="primary" rounded>
                Open
              </v-btn>
            </td>
            <template v-for="(historyItem, hid) in proccess.history">
              <td :key="`history item-${hid}`">
                <div class="select-small centered">
                  <v-select
                    disabled
                    background-color="primary"
                    label="Select"
                    outlined
                    dark
                    class="min-height-select"
                    clearable
                    :value="historyItem.variables[0].value"
                    :items="values"
                  />
                </div>
              </td>
              <td
                v-if="
                  historyItem.variables[0].value === 'reject' &&
                    hid + 1 < allApprovalsCount
                "
                :key="`history-disabled-${hid}`"
              >
                <div class="select-small centered">
                  <v-select
                    dark
                    label="Select"
                    disabled
                    outlined
                    background-color="primary"
                    class="min-height-select"
                    clearable
                    :items="values"
                  />
                </div>
              </td>
            </template>

            <td v-if="proccess.token && proccess.token !== null">
              <div class="select-small centered">
                <v-select
                  label="Select"
                  outlined
                  :item-text="'value'"
                  background-color="primary"
                  dark
                  class="min-height-select"
                  :items="values"
                  :value="``"
                  :menu-props="{ offsetY: true }"
                  @change="(e) => onSelectChange(e, i)"
                />
              </div>
            </td>
          </tr>
        </tbody>
        <div v-else class="no-data-message">
          There is no data yet
        </div>
      </v-simple-table>
    </div>
  </div>
</template>
<script>
import { getProccessDefinitionTypeById } from "@/utils/getValueFromJSONById/getValueFromJSONById";

import ScopeDialog from "../../../ScopeOfWorks/components/ScopeDialog/ScopeDialog.vue";
import CSelect from "../../../../components/c-select/c-select.vue";
import { uuid } from "vue-uuid";

export default {
  name: "Actions",
  components: { ScopeDialog },

  data: function() {
    return {
      isScopeDialogOpen: false,
      key: 0,
      actions: [
        "Approved",
        {
          label: "Rejected",
          value: `Rejected - comments emailed
          to consultant`,
        },
      ],

      headers: [
        { text: "Type", value: "delayType" },
        { text: "Consultant", value: "consultant" },
        { text: "View deliverables", value: "view" },
      ],
      items: [],
      values: [
        {
          text: "Approved",
          value: "approve",
        },

        {
          text: "Rejected",
          value: "reject",
        },
      ],
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    approverWorkflowConfigurations() {
      return this.$store.getters["projectsModule/getProjectById"](this.id)
        .approverWorkflowConfigurations;
    },
    processInstances() {
      return this.$store.getters["projectsModule/getProjectById"](this.id)
        .processInstances;
    },
    currentUser() {
      return this.$store.state.auth.currentUser;
    },
    allApprovalsCount() {
      let count = 0;
      this.approverWorkflowConfigurations?.forEach((a) => {
        if (count < a.actions.length) {
          count = a.actions.length;
        }
      });
      return count;
    },
  },

  methods: {
    getProccessDefinitionTypeById,

    toogleScopeModalOpen() {
      this.isScopeDialogOpen = !this.isScopeDialogOpen;
    },
    createTokenForInstance(instance) {
      let aviableActionsLenght = this.approverWorkflowConfigurations.find(
        (c) => c.processDefinitionId === instance.processDefinitionId
      ).actions.length;
      if (
        instance.history[instance.history.length - 1].variables[0].value ===
        "reject"
      ) {
        return null;
      }
      if (aviableActionsLenght === instance.history.length) {
        return null;
      } else {
        return {
          activityId: uuid.v1(),
          startDate: `${new Date().toISOString()}`,
        };
      }
    },
    onSelectChange(val, index) {
      let newInstance = JSON.parse(
        JSON.stringify(this.processInstances[index])
      );
      newInstance.history.push({
        activityId: uuid.v1(),
        startDate: `${new Date().toISOString()}`,
        endDate: `${new Date().toISOString()}`,
        variables: [
          {
            id: "decision",
            value: val,
          },
        ],
        actor: {
          id: this.currentUser.id,
          name: `${this.currentUser.firstName} ${this.currentUser.lastName}`,
        },
      });
      newInstance.token = this.createTokenForInstance(newInstance);
      this.$store.commit("projectsModule/editProccesInstance", {
        id: this.id,
        newInstance: newInstance,

        proccesIndex: index,
      });
      // this.$forceUpdate();
      this.key += 1;
    },
  },
};
</script>
<style lang="scss" scoped>
.merged-column {
  position: absolute;
  top: 0px;
}
::v-deep th {
  border-top: thin solid rgba(0, 0, 0, 0.12);
}
::v-deep td {
  border-bottom: none !important;
}
::v-deep .v-list-item__title {
  text-align: left;
  text-transform: capitalize;
}
::v-deep .v-select__selection {
  text-transform: capitalize;
}
.actions-page {
  // position: relative;
  overflow: auto;
}
.no-data-message {
  position: absolute;
  left: 0;
  color: grey;
  right: 0;
  top: 170px;
}
.proccess {
  width: 188px;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
