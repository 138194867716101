<template>
  <div class="dialog">
    <div class="dialog__title">
      new Framework
    </div>
    <div class="dialog__content">
      <div class="fields">
        <div class="select-row">
          <div>
            <v-autocomplete
              v-model="industryType"
              outlined
              label="Industry Type"
              clearable
              :items="json.industryTypes"
            />
          </div>
        </div>
        <div class="select-row">
          <div>
            <v-autocomplete
              v-model="contractType"
              outlined
              label="Contract Type"
              clearable
              :items="json.contractTypes"
            />
          </div>
        </div>
        <div class="select-row">
          <div>
            <v-autocomplete
              v-model="developmentType"
              outlined
              label="Development Type"
              clearable
              :items="json.developmentTypes"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="dialog_buttons">
      <v-btn width="130px" height="45px" @click="onCancel">
        Cancel
      </v-btn>

      <v-btn
        color="primary"
        width="130px"
        height="45px"
        :disabled="canContinue()"
        @click="createNewFramework()"
      >
        Create
      </v-btn>
    </div>
  </div>
</template>
<script>
import json from "@/createNewProjectData.json";
import { uuid } from "vue-uuid";

export default {
  name: "CreateNewFrameworkDialog",
  data: function() {
    return {
      json: json,
      industryType: "",
      contractType: "",
      developmentType: "",
      uuid: uuid.v1(),
    };
  },
  methods: {
    canContinue() {
      return (
        this.checkStr(this.industryType) ||
        this.checkStr(this.contractType) ||
        this.checkStr(this.developmentType)
      );
    },
    onCancel() {
      Object.assign(this.$data, this.$options.data());

      this.$emit("toggle");
    },
    checkStr(str) {
      return str === "" || str === null;
    },
    createNewFramework() {
      this.$store.commit("myFrameworkModule/setFrameworks", [
        ...this.$store.state.myFrameworkModule.frameworks,
        {
          allocatedDisciplines: [],
          contractType: this.contractType,
          developmentType: this.developmentType,
          industryType: this.industryType,
          id: this.uuid,
        },
      ]);
      Object.assign(this.$data, this.$options.data());
      this.$emit("toggle");
    },
  },
};
</script>
<style scoped lang="scss">
.dialog {
  background: white;
  height: 697px;
  flex-direction: column;
  overflow: auto;
  overflow-x: hidden;
  display: flex;
  &__title {
    text-transform: uppercase;
    min-height: 95px;
    width: 100%;
    background-color: var(--v-primary-base) !important;
    display: flex;
    color: white;
    padding-left: 50px;
    align-items: center;
  }
  &_buttons {
    display: flex;
    width: 55%;
    justify-content: center;
    align-items: flex-end;
    align-self: flex-end;
    margin-top: 20px;
    column-gap: 10px;
    position: absolute;
    bottom: 23px;
  }
  .fields {
    // height: 697px;
    margin-top: 34px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .select-row {
    display: flex;
    column-gap: 10px;
    width: 613px;
  }
}
::v-deep .v-list-item__title {
  text-align: left !important;
}
</style>
