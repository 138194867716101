<template>
  <div class="table-wrapper">
    <v-dialog v-model="isTenderViewModalOpen" width="750">
      <div class="pos-relative">
        <div
          class="icon-close-wrapper pointer"
          @click="toggleEtenderViewModal()"
        >
          <v-icon>
            mdi-close
          </v-icon>
        </div>
        <div>
          <EtenderingViewModal :tender="currentPreviewedTender" />
        </div>
      </div>
    </v-dialog>
    <v-dialog v-model="isTenderCreateModalOpen" width="750">
      <div class="pos-relative">
        <div
          class="icon-close-wrapper pointer"
          @click="toggleEtenderCreateModal()"
        >
          <v-icon>
            mdi-close
          </v-icon>
        </div>
        <div>
          <EtenderingCreateModal
            :current-edit-tender="currentEditTender"
            @close="toggleEtenderCreateModal"
          />
        </div>
      </div>
    </v-dialog>
    <div class="d-flex flex-wrap">
      <v-btn
        color="primary"
        class="white--text d-flex add-area-btn"
        @click="toggleEtenderCreateModal()"
      >
        Create new tender
        <div>
          <v-icon>
            mdi-plus
          </v-icon>
        </div>
      </v-btn>
    </div>
    <v-simple-table>
      <thead>
        <td
          v-for="(header, i) in headers"
          :key="`scope-table-header-${i}`"
          class="table-header primary-background white--text text-left"
        >
          <div class="table-item-wrapper">
            {{ header }}
          </div>
        </td>
      </thead>
      <tbody>
        <tr
          v-for="(item, i) in processes"
          :key="`etenders-table-item -${i}`"
          @click="setCurrentPreviewedTender(item.tender)"
        >
          <td class="text-left tender-title">
            {{ item.tender.title }}
          </td>
          <td class="text-left">
            -
          </td>
          <td class="text-left">
            {{ item.tender.tenderPeriod.startDate }}
          </td>
          <td class="text-left">
            {{ item.tender.tenderPeriod.endDate }}
          </td>
          <td class="text-left">
            -
          </td>
          <td class="text-left">
            {{ getTenderStatusTitleById(item.tender.status) }}
          </td>
          <td class="d-flex justify-end align-center">
            <div class="export-btn">
              <v-menu left bottom offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on">
                    mdi-dots-vertical
                  </v-icon>
                </template>

                <v-list class="dialog-actions-list">
                  <div
                    class="pointer dialog-actions-list__item"
                    @click="toogleEditMode(item)"
                  >
                    Edit
                  </div>
                  <div class="pointer dialog-actions-list__item">
                    Send for workflow approval
                  </div>
                  <div class="pointer dialog-actions-list__item">
                    Cancel the tender
                  </div>
                </v-list>
              </v-menu>
            </div>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>
<script>
import EtenderingCreateModal from "./components/EtenderingCreateModal/EtenderingCreateModal.vue";
import EtenderingViewModal from "./components/EtenderingViewModal.vue";
import headers from "./tableHeaders.json";
import {
  getConsultantDisciplineById,
  getIndustryTypeById,
  getTenderStatusTitleById,
} from "@/utils/getValueFromJSONById/getValueFromJSONById";
export default {
  name: "Etendering",
  components: { EtenderingViewModal, EtenderingCreateModal },
  data: function() {
    return {
      headers: headers.headers,
      isTenderViewModalOpen: false,
      isTenderCreateModalOpen: false,
      currentPreviewedTender: null,
      isEditMode: false,
      currentEditTender: null,
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    processes() {
      return this.$store.getters["projectsModule/getProjectById"](this.id)
        .processes;
    },
  },
  methods: {
    getTenderStatusTitleById,
    toggleEtenderViewModal() {
      this.isTenderViewModalOpen = !this.isTenderViewModalOpen;
    },
    toggleEtenderCreateModal() {
      this.isTenderCreateModalOpen = !this.isTenderCreateModalOpen;
      this.currentEditTender = null;
    },
    setCurrentPreviewedTender(tender) {
      this.currentPreviewedTender = tender;
      this.toggleEtenderViewModal();
    },
    toogleEditMode(tender) {
      this.isEditMode = !this.isEditMode;
      this.currentEditTender = tender;
      this.isTenderCreateModalOpen = true;
    },
  },
};
</script>
<style scoped lang="scss">
.table-wrapper {
}
::v-deep td {
  padding: 10px;
}

.table-header {
  font-size: 14px;
  min-height: 38px;
}
.table-item-wrapper {
  display: flex;
  min-height: 44px;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  left: 7px;
}
::v-deep tr {
  cursor: pointer;

  // table-layout: fixed !important;
}
.tender-title {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.add-area-btn {
  font-size: 16px !important;
  border-radius: 0px !important;
  min-width: 200px !important;
  padding: 0 20px !important;
  height: 64px !important;
}
.v-data-table {
  margin-top: 30px;
}
</style>
