<template>
  <div class="dialog">
    <div class="dialog__title">
      PRE-QUALIFICATION QUESTIONS
    </div>
    <v-btn class="question-btn" rounded>
      +Add question
    </v-btn>
    <div class="dialog__content">
      <div class="table-title">
        <div>
          Question
        </div>
        <div class="anserd-type-col">
          Answer type
        </div>
      </div>
      <div
        v-for="(item, i) in questions"
        :key="`question-${i}`"
        class="table-item"
        :class="i === 0 ? 'mt-2' : 'mt-1'"
      >
        <div class="question">
          {{ item.question }}
        </div>
        <div class="answer">
          <div class="d-flex justify-space-between">
            <div class="answer-col">
              {{ item.answer }}
            </div>
            <div>
              <v-icon>
                mdi-dots-vertical
              </v-icon>
            </div>
          </div>
        </div>
      </div>
      <div class="requirements">
        <div class="requirements__title">
          <div>
            Requirements
          </div>
          <div class="ml-2">
            <v-tooltip top color="black">
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on">
                  mdi-help-circle-outline
                </v-icon>
              </template>
              <span v-html="''" />
            </v-tooltip>
          </div>
        </div>
        <div class="d-flex justify-center c-wrapper mt-2">
          <file-upload
            ref="upload"
            class="btn btn-primary"
            post-action="/upload/post"
            extensions="gif,jpg,jpeg,png,webp"
            :size="1024 * 1024 * 10"
          >
            <div class="c-file-upload">
              <v-icon>
                mdi-cloud-upload
              </v-icon>
              <div class="c-file-upload__label">
                Chose files to upload
              </div>
              <div class="c-file-upload__subLabel">
                Or drag and drop them here
              </div>
            </div>
          </file-upload>
        </div>
      </div>
    </div>
    <div class="dialog_buttons">
      <v-btn width="130px" height="45px" @click="toggleDialog()">
        Cancel
      </v-btn>

      <v-btn
        color="primary"
        width="130px"
        height="45px"
        @click="toggleDialog()"
      >
        Save
      </v-btn>
    </div>
  </div>
</template>
<script>
import FileUpload from "vue-upload-component";

export default {
  name: "PreQualificationQuestions",
  components: {
    FileUpload,
  },
  data: function() {
    return {
      questions: [
        {
          question:
            "1. Vel turpis nunc eget lorem dolor sed viverra ipsum nunc. ",
          answer: "Text field",
        },
        {
          question: "2. Mauris a diam maecenas sed.",
          answer: "Text field",
        },
        {
          question: "3. Sed adipiscing diam donec adipiscing tristique risus. ",
          answer: "Number field",
        },
        {
          question: "4. Viverra nibh cras pulvinar mattis nunc.",
          answer: "Number field",
        },
        {
          question: "5. Vitae elementum curabitur",
          answer: "Number field",
        },
        {
          question: "6. Montes nascetur ridiculus",
          answer: "Text field",
        },
        {
          question:
            "7. Vel turpis nunc eget lorem dolor sed viverra ipsum nunc. ",
          answer: "Text field",
        },
        {
          question: "8. Mauris a diam maecenas sed.",
          answer: "Number field",
        },
        {
          question:
            "9. Sed adipiscing diam donec adipiscing tristique risus nec in. ",
          answer: "Choice",
        },
        {
          question: "10. Viverra nibh cras pulvinar mattis nunc. ",
          answer: "Text field",
        },
        {
          question: "11. Vitae elementum curabitur",
          answer: "Choice",
        },
        {
          question: "12. Montes nascetur ridiculus",
          answer: "File",
        },
      ],
    };
  },
  methods: {
    toggleDialog() {
      this.$emit("toggleDialog");
    },
  },
};
</script>
<style scoped lang="scss">
.dialog {
  background: white;
  height: 916px;
  flex-direction: column;
  overflow: auto;
  overflow-x: hidden;
  display: flex;
  &__title {
    text-transform: uppercase;
    min-height: 95px;
    width: 100%;
    background-color: var(--v-primary-base) !important;
    display: flex;
    color: white;
    padding-left: 50px;
    align-items: center;
  }
  &__content {
    padding-left: 50px;
    padding-right: 50px;
    margin-top: 30px;
  }
  &_buttons {
    display: flex;
    width: 100%;
    /* justify-content: center; */
    /* align-items: flex-end; */
    /* align-self: flex-end; */
    margin-top: 30px;
    -moz-column-gap: 10px;
    column-gap: 10px;
    justify-content: flex-end;
    padding-right: 50px;
  }
}
.question-btn {
  height: 38px;
  width: 144px;
  position: absolute;
  top: 67px;
  left: 6px;
  padding: 23px;
}
.table-title {
  display: flex;
  font-weight: bold;
  justify-content: space-between;
  padding-bottom: 20px;
  border-bottom: 1px solid black;
}
.table-item {
  display: flex;
  justify-content: space-between;
}
.answer {
  text-align: left;
  width: 120px;
  white-space: nowrap;
}
.question {
  width: 400px;
  white-space: nowrap;
  text-align: left;
}
.requirements {
  margin-top: 30px;
  &__title {
    display: flex;
  }
}
.c-wrapper {
  width: 100%;
}
::v-deep.file-uploads {
  width: 100% !important;
}
::v-deep file-upload {
  width: 613px !important;
}
.anserd-type-col {
  position: relative;
  right: 51px;
}
.answer-col {
  position: relative;
  right: 28px;
}
</style>
