<template>
  <v-form ref="form" class="security-info-form px-5 px-lg-15" @submit.prevent="onSubmit">
    <h3 class="form-title">
      Security Info
    </h3>
    <p v-if="showError" class="red--text">
      Current password is incorrect
    </p>
    <v-text-field
      v-model="formData.currentPassword"
      :rules="[validators.required]"
      outlined
      class="form-input"
      :type="showCurrentPassword ? 'text' : 'password'"
      label="Current Password"
    >
      <template v-if="formData.currentPassword" v-slot:append>
        <v-icon @click="showCurrentPassword = !showCurrentPassword">
          {{ showCurrentPassword ? 'mdi-eye-off-outline' : 'mdi-eye-outline' }}
        </v-icon>
      </template>
    </v-text-field>
    <v-text-field
      v-model="formData.newPassword"
      :rules="[validators.required, validators.minLength(6)]"
      outlined
      class="form-input"
      :type="showNewPassword ? 'text' : 'password'"
      label="New Password"
    >
      <template v-if="formData.newPassword" v-slot:append>
        <v-icon @click="showNewPassword = !showNewPassword">
          {{ showNewPassword ? 'mdi-eye-off-outline' : 'mdi-eye-outline' }}
        </v-icon>
      </template>
    </v-text-field>
    <v-text-field
      v-model="confirmPassword"
      :rules="[validators.required, matchPassword]"
      outlined
      class="form-input"
      :type="showConfirmPassword ? 'text' : 'password'"
      label="Confirm Password"
    >
      <template v-if="confirmPassword" v-slot:append>
        <v-icon @click="showConfirmPassword = !showConfirmPassword">
          {{ showConfirmPassword ? 'mdi-eye-off-outline' : 'mdi-eye-outline' }}
        </v-icon>
      </template>
    </v-text-field>
    <FormButtons class="mt-5" @cancel="resetForm" />
  </v-form>
</template>

<script>
import FormButtons from "./FormButtons";
import validators from "@/utils/validators";

export default {
  components: {
    FormButtons,
  },
  data() {
    return {
      validators,
      formData: {
        currentPassword: '',
        newPassword: '',
      },
      confirmPassword: '',
      showError: false,
      showCurrentPassword: false,
      showNewPassword: false,
      showConfirmPassword: false,
    };
  },
  computed: {
    matchPassword() {
      return (v) => v === this.formData.newPassword || 'Passwords don\'t match';
    },
  },
  watch: {
    'formData.currentPassword'() {
      this.showError = false;
    },
  },
  methods: {
    resetForm() {
      this.formData.currentPassword = '';
      this.formData.newPassword = '';
      this.confirmPassword = '';
      this.$refs.form.resetValidation();
    },
    async onSubmit() {
      if (this.$refs.form.validate()) {
        try {
          await this.$store.dispatch('auth/changePassword', this.formData);
          this.resetForm();
        } catch (e) {
          this.showError = true;
          console.log(e);
        }
      }
    },
  }
};
</script>

<style scoped lang="scss">
.form-title {
  margin-bottom: 20px;
  font-size: 16px;
  text-transform: uppercase;
}
</style>