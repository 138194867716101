<template>
  <div class="reset-password-page">
    <div class="fields">
      <h3 class="field white--text d-flex align-center justify-center">
        Registration
      </h3>
      <p v-if="showError" class="text-center red--text">
        An account with this email already exists.
      </p>
      <v-form ref="form" @submit.prevent="onSubmit">
        <div class="field mb-6">
          <v-text-field
            v-model="formData.firstName"
            outlined
            background-color="white"
            color="primary"
            :rules="[validators.required]"
            label="First Name*"
          />
        </div>
        <div class="field mb-6">
          <v-text-field
            v-model="formData.lastName"
            outlined
            background-color="white"
            color="primary"
            :rules="[validators.required]"
            label="Last Name*"
          />
        </div>
        <div class="field mb-6">
          <v-text-field
            v-model="formData.emailAddress"
            outlined
            background-color="white"
            color="primary"
            type="email"
            :rules="[validators.required, validators.email]"
            label="Email address*"
          />
        </div>
        <div class="field mb-6">
          <v-text-field
            v-model="formData.password"
            outlined
            background-color="white"
            color="primary"
            :rules="[validators.required, validators.minLength(6)]"
            :type="showPassword ? 'text' : 'password'"
            label="Password*"
          >
            <template v-if="formData.password" v-slot:append>
              <v-icon @click="showPassword = !showPassword">
                {{ showPassword ? "mdi-eye-off-outline" : "mdi-eye-outline" }}
              </v-icon>
            </template>
          </v-text-field>
        </div>
        <div class="field mb-6">
          <v-text-field
            v-model="formData.confirmPassword"
            outlined
            background-color="white"
            color="primary"
            :rules="[validators.required, matchPassword]"
            :type="showConfirmPassword ? 'text' : 'password'"
            label="Confirm Password*"
          >
            <template v-if="formData.confirmPassword" v-slot:append>
              <v-icon @click="showConfirmPassword = !showConfirmPassword">
                {{
                  showConfirmPassword
                    ? "mdi-eye-off-outline"
                    : "mdi-eye-outline"
                }}
              </v-icon>
            </template>
          </v-text-field>
        </div>

        <div class="field">
          <v-btn
            background="white"
            width="100%"
            height="100%"
            class="primary--text"
            type="submit"
          >
            Sign Up
          </v-btn>
        </div>
      </v-form>
      <div class="link-back-wrap">
        <router-link to="/" exact class="link-back">
          <div>
            <v-icon>
              mdi-chevron-left
            </v-icon>
          </div>
          <div>
            Go back to login
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import validators from "../../utils/validators";

export default {
  name: "SignUp",
  data() {
    return {
      validators,
      formData: {
        emailAddress: "",
        password: "",
        confirmPassword: "",
        firstName: "",
        lastName: "",
      },
      showError: false,
      showPassword: false,
      showConfirmPassword: false,
    };
  },
  computed: {
    matchPassword() {
      return (v) => v === this.formData.password || "Passwords don't match";
    },
  },
  watch: {
    "formData.emailAddress"() {
      this.showError = false;
    },
  },
  methods: {
    async onSubmit() {
      if (this.$refs.form.validate()) {
        try {
          await this.$store.dispatch("auth/register", this.formData);
          await this.$store.dispatch("auth/login", {
            email: this.formData.emailAddress,
            password: this.formData.password,
          });
          this.$router.push({ name: "Projects" });
        } catch (e) {
          this.showError = true;
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.reset-password-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 550px;
  max-height: 800px;
}
.fields {
  display: flex;
  flex-direction: column;
  .field {
    width: 420px;
    height: 58px;
  }
}
::v-deep input {
  color: #5085a5 !important;
}
::v-deep .v-label {
  color: #5085a5 !important;
}
::v-deep .v-label--active {
  color: var(--v-primary-base) !important;
  top: 23px !important;
  font-size: 18px !important;
}
</style>
