<template>
  <div class="scoped-of-work-page">
    <v-row class="mx-0 my-5 mt-0">
      <DirectoryCard :item="findObjectForCard('buyer')" />

      <DirectoryCard :item="findObjectForCard('procuringEntity')" />
    </v-row>
    <v-text-field
      v-model="searchValue"
      style="max-width: 300px"
      class="my-3"
      outlined
      label="Search"
      hide-details
    />
    <DirectoryTable :headers="headers" :items="items" />
  </div>
</template>
<script>
import DirectoryTable from "./components/DirectoryTable";
import DirectoryCard from "./components/DirectoryCard";
import { itemsFilter } from "@/utils/itemsFilter/itemsFilter";
export default {
  name: "Directory",
  components: { DirectoryTable, DirectoryCard },

  data: function() {
    return {
      allowedRolesForCards: ["buyer", "procuringEntity"],
      searchValue: "",
      headers: [
        {
          title: "Organisation Title",
          background: true,
        },
        {
          title: "Contact",
          background: true,
        },
        {
          title: "Email Address",
          background: true,
        },
        {
          title: "Phone Number",
          background: true,
        },
        {
          title: "Address",
          background: true,
        },
        {
          title: "Rating",
          background: true,
        },
      ],
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    items() {
      const parties = this.$store.getters["projectsModule/getProjectById"](
        this.id
      ).parties.filter(
        (item) => !this.allowedRolesForCards.includes(item.roles[0])
      );
      console.log(parties);
      if (parties) {
        return itemsFilter(parties, ["address"], this.searchValue);
      } else {
        return [];
      }
    },
  },
  methods: {
    findObjectForCard(status) {
      const part = this.$store.getters["projectsModule/getProjectById"](
        this.id
      ).parties.filter((item) => item.roles[0] === status);
      return part?.[0];
    },
  },
};
</script>
<style scoped lang="scss">
.scoped-of-work-page {
  overflow: auto;
}
</style>
