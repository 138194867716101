import { uuid } from "vue-uuid";

export default {
  namespaced: true,
  state: {
    scopeOfWorks: [
      {
        areaTag: "Hotel 01 ",
        disciplines: [
          {
            discipline: "Architect of Record",
            subConsultants: ["Structural", "MEP"],
          },
          {
            discipline: "Interior Design",
            subConsultants: ["Structural2"],
          },
          {
            discipline: "Extends to full list",
            subConsultants: [],
          },
        ],
      },
    ],
    pageData: {
      dialog: {
        currentDialog: "",
        currentArea: "",
        consultrant: "",
      },
      documents: null,
    },
  },
  mutations: {
    setCurrentDialog(state, dialog) {
      state.pageData.dialog.currentDialog = dialog;
    },
    setCurrentArea(state, area) {
      state.pageData.dialog.currentArea = area;
    },
    setCurrentConsultant(state, consultant) {
      state.pageData.dialog.consultrant = consultant;
    },
    setDocuments(state, docs) {
      console.log("docs", docs);
      state.pageData.documents = docs;
    },
    editDocument(state, { id, docVal }) {
      console.log("new doc ", doc);
      let doc = state.pageData.documents.find((d) => d.relatedItem === id);
      doc.url = docVal;
    },
    addDocument(state, delivarableId) {
      let newItem = {
        dateModified: "",
        datePublished: "",
        description: "",
        documentOf: "deliverable",
        documentType: "transmittal",
        format: "text/html",
        id: uuid.v1(),
        language: "en",
        relatedItem: delivarableId,
        title: "",
        url: "",
      };
      state.pageData.documents = [...state.pageData.documents, newItem];
    },
  },
};
