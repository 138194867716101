<template>
  <div class="login-page">
    <div class="fields">
      <div class="field">
        <v-btn :to="{ name: 'sign-up' }" color="primary" width="100%" height="100%">
          New User
        </v-btn>
      </div>
      <div class="field white--text d-flex align-center justify-center">
        Or
      </div>
      <p v-if="showError" class="text-center red--text">
        The email or password is incorrect.
      </p>
      <v-form ref="form" @submit.prevent="onSubmit">
        <div class="field mb-6">
          <v-text-field
            v-model="formData.email"
            outlined
            background-color="white"
            color="primary"
            type="email"
            :rules="[validators.required, validators.email]"
            label="Username"
          />
        </div>
        <div class="field">
          <v-text-field
            v-model="formData.password"
            outlined
            background-color="white"
            color="primary"
            :rules="[validators.required]"
            :type="showPassword ? 'text' : 'password'"
            label="Password"
          >
            <template v-if="formData.password" v-slot:append>
              <v-icon @click="showPassword = !showPassword">
                {{ showPassword ? 'mdi-eye-off-outline' : 'mdi-eye-outline' }}
              </v-icon>
            </template>
          </v-text-field>
        </div>
        <div class="field d-flex mt-2 align-center">
          <router-link :to="{ name: 'reset-password' }" class="forgot-password-link">
            Forgot password
          </router-link>
        </div>
        <div class="field">
          <v-btn
            background="white"
            width="100%"
            height="100%"
            class="primary--text"
            type="submit"
          >
            Sign in
          </v-btn>
        </div>
      </v-form>
    </div>
  </div>
</template>

<script>
import validators from '../../utils/validators';

export default {
  name: "Login",
  data() {
    return {
      validators,
      formData: {
        email: '',
        password: '',
      },
      showError: false,
      showPassword: false,
    };
  },
  watch: {
    'formData.email'() {
      this.showError = false;
    },
    'formData.password'() {
      this.showError = false;
    },
  },
  methods: {
    onSubmit() {
      if (this.$refs.form.validate()) {
        this.$store.dispatch('auth/login', this.formData)
          .then(() => {
            this.$router.push({ name: 'Projects' });
          })
          .catch(() => {
            this.showError = true;
          });
      }
    },
  }
};
</script>
<style lang="scss" scoped>
.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 550px;
  max-height: 800px;
}
.fields {
  display: flex;
  flex-direction: column;
  .field {
    width: 420px;
    height: 58px;
  }
}
::v-deep input {
  color: #5085a5 !important;
}
::v-deep .v-label {
  color: #5085a5 !important;
}
::v-deep .v-label--active {
  color: var(--v-primary-base) !important;
  top: 23px !important;
  font-size: 18px !important;
}

.forgot-password-link {
  text-decoration: none;
  color: #ffffff;
}
</style>
