var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tbody',{staticClass:"no-td-borders "},[_c('v-snackbar',{attrs:{"vertical":_vm.vertical,"color":"primary"},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarText)+" "),[_c('div',{staticClass:"snackbar-close"},[_c('v-icon',{on:{"click":function($event){return _vm.closeSnackbar()}}},[_vm._v(" mdi-close ")])],1)]],2),_c('tr',{staticClass:"text-center no-border"},[_c('td',[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"pointer",on:{"click":_vm.toggleOpen}},[(_vm.isOpen)?_c('v-icon',[_vm._v(" mdi-arrow-up-drop-circle-outline ")]):_c('v-icon',[_vm._v(" mdi-arrow-down-drop-circle-outline ")])],1),_c('div',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(_vm.consultants[0].area[0].name)+" ")])])]),_c('td',{staticClass:"text-left "},[_c('div',{staticClass:"table-item-description"},[_vm._v(" "+_vm._s(_vm.getConsultantDisciplineById(_vm.consultants[0].classification.id))+" ")])]),_vm._m(0),_vm._l((4),function(b){return _c('td',{key:("b-" + b),staticClass:"text-center"},[_c('v-btn',{attrs:{"rounded":"","color":"primary","height":"29px"},on:{"click":function($event){return _vm.openScopeDialog(b, _vm.consultants[0], _vm.item)}}},[_vm._v(" Open ")])],1)}),_c('td',[_c('v-btn',{attrs:{"rounded":"","color":"primary","height":"29px"},on:{"click":function($event){return _vm.createVariation(_vm.item)}}},[_vm._v(" Add+ ")])],1),_c('td',{staticClass:"text-left"},[_vm._v(" CD ")]),_c('td',{staticClass:"text-left"},[_vm._v(" 2021-06-01 ")]),_c('td',{staticClass:"text-left"},[_vm._v(" Approved ")]),_c('td',{staticClass:"text-left"},[_vm._v(" Pending ")]),_c('td',{staticClass:"d-flex justify-end align-center"},[_c('div',{staticClass:"export-btn"},[_c('v-menu',{attrs:{"left":"","bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-dots-vertical ")])]}}])},[_c('v-list',{staticClass:"dialog-actions-list"},[_c('div',{staticClass:"pointer dialog-actions-list__item",on:{"click":function($event){return _vm.onSendToWorkflow()}}},[_vm._v(" Send for workflow approval ")]),_c('div',{staticClass:"pointer dialog-actions-list__item",on:{"click":_vm.onExportToPDF}},[_vm._v(" Export to PDF ")])])],1)],1)])],2),_vm._l((_vm.consultants),function(consultantItem,i){return [(i !== 0 && _vm.isOpen && !consultantItem.isSub)?_c('tr',{key:("discipline-element-" + i)},[_c('td',[_vm._v(" "+_vm._s(consultantItem.area[0].name)+" ")]),_c('td',{staticClass:"text-left"},[_c('div',{staticClass:"table-item-description"},[_vm._v(" "+_vm._s(_vm.getConsultantDisciplineById(consultantItem.classification.id))+" ")])]),_c('td',{staticClass:"text-center"},[_vm._v(" - ")]),_vm._l((4),function(b){return _c('td',{key:("b-" + b),staticClass:"text-center"},[_c('v-btn',{attrs:{"rounded":"","color":"primary","height":"29px"},on:{"click":function($event){return _vm.openScopeDialog(b, consultantItem, _vm.item)}}},[_vm._v(" Open ")])],1)}),_c('td'),_c('td',{staticClass:"text-left"},[_vm._v(" CD ")]),_c('td',{staticClass:"text-left"},[_vm._v(" 2021-06-01 ")]),_c('td',{staticClass:"text-left"},[_vm._v(" Approved ")]),_c('td',{staticClass:"text-left"},[_vm._v(" Pending ")]),_c('td',{staticClass:"actions-row"},[_c('div',{staticClass:"export-btn"},[_c('v-menu',{attrs:{"left":"","bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-dots-vertical ")])]}}],null,true)},[_c('v-list',{staticClass:"dialog-actions-list"},[_c('div',{staticClass:"pointer dialog-actions-list__item",on:{"click":_vm.onExportToPDF}},[_vm._v(" Export to PDF ")])])],1)],1)])],2):_vm._e(),_vm._l((consultantItem.additionalClassification),function(subConsultant){return _c('tr',{key:_vm.getUniqCode() + "" + (subConsultant.id)},[(_vm.isOpen)?[_c('td'),_c('td'),_c('td',{staticClass:"d-flex remove-col-wrapper"},[_c('div',{staticClass:"table-item-description text-left "},[_c('div',{staticClass:"sub-consultant-id"},[_vm._v(" "+_vm._s(_vm.getConsultantDisciplineById(subConsultant.id))+" ")])])]),_vm._l((4),function(b){return _c('td',{key:("b-" + b),staticClass:"text-center"},[_c('v-btn',{attrs:{"rounded":"","color":"primary","height":"29px"},on:{"click":function($event){return _vm.openScopeDialogSubConsultant(
                b,
                consultantItem,
                _vm.item,
                subConsultant
              )}}},[_vm._v(" Open ")])],1)}),_c('td'),_c('td',{staticClass:"text-left"},[_vm._v(" CD ")]),_c('td',{staticClass:"text-left"},[_vm._v(" 2021-06-01 ")]),_c('td',{staticClass:"text-left"},[_vm._v(" Approved ")]),_c('td',{staticClass:"text-left"},[_vm._v(" Pending ")]),_c('td',{staticClass:"actions-row"},[_c('div',{staticClass:"export-btn"},[_c('v-menu',{attrs:{"left":"","bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-dots-vertical ")])]}}],null,true)},[_c('v-list',{staticClass:"dialog-actions-list"},[_c('div',{staticClass:"pointer dialog-actions-list__item",on:{"click":_vm.onExportToPDF}},[_vm._v(" Export to PDF ")])])],1)],1)])]:_vm._e()],2)})]})],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',{staticClass:"text-center"},[_c('div',[_vm._v(" - ")])])}]

export { render, staticRenderFns }