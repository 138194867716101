<template>
  <div>
    <div class="d-flex">
      Tender Rules
    </div>
    <div class="d-flex align-center item">
      <div>
        <v-checkbox disabled />
      </div>
      <div class="text-disabled">
        Allow access to the invitation after the closing date
      </div>
    </div>
    <div class="d-flex align-center item">
      <div>
        <v-checkbox disabled input-value="true" />
      </div>
      <div class="text-disabled">
        Allow bid submission after the closing date
      </div>
    </div>
    <div class="d-flex align-center item">
      <div>
        <v-checkbox disabled input-value="true" />
      </div>
      <div class="text-disabled">
        Don't allow access to tender submissions before the tender closing date
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "TenderRules",
};
</script>
<style scoped lang="scss">
.item {
  height: 40px;
}
</style>
