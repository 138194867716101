<template>
  <div class="setup-page text-left mt-5">
    <v-container>
      <div class="d-flex flex-wrap">
        <div
          v-for="(tab, i) in tabs"
          :key="`tabs - ${i}`"
          :class="tab === currentTab && 'active'"
          class="tab"
          @click="currentTab = tab"
        >
          {{ tab }}
        </div>
      </div>
      <div class="tab-wrapper">
        <PersonalInfoTab v-if="currentTab === 'Personal Info'" />
        <Users v-if="currentTab === 'User Accounts List'" />
      </div>
    </v-container>
  </div>
</template>

<script>
import Users from "@/views/Setup/Users/Users";
import PersonalInfoTab from "@/views/Setup/PersonalInfo/PersonalInfoTab";

export default {
  components: {
    Users,
    PersonalInfoTab,
  },
  data() {
    return {
      tabs: ['Personal Info', 'User Accounts List'],
      currentTab: 'Personal Info',
    };
  },
};
</script>

<style scoped lang="scss">
.tab {
  cursor: pointer;
  background-color: whitesmoke;
  min-width: 200px;
  height: 64px;
  padding: 20px;
  text-align: center;
  &:not(:last-of-type) {
    margin-right: 10px;
  }
  &.active {
    background: #5085a5;
    color: white;
  }
}
.tab-wrapper {
  border: 1px solid #929da3;
  height: calc(100vh - 250px);
  overflow: auto;
}
</style>