<template>
  <div>
    <h3 class="c-title">
      PROJECT DETAILS
    </h3>
    <div class="c-info">
      <div class="item">
        <div class="key-wrapper">
          <div class="key item-paddings">
            Development type :
          </div>
        </div>
        <div>
          <div
            v-for="(devType, d) in project.developmentType"
            :key="`dev-type-${d}`"
            class="value"
            :class="d === 0 && 'item-paddings -no-bottom'"
          >
            {{ devType }}
          </div>
        </div>
      </div>
      <div v-if="project.areas && project.areas.length > 0" class="item">
        <div class="key-wrapper">
          <div class="key item-paddings">
            Areas :
          </div>
        </div>
        <div>
          <div
            v-for="(area, i) in getUniqAreas(project.areas)"
            :key="`project areas - ${i}`"
            class="value"
            :class="i === 0 && 'item-paddings -no-bottom'"
          >
            {{ area.areaType }}
          </div>
        </div>
      </div>
      <div v-if="project.files.length > 0" class="item">
        <div class="key-wrapper">
          <div class="key  item-paddings">
            Documents :
          </div>
        </div>
        <div>
          <div
            v-for="(file, i) in project.files"
            :key="`project files - ${i}`"
            class="doc-link value "
            :class="i === 0 && 'item-paddings -no-bottom'"
          >
            {{ file.slice(file.lastIndexOf("/")).substring(1) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ProjectDetails",
  props: ["project"],
  methods: {
    getUniqAreas(areas) {
      let items = new Set(areas);
      items = Array.from(items);
      items.map((i) => {
        // eslint-disable-next-line no-param-reassign
        i.counter = 0;
        return i;
      });
      if (areas && areas.length > items.length) {
        items.map((i) => {
          let dublicatesCount = areas.filter((a) => a.areaType === i.areaType)
            .length;
          if (dublicatesCount > 1) {
            // eslint-disable-next-line no-param-reassign
            i.counter = dublicatesCount;
          }
          return item;
        });
      }
      console.log(items);

      return items;
    },
  },
};
</script>
<style scoped lang="scss">
.item-paddings {
  padding-top: 10px;
  padding-bottom: 10px;
  &.-no-bottom {
    padding-bottom: 0px;
  }
}
.c-title {
  font-size: 16px;
  display: flex;
}
.value {
  margin-left: 10px;
}
.key-wrapper {
  border-right: 1px solid grey;
}
.c-info {
  margin-top: 10px;
  .item {
    .key {
      display: flex;
      justify-content: flex-end;
      padding-right: 10px;
    }
    text-align: left;
    display: grid;
    grid-template-columns: 157px 140px;
  }
}
.doc-link {
  color: #5085a5;
  text-decoration: underline;
  cursor: pointer;
}
</style>
