export const variationHeaders = [
  {
    title: "Variation",
    background: true,
  },
  {
    title: "Area title",
    background: true,
  },
  {
    title: "Discipline",
    tooltip: `Autofills from project type.  </br>Editable only by project admin`,
  },

  {
    title: "Sub consultants",
    tooltip: `
        Transfer discipline under
         </br>
another discipline
</br>
by adding
        `,
  },

  {
    title: "Scope of works",
    tooltip: `
      Autofills from project type.
      </br>
Editable only by project admin
        `,
  },
  {
    title: "Set Program",
    background: true,
  },
  {
    title: "Scope  deleted",
    background: true,
  },
  {
    title: "Scope  added",
    background: true,
  },

  //
  {
    title: "",
    background: true,
  },
];
