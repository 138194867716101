<template>
  <div class="dashboard">
    <div class="main-data">
      <div class="data">
        <div class="top-level-component-height">
          <Card class="overflow-auto">
            <ProjectDetails :project="project" />
          </Card>
        </div>

        <div class="top-level-component-height">
          <Card>
            <Consultant />
          </Card>
        </div>
      </div>

      <div class="areas">
        <Card class="overflow-hidden top-level-component-height">
          <Areas />
        </Card>
      </div>
      <div class="overflow-hidden actions-component-wrapper">
        <Card>
          <Actions />
        </Card>
      </div>
      <div>
        <Card>
          <RecentActivities />
        </Card>
      </div>
    </div>
    <div class="tabs">
      <DashboardTabs :project-areas="project.areas" />
    </div>
  </div>
</template>

<script>
import Card from "@/components/Card/Card.vue";
import ProjectDetails from "./components/ProjectsDetails/ProjectDetails.vue";
import Consultant from "./components/Consultant/Consultant.vue";
import Areas from "./components/Areas/Areas.vue";
import Actions from "./components/Actions/Actions.vue";
import RecentActivities from "./components/RecentActivities/RecentActivities.vue";
import DashboardTabs from "./components/DashboardTabs/DashboardTabs.vue";
export default {
  name: "Dashboard",
  components: {
    Card,
    ProjectDetails,
    Consultant,
    Areas,
    Actions,
    RecentActivities,
    DashboardTabs,
  },

  computed: {
    id() {
      return this.$route.params.id;
    },
    project() {
      return this.$store.getters["projectsModule/getProjectById"](`${this.id}`);
    },
  },

  created() {
    console.log();
  },
};
</script>
<style scoped lang="scss">
.dashboard {
  display: grid;
}
.main-data {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 2fr 1fr;
}
.data {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr 2fr;
}
.tabs {
  overflow: hidden;
  margin-top: 10px;
}
.my-actions-wrapper {
  @media screen and (max-width: 1750px) {
  }
}
.actions-component-wrapper {
  min-height: 347px;
  position: relative;
  max-height: 347px;
}
.top-level-component-height {
  max-height: 238px;
}
.areas {
  @media screen and (min-width: 1750px) {
    min-width: 709px;
  }
}
</style>
