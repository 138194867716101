export const itemsFilter = (
  items,
  fields,
  searchValue,
  selectValue,
  selectFieldName
) => {
  const filteredItems = items.filter((item) => {
    console.log("fields", fields);
    return fields.some((f) => {
      if (selectValue) {
        return (
          item[f].toLowerCase().includes(searchValue.toLowerCase()) &&
          item[selectFieldName] === selectValue
        );
      } else {
        if (f === "classification") {
          return item?.classification?.id
            .toLowerCase()
            .includes(searchValue.toLowerCase());
        }
        if (f === "address") {
          return item?.address?.locality
            .toLowerCase()
            .includes(searchValue.toLowerCase()) ||
            item?.address?.countryName
              .toLowerCase()
              .includes(searchValue.toLowerCase());
        }
        else {
          if (item[f]) {
            return item[f].toLowerCase().includes(searchValue.toLowerCase());
          }
        }
      }
    });
  });
  return filteredItems;
};
export const multiplieSelectFilter = (items, fields, selectValues) => {
  const filteredItems = items.filter((item) => {
    return fields.every((fname) => {
      if (selectValues[fname] !== "" && selectValues[fname] !== null) {
        if (fname !== "classification") {
          return item[fname] === selectValues[fname];
        } else {
          if (selectValues.classification.id === "") {
            return true;
          } else {
            return item.classification
              ? item.classification.id === selectValues.classification.id
              : true;
          }
        }
      } else {
        return true;
      }
    });
  });
  return filteredItems;
};
