var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tbody',[_c('tr',[_c('td',{staticClass:"text-left"},[_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"item-area-wrapper"},[_vm._v(" "+_vm._s(_vm.item.variationId)+" ")]),_c('div',{staticClass:"d-flex align-center ml-1 open-btn",on:{"click":function($event){return _vm.toggleOpen()}}},[_c('v-icon',[_vm._v(" mdi-arrow-down-drop-circle-outline ")])],1)])]),_c('td',[_vm._v(" "+_vm._s(_vm.item.consultants[0].area[0].name)+" ")]),_c('td',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.getConsultantDisciplineById(_vm.item.consultants[0].classification.id))+" ")]),_c('td',{staticClass:"text-left "}),_vm._l((_vm.getConsultantStages(
        _vm.item.consultants[0].stages,
        _vm.item.consultants[0]
      )),function(stage,m){return _c('td',{key:("stages-1 -" + m),staticClass:"td-left"},[(stage)?_c('div',{staticClass:"status-panel area-table-green-color"},[_vm._v(" "+_vm._s((_vm.getStageCodeByType(stage.type)) + "-" + "" + (_vm.getMilestoneByStageId(_vm.item.consultants[0], stage.id)))+" ")]):_c('div',[_vm._v(" - ")])])}),_c('td',{staticClass:"text-left actions"},[_c('v-menu',{staticClass:"c-menu",attrs:{"left":"","bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-dots-vertical ")])]}}])},[_c('v-list',{staticClass:"dialog-actions-list"},[_c('div',{staticClass:"delete-btn pointer dialog-actions-list__item"},[_vm._v(" Export to PDF ")]),_c('div',{staticClass:"delete-btn pointer dialog-actions-list__item"},[_vm._v(" Send delay notification ")])])],1)],1)],2),(_vm.isOpen)?[_vm._l((_vm.item.consultants[0].additionalClassification),function(sub,i){return _c('tr',{key:("sub-consultant-dsadasdasd-tab-" + i)},[_c('td',{staticClass:"no-border"}),_c('td',{staticClass:"no-border"}),_c('td',{staticClass:"no-border"}),_c('td',{staticClass:"text-left no-border"},[_vm._v(" "+_vm._s(_vm.getConsultantDisciplineById(sub.id))+" ")]),_vm._l((_vm.getConsultantStages(_vm.item.consultants[0].stages, {
          classification: {
            id: sub.id,
          },
        })),function(stage,m){return _c('td',{key:("stages-2 -" + m),staticClass:"td-left no-border"},[(stage)?_c('div',{staticClass:"status-panel area-table-green-color"},[_vm._v(" "+_vm._s((_vm.getStageCodeByType(stage.type)) + "-" + "" + (_vm.getMilestoneByStageId(_vm.item.consultants[0], stage.id)))+" ")]):_c('div',[_vm._v(" - ")])])}),_c('td',{staticClass:"text-left no-border actions"},[_c('v-menu',{staticClass:"c-menu",attrs:{"left":"","bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-dots-vertical ")])]}}],null,true)},[_c('v-list',{staticClass:"dialog-actions-list"},[_c('div',{staticClass:"delete-btn pointer dialog-actions-list__item"},[_vm._v(" Export to PDF ")]),_c('div',{staticClass:"delete-btn pointer dialog-actions-list__item"},[_vm._v(" Send delay notification ")])])],1)],1)],2)}),_vm._l((_vm.item.consultants.slice(1)),function(constation,i){return [_c('tr',{key:("table-consultation-" + i)},[_c('td',{staticClass:"text-left no-border"}),(_vm.item.variation)?_c('td',{staticClass:"text-left no-border"}):_vm._e(),_c('td',{staticClass:"no-border"},[_vm._v(" "+_vm._s(constation.area[0].name)+" ")]),_c('td',{staticClass:"text-left no-border"},[_vm._v(" "+_vm._s(_vm.getConsultantDisciplineById(constation.classification.id))+" ")]),_c('td',{staticClass:"text-left no-border"}),_vm._l((_vm.getConsultantStages(
            constation.stages,
            constation
          )),function(stage,m){return _c('td',{key:("stages-2 -" + m),staticClass:"td-left no-border"},[(stage)?_c('div',{staticClass:"status-panel area-table-green-color"},[_vm._v(" "+_vm._s((_vm.getStageCodeByType(stage.type)) + "-" + "" + (_vm.getMilestoneByStageId(constation, stage.id)))+" ")]):_c('div',[_vm._v(" - ")])])}),_c('td',{staticClass:"text-left no-border actions"},[_c('v-menu',{staticClass:"c-menu",attrs:{"left":"","bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-dots-vertical ")])]}}],null,true)},[_c('v-list',{staticClass:"dialog-actions-list"},[_c('div',{staticClass:"delete-btn pointer dialog-actions-list__item"},[_vm._v(" Export to PDF ")]),_c('div',{staticClass:"delete-btn pointer dialog-actions-list__item"},[_vm._v(" Send delay notification ")])])],1)],1)],2),_vm._l((constation.additionalClassification),function(sub,i){return _c('tr',{key:("sub-consultant-a-tab-" + i)},[_c('td',{staticClass:"no-border"}),_c('td',{staticClass:"no-border"}),_c('td',{staticClass:"no-border"}),_c('td',{staticClass:"text-left no-border"},[_vm._v(" "+_vm._s(_vm.getConsultantDisciplineById(sub.id))+" ")]),_vm._l((_vm.getConsultantStages(constation.stages, {
            classification: {
              id: sub.id,
            },
          })),function(stage,m){return _c('td',{key:("stages-2 -" + m),staticClass:"td-left no-border"},[(stage)?_c('div',{staticClass:"status-panel area-table-green-color"},[_vm._v(" "+_vm._s((_vm.getStageCodeByType(stage.type)) + "-" + "" + (_vm.getMilestoneByStageId(constation, stage.id)))+" ")]):_c('div',[_vm._v(" - ")])])}),_c('td',{staticClass:"text-left no-border actions"},[_c('v-menu',{staticClass:"c-menu",attrs:{"left":"","bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-dots-vertical ")])]}}],null,true)},[_c('v-list',{staticClass:"dialog-actions-list"},[_c('div',{staticClass:"delete-btn pointer dialog-actions-list__item"},[_vm._v(" Export to PDF ")]),_c('div',{staticClass:"delete-btn pointer dialog-actions-list__item"},[_vm._v(" Send delay notification ")])])],1)],1)],2)})]})]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }