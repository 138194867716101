<template>
  <div>
    <h3 class="c-title">
      CONSULTANT | SCOPE AWARD STATUS
    </h3>
    <div class="c-info">
      <div class="items">
        <div class="item">
          <div>
            <div class="key">
              Total scope to be appointed:
            </div>
          </div>
          <div>
            <div class="value">
              42
            </div>
          </div>
        </div>
        <div class="item">
          <div class="key">
            <div>
              RFP prepared, approval rqd:
            </div>
          </div>
          <div>
            <div class="value">
              20
            </div>
          </div>
        </div>
        <div class="item">
          <div class="key">
            <div>
              RFP in tender process:
            </div>
          </div>
          <div>
            <div class="value">
              20
            </div>
          </div>
        </div>
        <div class="item">
          <div class="key">
            <div>
              Total scope in contract:
            </div>
          </div>
          <div>
            <div class="value">
              5
            </div>
          </div>
        </div>
        <div class="item">
          <div class="key">
            <div>
              Scope pending to be appointed:
            </div>
          </div>
          <div>
            <div class="value">
              40
            </div>
          </div>
        </div>
      </div>
      <div class="graphic">
        <fusioncharts
          ref="fc"
          :type="type"
          :width="width"
          :height="height"
          :data-format="dataFormat"
          :data-source="dataSource"
          @dataPlotClick="onSliceClick"
        />
      </div>
    </div>
  </div>
</template>

<script>
var dataSource = {
  chart: {
    plottooltext: "",
    showLegend: "0",
    showPercentValues: "1",
    legendPosition: "bottom",
    useDataPlotColorForLabels: "0",
    enableMultiSlicing: "0",
    theme: "fusion",
    showlegend: "0",
    showLabels: "0",
    paletteColors: " #c2deef ,#97CBE7,#074868 ,#5085a5,#205c85,#205c85",
    // showTooltip: "0",
    showCaption: "0",
    showValues: "0",
  },
  data: [
    {
      label: "Total scope to be appointed",
      value: "42",
    },
    {
      label: "RFP prepared, approval rqd:",
      value: "20",
    },
    {
      label: "RFP in tender process",
      value: "20",
    },
    {
      label: "Total scope in contract",
      value: "5",
    },
    {
      label: "Scope pending to be appointed",
      value: "40",
    },
  ],
};
export default {
  name: "Consultant",
  data: function() {
    return {
      type: "doughnut2d",
      width: "80%",
      height: "230",
      dataFormat: "json",
      dataSource: dataSource,
      radioValue: "none",
      lastActive: "none",
    };
  },
  methods: {
    // function to slice items when radio buttons are clicked using the slicePlotItems api
    onChangeItem: function() {
      const chart = this.$refs.fc.chartObj,
        lastActive = this.lastActive,
        value = this.radioValue;
      this.lastActive = value;
      if (value === "none") {
        chart.slicePlotItem(lastActive, false);
      } else {
        chart.slicePlotItem(value, true);
      }
    },
    // function to actiavte radio buttons when plots are clicked
    onSliceClick: function(e) {
      var isSliced = e.data.isSliced;
      if (isSliced) {
        this.lastActive = this.radioValue = "none";
      } else {
        this.lastActive = this.radioValue = e.data.index;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.c-title {
  display: flex;
  font-size: 16px;
}

.c-info {
  margin-top: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  .items {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    position: relative;
    top: 28px;
    .item {
      white-space: nowrap;
      text-align: left;
      margin-top: 0px;
      display: grid;
      grid-template-columns: 4fr 1fr;

      .key {
        display: flex;
        justify-content: flex-end;
        padding-right: 10px;
        border-right: 1px solid grey;
      }
      .value {
        margin-left: 10px;
      }
      &.--blue {
        color: #5085a5;
      }
    }
  }
}
.graphic {
  position: relative;
  bottom: 32px;
  rect {
    fill: red;
  }
}
</style>
