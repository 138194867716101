<template>
  <tbody>
    <tr>
      <td class="text-left">
        <div class="d-flex align-center">
          <div class="item-area-wrapper">
            {{ item.variationId }}
          </div>
          <div class="d-flex align-center ml-1 open-btn" @click="toggleOpen()">
            <v-icon>
              mdi-arrow-down-drop-circle-outline
            </v-icon>
          </div>
        </div>
      </td>
      <td>
        {{ item.consultants[0].area[0].name }}
      </td>
      <td class="text-left">
        {{ getConsultantDisciplineById(item.consultants[0].classification.id) }}
      </td>
      <td class="text-left " />

      <td
        v-for="(stage, m) in getConsultantStages(
          item.consultants[0].stages,
          item.consultants[0]
        )"
        :key="`stages-1 -${m}`"
        class="td-left"
      >
        <div v-if="stage" class="status-panel area-table-green-color">
          {{
            `${getStageCodeByType(stage.type)}-` +
              `${getMilestoneByStageId(item.consultants[0], stage.id)}`
          }}
        </div>
        <div v-else>
          -
        </div>
      </td>
      <td class="text-left actions">
        <v-menu left bottom offset-y class="c-menu">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on">
              mdi-dots-vertical
            </v-icon>
          </template>

          <v-list class="dialog-actions-list">
            <div class="delete-btn pointer dialog-actions-list__item">
              Export to PDF
            </div>
            <div class="delete-btn pointer dialog-actions-list__item">
              Send delay notification
            </div>
          </v-list>
        </v-menu>
      </td>
    </tr>
    <template v-if="isOpen">
      <tr
        v-for="(sub, i) in item.consultants[0].additionalClassification"
        :key="`sub-consultant-dsadasdasd-tab-${i}`"
      >
        <td class="no-border" />
        <td class="no-border" />
        <td class="no-border" />
        <td class="text-left no-border">
          {{ getConsultantDisciplineById(sub.id) }}
        </td>
        <td
          v-for="(stage, m) in getConsultantStages(item.consultants[0].stages, {
            classification: {
              id: sub.id,
            },
          })"
          :key="`stages-2 -${m}`"
          class="td-left no-border"
        >
          <div v-if="stage" class="status-panel area-table-green-color">
            {{
              `${getStageCodeByType(stage.type)}-` +
                `${getMilestoneByStageId(item.consultants[0], stage.id)}`
            }}
          </div>
          <div v-else>
            -
          </div>
        </td>

        <td class="text-left no-border actions">
          <v-menu left bottom offset-y class="c-menu">
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on">
                mdi-dots-vertical
              </v-icon>
            </template>

            <v-list class="dialog-actions-list">
              <div class="delete-btn pointer dialog-actions-list__item">
                Export to PDF
              </div>
              <div class="delete-btn pointer dialog-actions-list__item">
                Send delay notification
              </div>
            </v-list>
          </v-menu>
        </td>
      </tr>
      <template v-for="(constation, i) in item.consultants.slice(1)">
        <tr :key="`table-consultation-${i}`">
          <td class="text-left no-border" />
          <td v-if="item.variation" class="text-left no-border" />
          <td class="no-border">
            {{ constation.area[0].name }}
          </td>
          <td class="text-left no-border">
            {{ getConsultantDisciplineById(constation.classification.id) }}
          </td>
          <td class="text-left no-border" />
          <td
            v-for="(stage, m) in getConsultantStages(
              constation.stages,
              constation
            )"
            :key="`stages-2 -${m}`"
            class="td-left no-border"
          >
            <div v-if="stage" class="status-panel area-table-green-color">
              {{
                `${getStageCodeByType(stage.type)}-` +
                  `${getMilestoneByStageId(constation, stage.id)}`
              }}
            </div>
            <div v-else>
              -
            </div>
          </td>

          <td class="text-left no-border actions">
            <v-menu left bottom offset-y class="c-menu">
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on">
                  mdi-dots-vertical
                </v-icon>
              </template>

              <v-list class="dialog-actions-list">
                <div class="delete-btn pointer dialog-actions-list__item">
                  Export to PDF
                </div>
                <div class="delete-btn pointer dialog-actions-list__item">
                  Send delay notification
                </div>
              </v-list>
            </v-menu>
          </td>
        </tr>
        <tr
          v-for="(sub, i) in constation.additionalClassification"
          :key="`sub-consultant-a-tab-${i}`"
        >
          <td class="no-border" />
          <td class="no-border" />
          <td class="no-border" />
          <td class="text-left no-border">
            {{ getConsultantDisciplineById(sub.id) }}
          </td>
          <td
            v-for="(stage, m) in getConsultantStages(constation.stages, {
              classification: {
                id: sub.id,
              },
            })"
            :key="`stages-2 -${m}`"
            class="td-left no-border"
          >
            <div v-if="stage" class="status-panel area-table-green-color">
              {{
                `${getStageCodeByType(stage.type)}-` +
                  `${getMilestoneByStageId(constation, stage.id)}`
              }}
            </div>
            <div v-else>
              -
            </div>
          </td>

          <td class="text-left no-border actions">
            <v-menu left bottom offset-y class="c-menu">
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on">
                  mdi-dots-vertical
                </v-icon>
              </template>

              <v-list class="dialog-actions-list">
                <div class="delete-btn pointer dialog-actions-list__item">
                  Export to PDF
                </div>
                <div class="delete-btn pointer dialog-actions-list__item">
                  Send delay notification
                </div>
              </v-list>
            </v-menu>
          </td>
        </tr>
      </template>
    </template>
  </tbody>
</template>
<script>
import CSelect from "@/components/c-select/c-select.vue";
import {
  getStageCodeByType,
  getConsultantDisciplineById,
} from "@/utils/getValueFromJSONById/getValueFromJSONById";

export default {
  name: "VariationsSubTable",
  components: {},
  // eslint-disable-next-line vue/require-prop-types
  props: ["item", "maxStages"],
  data: function() {
    return {
      items: ["1", "2"],
      isOpen: false,
      actions: ["Export report", "Send delay notification"],
    };
  },
  methods: {
    getStageCodeByType,
    getConsultantDisciplineById,

    toggleOpen() {
      this.isOpen = !this.isOpen;
    },
    getMilestones(items, allDisciplines) {
      // if (items.length < this.maxStages) {
      //   while (items.length !== this.maxStages) {
      //     items.push(false);
      //   }
      // }
      // return items.map((i) => {
      //   // console.log(
      //   //   new Date().toLocaleString(),
      //   //   new Date(i.dueDate).toLocaleString()
      //   // );
      //   // console.log(new Date() < new Date(i.dueDate));
      //   return i;
      // });
      return items;
    },
    getConsultantStages(items, consultant) {
      console.log("items", items);
      console.log("consultant", consultant);
      return items.filter((s) => {
        return s.classification.id === consultant.classification.id;
      });
    },
    getMilestoneByStageId(item, stageId) {
      //   console.log(
      //     "milestone",
      //     this.item.milestones.find((m) => m.stageId === stageId)
      //   );
      return this.item.implementation.milestones.find(
        (m) => m.stageId === stageId
      ).dueDate;
    },
  },
};
</script>
<style lang="scss" scoped>
.open-btn {
  cursor: pointer;
}
::v-deep td {
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.actions {
  display: flex;
  justify-content: flex-end;
  position: relative;
  // top: 19px;
  align-items: center;
  button {
    bottom: 8px;
  }
}
.item-area-wrapper {
  width: 80px;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
