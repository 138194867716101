<template>
  <div class="dialog" :class="step === 2 && 'second'">
    <div class="dialog__title">
      new tender
    </div>
    <div class="dialog__content" :class="step === 2 && 'second'">
      <div v-if="step === 1" class="container">
        <div class="area-select-group">
          <div class="item main">
            <div class="d-flex justify-center align-center">
              <div>
                <v-checkbox @change="(e) => onSelectAll(e)" />
              </div>
              <div class="area-title-col select-item">
                Area Title
              </div>
            </div>
            <div class="right">
              Area Type
            </div>
          </div>

          <div
            v-for="(area, aid) in areas"
            :key="`areas etendrering -${aid}`"
            class="item"
          >
            <div class="d-flex justify-center align-center">
              <div>
                <v-checkbox v-model="selectedAreas" :value="area" />
              </div>
              <div class="area-title-col select-item">
                {{ area.areaTitle }}
              </div>
            </div>
            <div class="right">
              {{ area.areaType }}
            </div>
          </div>
        </div>
        <div class="autocomplete-wrapper">
          <v-autocomplete
            v-model="selectedConsultant"
            outlined
            label="Discipline"
            :items="identityConsultant"
            :item-text="`classification.id`"
            return-object
            clearable
          >
            <template v-slot:item="data">
              <div class="d-flex fixed-width">
                <div class="text">
                  {{ getConsultantDisciplineById(data.item.classification.id) }}
                </div>
              </div>
            </template>
            <template v-slot:selection="data">
              <div class="d-flex fixed-width">
                <div class="text">
                  {{ getConsultantDisciplineById(data.item.classification.id) }}
                </div>
              </div>
            </template>
          </v-autocomplete>
        </div>
      </div>
      <div v-if="step === 2" class="container">
        <div>
          <v-textarea outlined label="Title" :value="tenderTitle" />
        </div>
        <div class="input-row">
          <div class="item">
            <DatePicker
              :value.sync="tenderOpeningDate"
              label="Tender Opening Date*"
              show-icon
            />
          </div>
          <div class="item">
            <TimePicker
              :value.sync="tenderOpeningTime"
              label="Tender Opening Time*"
            />
          </div>
        </div>
        <div class="input-row">
          <div class="item">
            <DatePicker
              :value.sync="tenderQueryClosingDate"
              label="Tender Query Period Closing Date"
              show-icon
            />
          </div>
          <div class="item">
            <TimePicker
              :value.sync="tenderQueryClosingTime"
              label="Tender Query Period Closing Time"
            />
          </div>
        </div>
        <div class="input-row">
          <div class="item">
            <DatePicker
              :value.sync="tenderClosingDate"
              show-icon
              label="Tender Closing Date"
            />
          </div>
          <div class="item">
            <TimePicker
              :value.sync="tenderClosingTime"
              label="Tender Closing Time"
            />
          </div>
        </div>
        <div class="input-row">
          <div class="item">
            <DatePicker :value.sync="AwardingDate" label="Awarding Date" />
          </div>
        </div>
        <div class="input-row">
          <div class="item">
            <DatePicker
              :value.sync="contractStartDate"
              disabled
              label="Contract Start Date"
            />
          </div>
        </div>
        <div class="input-row">
          <div class="item">
            <DatePicker
              disabled
              :value.sync="contractFinishDate"
              label="Contract Finish Date"
            />
          </div>
        </div>
        <div>
          <v-text-field
            disabled
            :value="contactPerson.name"
            outlined
            label="Contact Person"
          />
        </div>
        <div>
          <v-autocomplete
            v-model="recipients"
            label="Recipients"
            multiple
            outlined
            item-text="emailAddress"
            return-object
            :items="users"
          />
        </div>
        <div>
          <TenderRules />
        </div>
        <div>
          <div class="d-flex mt-2">
            <Attachments
              :attachments="attachments"
              :consultant="selectedConsultant"
            />
          </div>
        </div>
        <div class="d-flex justify-center c-wrapper mt-4">
          <file-upload
            ref="upload"
            v-model="files"
            class="btn btn-primary"
            post-action="/upload/post"
            extensions="gif,jpg,jpeg,png,webp"
            :multiple="true"
            :size="1024 * 1024 * 10"
          >
            <div class="c-file-upload">
              <v-icon>
                mdi-cloud-upload
              </v-icon>
              <div class="c-file-upload__label">
                Chose files to upload
              </div>
              <div class="c-file-upload__subLabel">
                Or drag and drop them here
              </div>
            </div>
          </file-upload>
        </div>
        <div class="files-wrapper mt-1">
          <v-row v-for="(file, i) in files" :key="`file - ${i}`">
            <v-col class="d-flex">
              <div class="icon-wrap">
                <!-- <v-icon> {{ `mdi-file-${getFileExtention(file)}` }} </v-icon> -->
                <v-icon>mdi-file </v-icon>
              </div>
              <div class="d-flex justify-center align-center">
                {{ file.name }}
              </div>
            </v-col>
            <v-col class="d-flex justify-end icon-wrap">
              <v-icon @click="deleteFile(i)">
                mdi-delete
              </v-icon>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>

    <div class="dialog__buttons">
      <v-btn height="45px" width="130px" @click="prevStep">
        {{ buttonCancelName }}
      </v-btn>
      <v-btn
        height="45px"
        width="130px"
        color="primary"
        :disabled="isButtonSaveDisabled"
        @click="nextStep"
      >
        {{ buttonSaveName }}
      </v-btn>
    </div>
  </div>
</template>
<script>
import Attachments from "./components/Attachments/Attachments.vue";
import TenderRules from "./components/TenderRules/TenderRules.vue";
import TimePicker from "../../../../components/TimePicker/TimePicker.vue";
import DatePicker from "@/components/DatePicker/DatePicker.vue";
import FileUpload from "vue-upload-component";
import { uuid } from "vue-uuid";
import { getConsultantDisciplineById } from "@/utils/getValueFromJSONById/getValueFromJSONById";
/* eslint-disable no-param-reassign */

export default {
  name: "EtenderingCreateModal",
  components: { DatePicker, TimePicker, TenderRules, Attachments, FileUpload },
  props: ["currentEditTender"],
  data: function() {
    0;
    return {
      selectedAreas: [],
      selectedConsultant: null,
      step: 1,
      tenderOpeningDate: "",
      tenderOpeningTime: "",
      tenderQueryClosingDate: "",
      tenderQueryClosingTime: "",
      tenderClosingDate: "",
      tenderClosingTime: "",
      AwardingDate: "",
      contractPerson: "",
      recipients: [],
      files: [],
    };
  },
  computed: {
    contractStartDate() {
      return this.selectedConsultant.milestones[0].dateMet;
    },
    contractFinishDate() {
      return this.selectedConsultant.milestones[
        this.selectedConsultant.milestones.length - 1
      ].dueDate;
    },

    id() {
      return this.$route.params.id;
    },
    areas() {
      return this.$store.getters["projectsModule/getProjectById"](this.id)
        .areas;
    },
    contactPerson() {
      return this.$store.getters["projectsModule/getProjectById"](
        this.id
      ).parties.find((p) => {
        return p.roles[0] === "procuringEntity";
      });
    },
    users() {
      return this.$store.state.auth.users.filter((u) => {
        return u.role === "consultant";
      });
    },
    buttonCancelName() {
      return this.step === 1 ? "Cancel" : "Back";
    },
    buttonSaveName() {
      return this.step === 1 ? "Continue" : "Save";
    },
    attachments() {
      return [
        ...this.$store.getters["projectsModule/getProjectById"](this.id).files,
        ...this.selectedAreasBriefs,
      ];
    },
    selectedAreasBriefs() {
      let briefs = [];
      let areas = this.selectedAreas.map((a) =>
        a.briefs.map((b) => briefs.push(b.name))
      );
      return briefs;
    },

    isButtonSaveDisabled() {
      if (this.step === 1) {
        return this.selectedConsultant === null;
      } else {
        return this.checkIsSomeFieldsEmpty();
      }
    },
    tenderTitle() {
      let title = this.$store.getters["projectsModule/getProjectById"](this.id)
        .projectName;
      title = title.concat("; ");
      let consultantTitle = "";
      if (this.selectedConsultant) {
        consultantTitle = this.getConsultantDisciplineById(
          this.selectedConsultant.classification.id
        );
      } else {
        consultantTitle = "";
      }

      title = title.concat(consultantTitle);

      this.selectedAreas.forEach((sa) => {
        title = title.concat("; ");
        title = title.concat(sa.areaTitle);
      });
      return title;
    },

    identityConsultant() {
      if (this.selectedAreas.length > 1) {
        let allConsultants = [];
        this.selectedAreas.map((a) =>
          a.consultants.map((c) => allConsultants.push(c))
        );
        let uniqConsultans = [];

        allConsultants.forEach((c) => {
          if (this.checkIsConsultantPresentInAllSelectedAreas(c)) {
            if (
              !uniqConsultans.find(
                (uc) => uc.classification.id === c.classification.id
              )
            )
              uniqConsultans.push(c);
          }
        });
        let filteredConsultants = [];
        uniqConsultans.forEach((uc) => {
          uniqConsultans.forEach((anotherUc) => {
            if (uc.classification.id === anotherUc.classification.id) {
              if (this.compareConsultants(uc, anotherUc)) {
                if (
                  !filteredConsultants.find((c) => {
                    return c.id === uc.id;
                  })
                ) {
                  filteredConsultants.push(uc);
                }
              }
            }
          });
        });
        // allConsultants.forEach((ac) => {
        //   ////
        //   let res = allConsultants.filter((c) => {
        //     if (ac.id !== c.id) {
        //       if (this.compareConsultants(ac, c)) {
        //         return true;
        //       } else {
        //         return false;
        //       }
        //     }
        //   });
        //   if (res.length > 0) {
        //     let isAlreadyInArr = uniqConsultans.some((un) => {
        //       if (this.compareConsultants(un, ac)) {
        //         return true;
        //       } else {
        //         return false;
        //       }
        //     });
        //     if (!isAlreadyInArr) {
        //       uniqConsultans.push(ac);
        //     }
        //   }
        // });
        return filteredConsultants;
      } else {
        let allConsultants = [];
        this.selectedAreas.map((a) =>
          a.consultants.map((c) => allConsultants.push(c))
        );
        return allConsultants;
      }
    },
  },
  watch: {
    currentEditTender() {
      if (this.currentEditTender) {
        //         let index = str.indexOf(":")
        // str = (str.slice(index).slice(2))
        // str = str.split(",")
      }
    },
  },
  methods: {
    getConsultantDisciplineById,
    checkIsConsultantPresentInAllSelectedAreas(consultant) {
      let counter = 0;
      this.selectedAreas.forEach((sa) => {
        if (
          sa.consultants.find(
            (c) => c.classification.id === consultant.classification.id
          )
        ) {
          counter = counter + 1;
        }
      });
      return counter === this.selectedAreas.length;
    },
    nextStep() {
      if (this.step !== 2) {
        this.step = this.step + 1;
        return;
      } else {
        this.onSaveTender();
        Object.assign(this.$data, this.$options.data());
        this.$emit("close");
      }
    },
    onSelectAll(e) {
      if (e) {
        this.areas.forEach((a) => {
          if (!this.selectedAreas.find((sa) => sa.id === a.id)) {
            this.selectedAreas.push(a);
          }
        });
      } else {
        this.selectedAreas = [];
      }
    },
    checkIsSomeFieldsEmpty() {
      return (
        this.tenderOpeningDate === "" ||
        this.tenderOpeningTime === "" ||
        this.tenderQueryClosingDate === "" ||
        this.tenderQueryClosingTime === "" ||
        this.tenderClosingDate === "" ||
        this.tenderClosingTime === ""
      );
    },
    onSaveTender() {
      this.$store.commit("projectsModule/addTender", {
        projectId: this.id,
        tender: {
          awardPeriod: {
            endDate: this.AwardingDate,
          },
          contractPeriod: {
            startDate: this.contractStartDate,
            endDate: this.contractFinishDate,
          },
          tenderPeriod: {
            startDate: `${this.tenderOpeningDate} ${this.tenderOpeningTime}`,
            endDate: `${this.tenderClosingDate} ${this.tenderQueryClosingTime}`,
          },
          title: this.tenderTitle,
          hasEnquiries: false,
          id: uuid.v1(),
          parties: [],
          status: "draft",
          submissionMethod: "electronicSubmission",
          numberOfTenderers: 1,
          mainProcurementCategory: "services",
          eligibilityCriteria:
            "Emaar Properties pre-qualification requirements",
          tenders: null,
          items: this.getItemsForTender(),
          documents: [
            ...this.attachments.map((a) => {
              return {
                title: a,
              };
            }),
            ...this.files.map((f) => {
              return {
                title: f.name,
              };
            }),
          ],
        },
      });
    },
    getItemsForTender() {
      return this.selectedAreas.map((sa) => {
        return {
          additionalClassificarion: [],
          classification: {
            description: this.selectedConsultant.classification.id,
            id: this.selectedConsultant.classification.id,
          },
          description: `${this.selectedConsultant.classification.id} for ${sa.areaTitle} area`,
          disciplineId: this.getDisciplineIdFromArea(
            sa,
            this.selectedConsultant.classification.id
          ),
        };
      });
    },
    getDisciplineIdFromArea(currentArea, consultantName) {
      return currentArea.consultants.find(
        (c) => c.classification.id === consultantName
      ).id;
    },
    prevStep() {
      if (this.step !== 1) {
        this.step = this.step - 1;
        return;
      }
      if (this.step === 1) {
        Object.assign(this.$data, this.$options.data());
        this.$emit("close");
      }
    },
    deleteFile(id) {
      this.files = this.files.filter((e, i) => i !== id);
    },
    compareConsultants(a, b) {
      const { id: idA, area: aArea, ...otherA } = JSON.parse(JSON.stringify(a));
      const { id, area, ...otherB } = JSON.parse(JSON.stringify(b));
      delete otherA.classification;
      delete otherB.classification;

      otherA.milestones = otherA.milestones.map((m) => {
        delete m.stageId;
        delete m.classification;
        return m;
      });
      otherB.milestones = otherB.milestones.map((m) => {
        delete m.stageId;
        delete m.classification;
        return m;
      });

      otherA.stages = otherA.stages.map((s) => {
        s.deliverables = s.deliverables.map((d) => {
          delete d.id;
          delete d.classification;
          delete d.code;
          return d;
        });
        delete s.classification;
        delete s.id;
        return s;
      });

      otherB.stages = otherB.stages.map((s) => {
        s.deliverables = s.deliverables.map((d) => {
          delete d.classification;
          delete d.id;
          delete d.code;

          return d;
        });
        delete s.classification;
        delete s.id;
        return s;
      });
      otherA.scopes = otherA.scopes.map((s) => {
        delete s.classification;
        delete s.code;
        return s;
      });
      otherB.scopes = otherB.scopes.map((s) => {
        delete s.classification;
        delete s.code;

        return s;
      });

      // console.log(JSON.stringify(otherA) === JSON.stringify(otherB));

      return JSON.stringify(otherA) === JSON.stringify(otherB);
    },
  },
};
</script>
<style scoped lang="scss">
.autocomplete-wrapper {
  width: 100%;
  margin-top: 30px;
}
.dialog {
  background: white;
  height: 697px;
  flex-direction: column;
  overflow: auto;
  overflow-x: hidden;
  display: flex;
  &.second {
    height: 915px;
  }

  &__content {
    height: 509px;
    overflow: auto;

    overflow-x: hidden;
    &.second {
      height: 743px;
    }
  }
  &__title {
    text-transform: uppercase;
    min-height: 95px;
    width: 100%;
    background-color: var(--v-primary-base) !important;
    display: flex;
    color: white;
    padding-left: 50px;
    align-items: center;
  }
  &__buttons {
    display: flex;
    width: 55%;
    justify-content: center;
    align-items: flex-end;
    align-self: flex-end;
    margin-top: 20px;
    column-gap: 10px;
    position: absolute;
    bottom: 23px;
  }
}
.container {
  padding-left: 50px;
  padding-right: 50px;
}
.area-select-group {
  .item {
    display: flex;
    // column-gap: 300px;
    // justify-content: space-between;
    white-space: nowrap;
    align-items: center;
    &.main {
      font-weight: bold;
      border-bottom: 1px solid black;
    }
  }
}
.area-title-col {
  max-width: 62px;
}
::v-deep .v-list-item__content {
  text-align: left;
}
.input-row {
  display: flex;
  column-gap: 10px;
  .item {
    width: 50%;

  }
}

.text-disabled {
  color: grey;
}
::v-deep.file-uploads {
  width: 100% !important;
}
::v-deep file-upload {
  width: 100% !important;
}
.select-item {
  width: 18px;
}
.nowrap {
  white-space: nowrap;
}
.fixed-width {
  // overflow: hidden;
  // text-overflow: ellipsis;
  .text {
    // max-width: 134px;
    white-space: nowrap;
  }
}
    .right{
      position: relative;
      margin-left: 300px;
    }
</style>
