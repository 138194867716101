<template>
  <div>
    <v-row>
      <v-col sm="12">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th
                  v-for="(header, h) in headers"
                  :key="`table-headers- ${h}`"
                  class="text-left"
                >
                  {{ header }}
                </th>
                <th class="z-index-5" />
              </tr>
            </thead>

            <template v-if="items && items.length > 0">
              <template v-for="(item, i) in items">
                <ConsultationsSubTable
                  :key="`table-item-${i}`"
                  :item="item"
                  :max-stages="8"
                />
              </template>
            </template>
            <div v-else class="no-data-message">
              There is no data yet
            </div>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import ConsultationsSubTable from "./consultationsSubTable/ConsultationsSubTable.vue";

export default {
  name: "ProgramTab",
  components: {
    ConsultationsSubTable,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ["headers", "items"],
  data: function() {
    return {
      expanded: [],

      opened: false,
      singleExpand: false,
    };
  },
};
</script>
<style lang="scss" scoped>
.status-panel {
  width: 100px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.c-td {
  padding-left: 16px;
  padding-right: 16px;
}
::v-deep .v-data-table__wrapper {
  height: 375px;
  overflow-y: auto;
}
::v-deep table {
  table-layout: fixed;
  width: 300px;
}
::v-deep th {
  position: sticky;
  top: 0;
  background: whitesmoke;
  border: none;
}
.no-data-message {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 215px;
  color: grey;
}
.z-index-5 {
  z-index: 5;
}
</style>
