<template>
  <div class="my-framework">
    <v-row class="c-row">
      <v-col class="text-left c-table-head" sm="2">
        <div class="first-col">
          industry Type
        </div>
      </v-col>

      <v-col class="text-left c-table-head" sm="2">
        Development Type
      </v-col>
      <v-col class="text-left c-table-head" sm="2">
        Contract Type
      </v-col>

      <v-col class="text-center c-table-head" sm="3">
        Allocated
      </v-col>
      <v-col class="text-center c-table-head" sm="3">
        My Suite
      </v-col>
    </v-row>
    <v-row class="row-table">
      <v-col class="text-center d-flex" sm="2">
        <div class=" item-data">
          <div
            v-for="(dataItems, d) in items"
            :key="`myFrameworkTableItem DataItem - ${d}`"
            class="framewrok-item-main "
            :class="d === selectedItem.id && 'active'"
            @click="setSelectedItem(d, dataItems)"
          >
            <!-- <v-checkbox class="mt-0 c-checkbox-wrapper" /> -->
            <div>
              {{ getIndustryTypeById(dataItems.industryType) }}
            </div>
          </div>
        </div>
      </v-col>
      <v-col class="text-left " sm="2">
        <div class=" item-data">
          <div
            v-for="(dataItems, d) in items"
            :key="`myFrameworkTableItem Development - ${d}`"
            class="framewrok-item-main"
            :class="d === selectedItem.id && 'active'"
            @click="setSelectedItem(d, dataItems)"
          >
            <div>
              {{ dataItems.developmentType }}
            </div>
          </div>
        </div>
      </v-col>
      <v-col class="text-left " sm="2">
        <div class=" item-data">
          <div
            v-for="(dataItems, d) in items"
            :key="`myFrameworkTableItem Contract - ${d}`"
            class="framewrok-item-main contract-type"
            :class="d === selectedItem.id && 'active'"
            @click="setSelectedItem(d, dataItems)"
          >
            <div>
              {{ getContractTypeById(dataItems.contractType) }}
            </div>
          </div>
        </div>
      </v-col>

      <v-col sm="3" class="cell-border">
        <div
          v-for="(allocated, aid) in selectedItem.item.allocatedDisciplines"
          :key="`consultant-framework-page-${allocated}`"
          class="allocated-item"
        >
          <div class="d-flex align-center justify-space-between">
            <div class="d-flex allocated-text">
              <div>
                <!-- <v-checkbox /> -->
              </div>

              <div class="d-flex align-center">
                {{
                  getConsultantDisciplineById(
                    getAllocatedById(allocated).classification.id
                  )
                }}
              </div>
            </div>
            <div class="pointer" @click="onScopePreview(allocated)">
              <v-icon>
                mdi-eye
              </v-icon>
            </div>
            <div
              class="d-flex align-center flex-column justify-center c-icon-helper-text-wrap"
            >
              <div v-if="aid === 0" class="c-helper-text">
                Remove
              </div>

              <v-icon
                class="c-icon pointer"
                @click="
                  onAllocatedDisciplineRemove(selectedItem.item.id, allocated)
                "
              >
                mdi-play
              </v-icon>
            </div>
          </div>
        </div>
      </v-col>
      <v-col sm="3" class="cell-border  cell-border-right">
        <div
          v-for="(myScope, ms) in filtredScopes(myScopes)"
          :key="`consultant-framework-page-${ms}`"
          class="allocated-item"
        >
          <div class="d-flex align-center justify-space-between">
            <div class="d-flex">
              <div
                class="d-flex align-center flex-column justify-center c-icon-helper-text-wrap"
              >
                <div v-if="ms === 0" class="c-helper-text helper-text-2 ">
                  Add
                </div>

                <v-icon
                  class="rounded-icon c-icon pointer"
                  @click="onScopeAdd(selectedItem.item.id, myScope.id)"
                >
                  mdi-play
                </v-icon>
              </div>
              <div class="d-flex ml-3 align-center justify-center">
                <!-- <v-checkbox /> -->
              </div>

              <div class="d-flex align-center">
                {{ getConsultantDisciplineById(myScope.classification.id) }}
              </div>
            </div>
            <div class="pointer" @click="onScopePreview(myScope.id)">
              <v-icon>
                mdi-eye
              </v-icon>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import {
  getConsultantDisciplineById,
  getIndustryTypeById,
  getContractTypeById,
} from "@/utils/getValueFromJSONById/getValueFromJSONById";

export default {
  name: "MyFrameworkTable",
  props: ["headers", "items"],
  data: function() {
    return {
      selectedItem: {
        id: 0,
        item: this.items[0],
      },
    };
  },
  computed: {
    myScopes() {
      return this.$store.state.controlRoomModule.myScopes.filter((s) => {
        return true;
      });
    },
  },
  methods: {
    getConsultantDisciplineById,
    getIndustryTypeById,
    getContractTypeById,
    setSelectedItem(id, item) {
      this.selectedItem = {
        id: id,
        item: item,
      };
    },
    checkIsMySuiteInAllocated(sid) {
      this.items.forEach((i) => {
        return i?.allocatedDisciplines?.indexOf(sid) === -1 ? true : false;
      });
    },
    filtredScopes(myScopes) {
      return myScopes
        .filter((myScope) => {
          return this.items[
            this.selectedItem.id
          ]?.allocatedDisciplines?.indexOf(myScope.id) === -1
            ? true
            : false;
        })
        .filter((myScope) => {
          return (
            this.items[this.selectedItem.id].contractType ===
              myScope.contractType &&
            this.items[this.selectedItem.id].industryType ===
              myScope.industryType
          );
        });
    },
    onScopePreview(scopeId) {
      this.$emit("onPreviewButtonClick", this.getAllocatedById(scopeId));
    },
    onScopeAdd(frameworkId, scopeId) {
      console.log("scope ADD EVENT");
      this.$store.commit("myFrameworkModule/addFramewrok", {
        frameworkId,
        scopeId,
      });
    },
    onAllocatedDisciplineRemove(frameworkId, disciplineId) {
      return this.$store.commit("myFrameworkModule/removeAllocatedDiscipline", {
        frameworkId,
        disciplineId,
      });
    },
    getAllocatedById(id) {
      return this.$store.getters["controlRoomModule/getMyScopeById"](id);
    },
  },
};
</script>
<style scoped lang="scss">
.c-row {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}
.framewrok-item-main {
  display: flex;
  height: 50px;
}
.c-table-head {
  user-select: none;
  font-size: 0.75rem;
  height: 48px;
  color: rgba(0, 0, 0, 0.6);
  transition: height 0.2s cubic-bezier(0.4, 0, 0.6, 1);
  font-weight: 800;
  border-top: thin solid rgba(0, 0, 0, 0.12);
}
.cell-border {
  border-left: thin solid rgba(0, 0, 0, 0.12);
}
.cell-border-right {
  border-right: thin solid rgba(0, 0, 0, 0.12);
}
.rounded-icon {
  transform: rotate(180deg);
}
.c-icon {
  border: thin solid rgba(0, 0, 0, 0.12);
}
.c-helper-text {
  position: absolute;
  bottom: 25px;
  font-size: 0.75rem;
  &.helper-text-2 {
    bottom: 25px;
  }
}
.c-icon-helper-text-wrap {
  position: relative;
}
.c-checkbox-wrapper {
  position: relative;
  bottom: 4px;
}
.allocated-text {
  width: 282px;
}
.allocated-item {
  height: 50px;
}

.row-table {
  height: 600px;
}
.item-data {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  width: 100%;
}
.my-framework {
  // min-width: 1679px;
  margin-top: 15px;
}
.first-col {
  position: relative;
  // left: 33px;
}
.framewrok-item-main {
  width: 280px;
  display: flex;
  align-items: center;
  &.contract-type {
    width: 267px;
  }
  &.active {
    background: whitesmoke;
  }
  .v-input {
    position: relative;
    top: 7px;
  }
}
</style>
