<template>
  <div class="mt-5 py-4 d-flex align-center flex-column">
    <img class="mb-10" src="/img/icons/logo.svg">
    <h2 class="page-title mb-2">
      How we can help you?
    </h2>
    <v-text-field
      v-model.trim="searchQuery"
      label="Type keywords to find answers"
      prepend-inner-icon="mdi-magnify"
      class="search-input flex-grow-0 mb-5"
      solo
    />
    <div v-if="filteredItems.length" class="answers-container">
      <div v-for="(item, i) in filteredItems" :key="i" class="answers-container__item">
        <div class="px-8 py-4 d-flex justify-space-between align-center pointer" @click="showAnswer(i)">
          <p class="mb-0">
            {{ item.question }}
          </p>
          <v-icon>{{ i === openedAnswer ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
        </div>
        <div style="overflow: hidden">
          <transition name="fade" mode="out-in">
            <div v-if="i === openedAnswer" class="px-8 py-4 answers-container__answer">
              <p class="mb-0">
                {{ item.answer }}
              </p>
            </div>
          </transition>
        </div>
      </div>
    </div>
    <p v-else>
      Sorry, no results found matching your keywords
    </p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      openedAnswer: 0,
      searchQuery: '',
      items: [
        {
          question: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
          answer: 'Risus commodo viverra maecenas accumsan lacus vel facilisis volutpat est. A cras semper auctor neque vitae tempus quam pellentesque nec. Aliquet nec ullamcorper sit amet risus nullam eget. Mauris in aliquam sem fringilla ut morbi tincidunt. Diam phasellus vestibulum lorem sed risus. Erat imperdiet sed euismod nisi porta lorem.',
        },
        {
          question: 'Sed odio morbi quis commodo odio aenean sed adipiscing diam.',
          answer: 'Pretium lectus quam id leo in vitae turpis. Lacus vel facilisis volutpat est velit egestas dui. ',
        },
        {
          question: 'Quam adipiscing vitae proin sagittis nisl rhoncus?',
          answer: 'Semper auctor neque vitae tempus quam. Et sollicitudin ac orci phasellus. Id cursus metus aliquam eleifend mi in. Fermentum leo vel orci porta non.',
        },
        {
          question: 'Habitant morbi tristique senectus et netus et malesuada?',
          answer: 'Vulputate mi sit amet mauris commodo quis imperdiet massa tincidunt. Sed enim ut sem viverra aliquet eget.',
        },
        {
          question: 'Venenatis a condimentum vitae sapien?',
          answer: 'Tristique senectus et netus et malesuada fames. Risus feugiat in ante metus dictum at tempor commodo ullamcorper.',
        },
        {
          question: 'Convallis tellus id interdum velit laoreet id donec ultrices?',
          answer: 'Bibendum arcu vitae elementum curabitur vitae nunc sed velit. Sapien eget mi proin sed libero enim.',
        },
        {
          question: 'Nisl nunc mi ipsum faucibus vitae aliquet?',
          answer: 'Diam volutpat commodo sed egestas egestas fringilla phasellus faucibus.',
        },
        {
          question: 'Tristique magna sit amet purus gravida quis blandit turpis?',
          answer: 'Ut diam quam nulla porttitor massa id. Quis varius quam quisque id diam vel quam. Ipsum dolor sit amet consectetur adipiscing elit duis tristique. ',
        },
        {
          question: 'A erat nam at lectus urna duis convallis?',
          answer: 'Blandit aliquam etiam erat velit scelerisque in dictum non.',
        },
        {
          question: 'Massa tincidunt dui ut ornare lectus sit?',
          answer: 'Turpis egestas sed tempus urna et pharetra pharetra.',
        },
        {
          question: 'Mi proin sed libero enim sed faucibus turpis in eu?',
          answer: 'Imperdiet nulla malesuada pellentesque elit eget gravida cum sociis. Ac auctor augue mauris augue.',
        },
      ],
    };
  },
  computed: {
    filteredItems() {
      return this.items.filter((item) => {
        return item.question.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
          item.answer.toLowerCase().includes(this.searchQuery.toLowerCase());
      });
    },
  },
  methods: {
    showAnswer(i) {
      this.openedAnswer = this.openedAnswer === i ? null : i;
    }
  }
};
</script>

<style scoped lang="scss">
.page-title {
  color: #5085a4;
  font-size: 28px;
}

.search-input {
  width: 500px;
}

.answers-container {
  width: 1000px;
  border: 1px solid #e0e0e0;
  border-radius: 2px;
  text-align: left;
  margin-bottom: 50px;
  &__item:not(:last-of-type) {
    border-bottom: 1px solid #e0e0e0;
  }
  &__answer {
    border-top: 1px solid #e0e0e0;
    background-color: #cbe2f2;
  }
}

.fade-enter-active, .fade-leave-active {
  transition: all 0.35s;
}

.fade-enter, .fade-leave-to {
  margin-top: -100px;
}
</style>