<template>
  <Card style="height: 136px; min-width: 450px" class="mr-2 mt-2">
    <div v-if="item">
      <div class="c-info">
        <div class="item">
          <div class="key-wrapper">
            <div class="key">
              {{ item.roles[0] === "buyer" ? "Developer:" : "Manager" }}
            </div>
          </div>
          <div>
            <div class="value">
              {{ item.name }}
            </div>
          </div>
        </div>
        <div class="item">
          <div class="key-wrapper">
            <div class="key">
              Contact::
            </div>
          </div>
          <div>
            <div class="value">
              {{ item.contactPoint.name }}
            </div>
          </div>
        </div>
        <div class="item">
          <div class="key-wrapper">
            <div class="key">
              Email Address::
            </div>
          </div>
          <div>
            <div class="value">
              {{ item.contactPoint.email }}
            </div>
          </div>
        </div>
        <div class="item">
          <div class="key-wrapper">
            <div class="key">
              Phone Number:
            </div>
          </div>
          <div>
            <div class="value">
              {{ item.contactPoint.telephone }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </Card>
</template>
<script>
import Card from "@/components/Card/Card.vue";
export default {
  name: "DirectoryCard",
  components: {
    Card,
  },
  props: {
    item: Object,
  },
};
</script>
<style scoped lang="scss">
.c-title {
  font-size: 16px;
  display: flex;
}
.value {
  margin-left: 10px;
}
.key-wrapper {
  border-right: 1px solid grey;
}
.c-info {
  margin-top: 10px;
  .item {
    .key {
      display: flex;
      justify-content: flex-end;
      padding-right: 10px;
    }
    text-align: left;
    display: grid;
    grid-template-columns: 157px 270px;
  }
}
.doc-link {
  color: #5085a5;
  text-decoration: underline;
  cursor: pointer;
}
</style>
