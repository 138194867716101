<template>
  <div class="projects-page">
    <v-snackbar v-model="snackbar" :vertical="vertical" color="primary">
      {{ snackBarText }}

      <template>
        <div class="snackbar-close">
          <v-icon @click="closeSnackbar()">
            mdi-close
          </v-icon>
        </div>
      </template>
    </v-snackbar>

    <div class=" new-project-wrapper">
      <div class="project-card pointer" @click="toogleDialog()">
        New Project
      </div>
      <div class="project-card pointer mt-2" @click="toggleQuestionsDialog()">
        Pre-Qualification Questions
      </div>
    </div>
    <div class="all-projects-wrapper">
      <div class="d-flex all-projects-wrapper__title projects-toggle">
        <div
          v-for="(tab, i) in tabs"
          :key="`projects-tabs-${i}`"
          class="projects-toggle__item"
          :class="tab === activeTab && 'active'"
          @click="setCurrentTab(tab)"
        >
          {{ tab }}
        </div>
      </div>

      <div v-if="activeTab === 'Current Projects'" class="items">
        <div
          v-for="(project, id) in projects"
          :key="`projects-${id}`"
          class="item"
        >
          <div class="select-project-checkbox">
            <v-checkbox
              v-model="selected"
              :value="{ id: project.id, name: project.projectName }"
              dark
            />
          </div>
          <router-link :to="`${project.id}/dashboard/`">
            <div
              class="project-card"
              :class="
                selected.map((s) => s.id).indexOf(project.id) !== -1 &&
                  'selected'
              "
            >
              <div>
                {{ project.projectCode }}
              </div>
              <div>
                {{ project.projectName }}
              </div>
            </div>
          </router-link>
        </div>
      </div>

      <div v-else class="items">
        <div
          v-for="(project, id) in archivedProjects"
          :key="`projects-${id}`"
          class="item"
        >
          <div>
            <div class="project-card">
              <div>
                {{ project.projectCode }}
              </div>
              <div>
                {{ project.projectName }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-show="activeTab === 'Current Projects' && selected.length > 0"
      class="archive-project-actions"
    >
      <div class="pointer" @click="arhiveProjects">
        <div>
          <v-icon class="icon-style icon-size">
            mdi-archive
          </v-icon>
        </div>
        <div>
          Archive
        </div>
      </div>

      <div class="mt-3">
        <div>
          <v-icon class=" icon-size">
            mdi-share-variant
          </v-icon>
        </div>
        <div>
          Share
        </div>
      </div>
      <div class="mt-3 pointer" @click="onSendToWorkflowBtnClick()">
        <div>
          <img class="export-pdf-icon" src="/img/icons/send-to-workflow.svg">
        </div>
        <div>
          Send for workflow <br>
          approval
        </div>
      </div>
    </div>
    <v-dialog v-model="dialog" width="1200">
      <div class="dialog-wrapper">
        <div class="icon-close-wrapper pointer" @click="toogleDialog()">
          <v-icon>
            mdi-close
          </v-icon>
        </div>
        <CreateNewProject
          @toggleModal="toogleDialog()"
          @onProjectCreate="onSuccessCreateProject()"
        />
      </div>
    </v-dialog>

    <v-dialog v-model="questionsDialog" width="800">
      <div class="dialog-wrapper">
        <div
          class="icon-close-wrapper pointer"
          @click="toggleQuestionsDialog()"
        >
          <v-icon>
            mdi-close
          </v-icon>
        </div>
        <PreQualificationQuestions 
          @toggleDialog="toggleQuestionsDialog()"
        />
      </div>
    </v-dialog>
  </div>
</template>
<script>
import PreQualificationQuestions from "./components/PreQualificationQuestions/PreQualificationQuestions.vue";
import CreateNewProject from "./components/createNewProject/CreateNewProject.vue";
import { uuid } from "vue-uuid";

export default {
  name: "Projects",
  components: { CreateNewProject, PreQualificationQuestions },
  data: function() {
    return {
      dialog: false,
      questionsDialog: false,
      selected: [],
      tabs: ["Current Projects", "Archived Projects"],
      activeTab: "Current Projects",
      snackbar: false,
      snackBarText: "",
      vertical: true,
    };
  },
  computed: {
    projects: {
      get: function() {
        return this.$store.state.projectsModule.projects.filter(
          (p) => p.status === "active"
        );
      },
    },
    archivedProjects: {
      get: function() {
        return this.$store.state.projectsModule.projects.filter(
          (p) => p.status === "archived"
        );
      },
    },
    currentUser() {
      return this.$store.state.auth.currentUser;
    },
  },
  watch: {
    snackbar() {
      if (this.snackbar === false) {
        this.selected = [];
      }
    },
    dialog() {
      this.selected = [];
    },
    questionsDialog() {
      this.selected = [];
    },
  },
  methods: {
    onSuccessCreateProject() {
      this.snackBarText = "The project has been successfully created";
      this.snackbar = true;
    },
    onSuccessSendProjectToWorkflow() {
      this.snackBarText = "The project was successfully submitted";
      this.snackbar = true;
    },

    toogleDialog() {
      this.dialog = !this.dialog;
    },
    toggleQuestionsDialog() {
      this.questionsDialog = !this.questionsDialog;
    },
    setCurrentTab(tab) {
      this.activeTab = tab;
    },
    sendProjectToWorkflow(projectId) {
      this.$store.commit("projectsModule/addProccessInstance", {
        id: projectId.id,
        instance: {
          endDate: null,
          history: [],
          initiator: {
            name: `${this.currentUser.firstName} ${this.currentUser.lastName}`,
            id: this.currentUser.id,
          },
          processDefinitionId: "001",
          startDate: `${new Date().toISOString()}`,
          status: "active",
          token: {
            activityId: uuid.v1(),
            startDate: `${new Date().toISOString()}`,
          },
          variables: [
            {
              id: "projectId",
              value: projectId.id,
            },
          ],
        },
      });
    },
    onSendToWorkflowBtnClick() {
      this.selected.forEach((s) => {
        this.sendProjectToWorkflow(s);
      });
      this.selected = [];
      this.onSuccessSendProjectToWorkflow();
    },

    arhiveProjects() {
      this.$store.commit(
        "projectsModule/archiveProjects",
        this.selected.map((selected) => selected.id)
      );
      if (this.selected.length === 1) {
        this.snackBarText = `Project ${this.selected[0].name} has been successfully archived`;
      }
      if (this.selected.length > 1) {
        this.snackBarText = "Projects";
        this.selected.forEach((s, i) => {
          this.snackBarText = this.snackBarText.concat(
            " ",
            `${s.name} ${i === this.selected.length - 1 ? " " : ","} `
          );
        });
        this.snackBarText = this.snackBarText.concat(
          " ",
          "have been successfully archived"
        );
      }

      this.selected = [];
      this.snackbar = true;
    },
    closeSnackbar() {
      this.selected = [];
      this.snackbar = false;
    },
  },
};
</script>
<style scoped lang="scss">
.icon-style {
  color: black !important;
}
.icon-size {
  font-size: 50px !important;
}
.tabs-wrapper {
  display: flex;
  column-gap: 10px;
}
.new-project-wrapper {
  margin-top: 84px;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #d8d6d6;
  // justify-content: center;
  align-items: center;
  max-height: 602px;
}
::v-deep .sidebar-wrapper {
  display: none !important;
}
.projects-page {
  display: grid;
  grid-template-columns: 310px 1164px auto;
}
.project-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #333333;
  padding: 10px;
  color: white;
  height: 136px;
  width: 208px;
  &.selected {
    // background: #d8d6d6 !important;
  }
}
.items {
  column-gap: 10px;
  display: flex;
  padding-top: 20px;
  padding-left: 20px;
  border: 1px solid #d8d6d6 !important;
  min-height: 600px;
}
.all-projects-wrapper {
  margin-left: 50px;
  display: flex;
  flex-direction: column;
  &__title {
  }
}
::v-deep a {
  text-decoration: none !important;
}
.item {
  position: relative;
  .select-project-checkbox {
    top: -14px;
    left: 10px;
    position: absolute;
  }
}
.archive-project-actions {
  margin-top: 78px;
}
.projects-toggle {
  &__item {
    background: whitesmoke;
    padding: 20px;
    cursor: pointer;
    min-width: 200px;
    height: 64px;
    text-align: center;
    &:not(:last-of-type) {
      margin-right: 10px;
    }
    &.active {
      background: #5085a5;
      color: white;
    }
  }
}
::v-deep .mdi-checkbox-marked {
  &.v-icon {
    color: #5085a5 !important;
  }
}
.dialog-wrapper {
  position: relative;
}
::v-deep .v-snack__content {
  padding: 20px;
}
.snackbar-close {
  position: absolute;
  top: 0px !important;
  right: 0px !important;
}
</style>
