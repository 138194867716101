export default {
  namespaced: true,
  state: {
    scopes: [],
    myScopes: [],
  },
  mutations: {
    setScopes(state, scopes) {
      state.scopes = scopes;
    },
    setMyScopes(state, scopes) {
      state.myScopes = scopes;
    },
    editMyScope(state, scope) {
      if (state.myScopes.find((s) => s.id === scope.id)) {
        state.myScopes = state.myScopes.map((s) => {
          if (s.id === scope.id) {
            return scope;
          } else {
            return s;
          }
        });
      } else {
        state.myScopes = [...state.myScopes, scope];
      }
    },
  },
  getters: {
    // getProjectById: (state) => (id) => {
    //   return state.projects.find((project) => project.id === id);
    // },
    getMyScopeById: (state) => (id) => {
      let sorted = state.myScopes.find((myScope) => myScope.id === id);
      return sorted;
    },
  },
};
