<template>
  <div class="table-wrapper">
    <v-simple-table>
      <thead>
        <tr>
          <th
            v-for="(header, i) in headers"
            :key="`areaTableHeader - ${i}`"
            class="text-left white--text primary-background area-table-head"
          >
            <div :class="i === 0 && 'first-col'" class="tr-head">
              {{ header.title }}
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items" :key="index">
          <td
            v-for="(itemTd, indexTd) in Object.keys(item)"
            :key="indexTd"
            class="text-left"
          >
            {{ item[itemTd] }}
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>
<script>
export default {
  name: "PaymentsTable",
  props: {
    headers: Array,
    items: Array,
  },
  methods: {},
};
</script>
<style scoped lang="scss">
::v-deep td {
  padding: 10px;
}

.table-header {
  font-size: 14px;
  min-height: 38px;
}
.table-item-wrapper {
  display: flex;
  min-height: 44px;
  justify-content: center;
  align-items: center;
}
::v-deep table {
  table-layout: fixed !important;
}
.tr-head {
  height: 64px;
  display: flex;
  align-items: center;
}
</style>
