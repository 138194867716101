<template>
  <div>
    <v-simple-table>
      <thead>
        <tr>
          <th
            v-for="(header, i) in headers"
            :key="`areaTableHeader - ${i}`"
            class="text-left white--text primary-background area-table-head "
          >
            <div :class="i === 0 && 'first-col'">
              {{ header }}
            </div>
          </th>
        </tr>
      </thead>
      <template v-if="items && items.length !== 0">
        <AreaTableItem
          v-for="(item, i) in items"
          :key="`area table item - ${i}`"
          :item="item"
          @onDelete="onDelete"
        />
      </template>
    </v-simple-table>
  </div>
</template>
<script>
import AreaTableItem from "./components/AreaTableItem.vue";
export default {
  name: "AreaTable",
  components: { AreaTableItem },
  props: {
    headers: Array,
    items: Array,
  },
  data: function() {
    return {
      offset: true,
      isOpened: false,
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
  },
  methods: {
    onDelete(area) {
      this.$store.commit("projectsModule/removeArea", {
        projectId: this.id,
        areaTitleToRemove: area.areaTitle,
      });
    },
    toggleOpen() {},
  },
};
</script>
<style lang="scss" scoped>
.delete-btn {
  padding: 10px;
  cursor: pointer;
}
::v-deep thead tr {
  height: 64px;
}
.tr-head {
  height: 64px;
}
::v-deep table {
  table-layout: fixed !important;
}
.first-col {
  position: relative;
  left: 38px;
}

.area-table-head {
  font-size: 14px !important;
  font-weight: normal !important;
}
</style>
