<template>
  <div class="nav-wrapper" :class="presintationPages && 'presintation'">
    <LogoSectionFull v-if="presintationPages" />
    <LogoSection v-else />
    <div class="navbar">
      <div class="navbar__items">
        <router-link to="/projects" class="item">
          Projects
        </router-link>
        <router-link
          :to="`/${id}/dashboard`"
          class="item"
          :class="$route.name === 'dashboard' && 'router-link-active'"
        >
          Dashboard
        </router-link>
        <router-link to="/control-room" class="item">
          Control Room
        </router-link>

        <router-link
          :to="`/${id}/areas`"
          exact
          class="item"
          :class="$route.name === 'areas' && 'router-link-active'"
        >
          Areas
        </router-link>
        <router-link
          :to="`/${id}/scope-of-works`"
          class="item"
          :class="$route.name === 'scope-of-works' && 'router-link-active'"
        >
          Scope of Works
        </router-link>

        <router-link
          :to="`/${id}/workflows`"
          class="item"
          :class="$route.name === 'workflows' && 'router-link-active'"
        >
          Workflows
        </router-link>
        <router-link
          :to="`/${id}/etendering`"
          class="item"
          :class="$route.name === 'etendering' && 'router-link-active'"
        >
          Etendering
        </router-link>
        <router-link
          :to="`/${id}/payments`"
          class="item"
          :class="$route.name === 'payments' && 'router-link-active'"
        >
          Payments
        </router-link>
        <router-link
          :to="`/${id}/directory`"
          class="item"
          :class="$route.name === 'directory' && 'router-link-active'"
        >
          Directory
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import LogoSection from "@/components/LogoSection/LogoSection.vue";
import LogoSectionFull from "@/components/LogoSectionFull/LogoSectionFull.vue";

export default {
  name: "Navbar",
  components: {
    LogoSection,
    LogoSectionFull,
  },
  props: {
    presintationPages: Boolean,
  },
  computed: {
    id() {
      return this.$route.params.id ? this.$route.params.id : 1;
    },
  },
};
</script>
<style scoped lang="scss">
.navbar {
  width: 100%;
  background: black;
  display: flex;
  justify-content: center;
  &__items {
    display: flex;
    width: 70%;
    justify-content: space-between;
    .item {
      padding: 10px 36px;

      color: white;
      text-decoration: none;
    }
    .router-link-active {
      background: #5085a5;
    }
  }
}
.nav-wrapper {
  width: 100%;
  &.presintation {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
</style>
