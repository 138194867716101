<template>
  <v-simple-table class="theme-white theme--light">
    <div v-if="currentView === 'master-suite'" class="blured-block-text">
      Buy to see the other {{ items.length - 3 }}

      {{ tableType === "Scope" ? "scopes" : "deliverables" }}
    </div>

    <thead>
      <th class="code-header" />
      <th class="text-left pa-2">
        <div class="d-flex justify-space-between c-col">
          <div class="d-flex align-center">
            <div>
              {{ tableType }}
            </div>
            <div
              v-if="
                isEditMode &&
                  currentView !== 'master-suite' &&
                  canShowEditActions
              "
              class="ml-2"
            >
              <v-btn
                rounded
                height="26px"
                color="primary"
                @click="onAddClickHadnle"
              >
                Add+
              </v-btn>
            </div>
          </div>
          <div
            v-if="currentView !== 'master-suite' && canShowEditActions"
            class="d-flex"
          >
            <div class="sub-th">
              <div v-if="isEditMode" class="title">
                Remove
              </div>
            </div>
            <div
              v-if="
                tableType === 'Deliverables' &&
                  currentRoute === 'scope-of-works'
              "
              class="d-flex"
            >
              <div class="sub-th">
                <div class="title">
                  Completed
                </div>
              </div>
              <div class="sub-th">
                <div class="title">
                  Transmittal
                </div>
              </div>
            </div>
          </div>
        </div>
      </th>
    </thead>
    <tbody>
      <tr
        v-for="(item, i) in items"
        :key="`scope-dialog-table-row-${i}`"
        :class="currentView === 'master-suite' && i > 2 && 'blured-block'"
      >
        <td
          v-if="tableType === 'Scope'"
          class="text-left td-code"
          :class="checkIsScopeToDelete(item) && 'scope-to-delete'"
        >
          {{ item.code }}
        </td>
        <td
          v-if="tableType === 'Deliverables'"
          class="text-left td-code"
          :class="checkIsScopeToDelete(item) && 'scope-to-delete'"
        >
          {{ item.code }}
        </td>
        <td class="text-left">
          <div class="d-flex justify-space-between c-col">
            <div
              class="d-flex align-center title-row"
              :class="checkIsScopeToDelete(item) && 'scope-to-delete'"
            >
              <div>
                {{ item.title }}
              </div>
            </div>
            <div class="d-flex">
              <!-- <div class="sub-th d-flex justify-center checkbox-wrapper">
                <v-checkbox />
              </div> -->
              <div class=" sub-th ml-1 d-flex justify-center checkbox-wrapper">
                <div v-if="isEditMode && canShowEditActions">
                  <v-checkbox
                    :value="checkIsScopeToDelete(item)"
                    :disabled="currentView === 'master-suite' && i > 2"
                    @change="
                      (e) => {
                        setScopeToDelete(e, item);
                      }
                    "
                  />
                </div>
              </div>

              <div
                v-if="
                  tableType === 'Deliverables' &&
                    currentRoute === 'scope-of-works' &&
                    canShowEditActions
                "
                class=" sub-th ml-1 d-flex justify-center checkbox-wrapper"
              >
                <div>
                  <v-checkbox
                    :value="item.isComplete"
                    @change="(e) => onDelivarableComplete(e, item)"
                  />
                </div>
              </div>
              <div
                v-if="
                  tableType === 'Deliverables' &&
                    currentRoute === 'scope-of-works'
                "
                class=" sub-th ml-1 d-flex justify-center checkbox-wrapper"
              >
                <div
                  v-if="canShowEditActions && !editContract"
                  class="d-flex justify-center pointer"
                >
                  <v-icon
                    v-if="item.transmittal === ''"
                    @click="onTransimalAdd(item)"
                  >
                    mdi-plus
                  </v-icon>
                  <div
                    v-else
                    class="d-flex justify-center"
                    @click="onTransimalEdit(item)"
                  >
                    <v-icon>
                      mdi-open-in-new
                    </v-icon>
                  </div>
                </div>
                <div
                  v-if="canShowEditActions && editContract"
                  class="d-flex justify-center pointer"
                >
                  <v-icon
                    v-if="checkIsTransmittalEmpty(item)"
                    @click="onTransimalAdd(item)"
                  >
                    mdi-plus
                  </v-icon>
                  <div
                    v-else
                    class="d-flex justify-center"
                    @click="onTransimalEdit(item)"
                  >
                    <v-icon>
                      mdi-open-in-new
                    </v-icon>
                  </div>
                </div>
              </div>
              <!-- <div
                v-if="tableType === 'Deliverables'"
                class=" sub-th ml-1 d-flex align-center justify-center"
              >
                <v-btn rounded height="26px" color="primary">
                  Add+
                </v-btn>
              </div> -->
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </v-simple-table>
</template>
<script>
export default {
  name: "ScopeDialogTable",
  props: {
    tableType: String,
    scopesToDelete: {
      type: Array,
      default: () => [],
    },
    canShowEditActions: {
      type: Boolean,
      default: true,
    },
    currentView: {
      type: String,
      default: "my-scope",
    },
    items: {
      type: Array,
      default: () => [],
    },
    isEditMode: {
      type: Boolean,
      default: false,
    },
    currentRoute: {
      type: String,
      default: "",
    },
    editContract: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onAddClickHadnle() {
      this.$emit("onScopeOrDelivarableAdd", this.tableType);
    },
    onDelivarableComplete(e, itemToComplete) {
      // eslint-disable-next-line no-param-reassign
      itemToComplete.isComplete = e === null ? false : true;
      this.$emit("onCompleteDelivarable", itemToComplete);
    },
    setScopeToDelete(val, item) {
      if (val) {
        this.$emit("setScopesToDelete", [...this.scopesToDelete, item]);
      } else {
        this.$emit(
          "setScopesToDelete",
          this.scopesToDelete.filter((s) => s.code !== item.code)
        );
      }
    },
    onTransimalAdd(delivarable) {
      this.$emit("onScopeOrDelivarableAdd", "Transmital", delivarable);
    },
    onTransimalEdit(delivarable) {
      this.$emit("onScopeOrDelivarableAdd", "Transmital", delivarable);
    },
    checkIsScopeToDelete(scope) {
      return (
        this.scopesToDelete.filter((s) => s.code === scope.code).length !== 0
      );
    },
    checkIsTransmittalEmpty(delivarable) {
      // this.value =
      //   this.$store.state.scopeOfWorksModule.pageData.documents.find(
      //     (d) => d.relatedItem === delivarable.id
      //   ).url === "";
      let val = this.$store.state.scopeOfWorksModule.pageData.documents.find(
        (d) => d.relatedItem === delivarable.id
      );
      if (val && val.url !== "") {
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.td-code {
  width: 30px;
  font-size: 14px;
}
::v-deep td {
  border-bottom: none !important;
  border-left: thin solid rgba(0, 0, 0, 0.12);
  height: 25px !important;
}
::v-deep th {
  text-transform: uppercase;
  border-left: thin solid rgba(0, 0, 0, 0.12);
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
  border-top: thin solid rgba(0, 0, 0, 0.12);
}
.sub-th {
  text-transform: capitalize !important;
  width: 78px;
  position: relative;
  .title {
    font-weight: normal !important;
    font-size: 10px !important;
    white-space: nowrap;
    left: 6px;
  }
}
.c-col {
  height: auto !important;
}
.checkbox-wrapper {
  width: 78px;
  height: 66px;
  position: relative;
  bottom: 0px;
}
::v-deep .v-data-table__wrapper {
  position: relative;
}
.title-row {
  white-space: normal;
}
.blured-block-text {
  position: absolute;
  top: 285px;
  color: var(--v-primary-base) !important;
  z-index: 3;

  left: 174px;
}
.code-header {
  width: 84px;
  height: 50px;
}
.scope-to-delete {
  // background: grey;
  color: grey;
}
::v-deep a {
  text-decoration: none;
}
</style>
