<template>
  <v-menu
    ref="menu"
    v-model="menu2"
    :close-on-content-click="false"
    :nudge-right="40"
    :return-value.sync="innerValue"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="innerValue"
        :label="label"
        readonly
        v-bind="attrs"
        outlined
        append-icon="mdi-clock-outline"
        clearable
        v-on="on"
      />
    </template>
    <v-time-picker
      v-if="menu2"
      v-model="innerValue"
      full-width
      @click:minute="$refs.menu.save(innerValue)"
    />
  </v-menu>
</template>
<script>
export default {
  name: "TimePicker",
  props: {
    label: String,
    value: String,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    date: "",
    menu: false,
    modal: false,
    menu2: false,
  }),
  computed: {
    innerValue: {
      get: function() {
        return this.value;
      },
      set: function(newValue) {
        this.$emit("update:value", newValue);
      },
    },
  },
};
</script>
<style scoped lang="scss">
::v-deep .v-input__slot {
  height: 43px !important;
  min-height: unset !important;
}
::v-deep .v-label {
  top: 13px !important;
}
::v-deep .v-input__append-inner {
  margin-top: 9px !important;
}
</style>
