<template>
  <div class="table-wrapper">
    <v-simple-table>
      <thead>
        <td
          v-for="(header, i) in appointedScopeHeaders
            ? appointedScopeHeaders
            : headers"
          :key="`scope-table-header-${i}`"
          :class="header.background && 'primary-background white--text'"
          class="table-header"
        >
          <div class="table-item-wrapper">
            <div v-html="header.title" />
            <div v-if="header.tooltip" class="ml-1">
              <v-tooltip top color="black">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on">
                    mdi-help-circle-outline
                  </v-icon>
                </template>
                <span v-html="header.tooltip" />
              </v-tooltip>
            </div>
          </div>
        </td>
      </thead>
      <template v-for="(item, i) in items">
        <AppointedScopeTableItem
          v-if="appointedScopeHeaders"
          :key="`table-scope-item-${i}`"
          :item="item"
          @setCurrentScopeItemInDialog="setCurrentScopeItemInDialog"
          @openScopeDialog="openScopeDialog"
        />

        <ScopeTableItem
          v-else
          :key="`table-scope-item-${i}`"
          :current-tab="currentTab"
          :item="item"
          @setCurrentScopeItemInDialog="setCurrentScopeItemInDialog"
          @openScopeDialog="openScopeDialog"
        />
      </template>
    </v-simple-table>
  </div>
</template>
<script>
import VariationTableItem from "./components/VariationTableItem.vue";
import AppointedScopeTableItem from "./components/AppointedScopeTableItem.vue";
import ScopeTableItem from "./components/ScopeTableItem.vue";
export default {
  name: "ScopeTable",
  components: {
    ScopeTableItem,
    AppointedScopeTableItem,
    ScopeTableItem,
    AppointedScopeTableItem,
  },
  props: {
    headers: Array,
    currentTab: String,
    items: Array,
    appointedScopeHeaders: Array,
  },
  methods: {
    openScopeDialog(val) {
      this.$emit("openScopeDialog", val);
    },
    setCurrentScopeItemInDialog(val) {
      this.$emit("setCurrentScopeItemInDialog", val);
    },
  },
};
</script>
<style scoped lang="scss">
::v-deep td {
  padding: 10px;
}

.table-header {
  font-size: 14px;
  min-height: 38px;
}
.table-item-wrapper {
  display: flex;
  min-height: 44px;
  justify-content: center;
  align-items: center;
}
::v-deep table {
  table-layout: fixed !important;
}
</style>
