<template>
  <div class="text-left" :class="centered && 'centered-select'">
    <v-menu
      :close-on-content-click="false"
      min-width="100px"
      bottom
      :offset-y="offset"
    >
      <template v-slot:activator="{ on: menu, attrs }">
        <template>
          <div class="select-small centered" />
          <v-select
            v-bind="attrs"
            outlined
            :background-color="disabled ? '#464646' : 'primary'"
            class="min-height-select"
            :placeholder="getItemValue(actionValue)"
            dark
            :disabled="disabled"
            v-on="{ ...menu }"
          />
        </template>
      </template>
      <v-card class="content-wrapper">
        <div
          v-for="(item, i) in actions"
          :key="`Action key -${i}`"
          class="item"
        >
          <div class="item__label">
            {{ getItemValue(item, true) }}
          </div>
          <div class="item__checkbox">
            <v-checkbox
              :input-value="actionValues[i]"
              @change="(e) => onCheckboxChange(e, i)"
            />
          </div>
        </div>

        <div>
          <v-btn rounded color="primary" @click="onSave">
            Save
          </v-btn>
        </div>
      </v-card>
    </v-menu>
  </div>
</template>
<script>
export default {
  name: "CSelect",
  props: {
    centered: Boolean,
    actions: {
      type: Array,
      default: () => ["Send to workflow", "Export to PDF"],
    },
    defaultValue: {
      type: String,
      default: "Action",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    itemId: {
      type: Number,
      default: 0,
    },
    actionValues: {
      type: Array,
      default: () => [false, false],
    },
  },
  data: function() {
    return {
      offset: true,
      selectedValues: this.actionValues,
    };
  },
  computed: {
    actionValue: {
      get() {
        let labelIndex = this.actionValues.indexOf(true);
        if (labelIndex !== -1) {
          return this.actions[labelIndex];
        } else {
          return this.defaultValue ? this.defaultValue : "Action";
        }
      },
    },
  },
  methods: {
    onSave() {
      this.$emit("onSave", this.itemId, this.selectedValues);
    },
    onCheckboxChange(val, id) {
      this.selectedValues[id] = val;
    },
    getItemValue(item, showValue) {
      if (typeof item === "string") {
        return item;
      } else {
        return showValue ? item.value : item.label;
      }
    },
  },
};
</script>
<style scoped lang="scss">
::v-deep .v-list-item,
.v-sheet.v-list {
  display: none !important;
}
::v-deep .v-select {
  width: 161px;
}
.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  &__checkbox {
    display: flex;
    width: 50px;
    justify-content: flex-end;
  }
  &__label {
    text-align: left;
    width: 135px;
  }
}
.content-wrapper {
  padding: 5px;
}
::v-deep .theme--dark.v-label {
  color: white !important;
}
.centered-select {
  position: relative;
  bottom: 5px;
}
::v-deep input::placeholder,
.v-label {
  color: white !important;
  opacity: 1 !important;
}
// ::v-deep .v-input--is-disabled {
//   background-color: red !important;
// }
</style>
