<template>
  <div class="add-disciplines">
    <div class="disciplines-list">
      <template v-if="customScopes.length > 0 && canShowCustomScopes">
        <div class="item bold">
          <div class="d-flex justify-cneter align-center">
            <div>
              <v-checkbox @change="(e) => onSelectAllDisciplines(e, true)" />
            </div>
            <div>
              Custom Scopes
            </div>
          </div>
          <div class="d-flex justify-cneter align-center">
            Cost
          </div>
        </div>
        <div
          v-for="(item, i) in customScopes"
          :key="`area add disc2iplines-${i}`"
        >
          <div v-if="checkIsScopeSelected(item)" class="item">
            <div class="d-flex justify-cneter align-center">
              <div>
                <v-checkbox v-model="selected" :value="item.id" />
              </div>
              <div>
                {{ getConsultantDisciplineById(item.classification.id) }}
              </div>
            </div>
            <div class="d-flex justify-cneter align-center">
              <div>
                Available
              </div>
            </div>
          </div>
        </div>
      </template>

      <div class="item bold mt-4">
        <div class="d-flex justify-cneter align-center">
          <div>
            <v-checkbox @change="(e) => onSelectAllDisciplines(e)" />
          </div>
          <div>
            Discipline
          </div>
        </div>
        <div class="d-flex justify-cneter align-center">
          Cost
        </div>
      </div>

      <div
        v-for="(item, i) in getScopesBySelectedDevelopmentType(allScopes)"
        :key="`area add disciplidddddnes-${i}`"
      >
        <div
          v-if="checkIsScopeSelected(item)"
          class="item"
          :class="isScopeMy(item) ? '' : 'disabled'"
        >
          <div class="d-flex justify-cneter align-center">
            <div>
              <v-checkbox
                v-model="selected"
                :disabled="!isScopeMy(item)"
                :value="item.id"
              />
            </div>
            <div>
              {{ getConsultantDisciplineById(item.classification.id) }}
            </div>
          </div>
          <div class="d-flex justify-cneter align-center">
            <div v-if="isScopeMy(item)">
              Available
            </div>
            <div v-else>
              ${{ item.value.amount }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getConsultantDisciplineById } from "@/utils/getValueFromJSONById/getValueFromJSONById";

export default {
  name: "AreaAddDisciplines",
  props: {
    currentDevelopmemntType: {
      type: String,
      default: "",
    },
    items: {
      type: Array,
      default: () => [],
    },
    itemsToAdd: {
      type: Array,
      default: () => [],
    },
  },
  data: function() {
    return {
      selected: [],
    };
  },
  computed: {
    allScopes() {
      return this.$store.state.controlRoomModule.scopes;
    },
    myScopes() {
      return this.$store.state.controlRoomModule.myScopes.filter((ms) => {
        if (this.items.indexOf(ms.id === -1)) {
          return true;
        } else {
          return false;
        }
      });
    },
    canShowCustomScopes() {
      let result = this.customScopes.filter((c) => {
        if (!this.itemsToAdd.includes(c.id) && !this.items.includes(c.id)) {
          return true;
        } else {
          return false;
        }
      });
      console.log(result);
      return result.length > 0;
    },
    customScopes() {
      return this.$store.state.controlRoomModule.myScopes.filter((ms) => {
        return ms.isCustom;
      });
    },
  },

  methods: {
    getConsultantDisciplineById,

    getScopesBySelectedDevelopmentType(scopes) {
      return scopes;
    },
    onSelectAllDisciplines(e, customScopes) {
      if (e === true) {
        this.myScopes.forEach((ms) => {
          if (customScopes ? ms.isCustom : !ms.isCustom) {
            if (
              !this.itemsToAdd.includes(ms.id) &&
              !this.items.includes(ms.id)
            ) {
              this.selected = [
                ...this.selected,
                customScopes ? ms.id : `master-${ms.id}`,
              ];
            }
          }
        });
      } else {
        if (customScopes) {
          this.selected = this.selected.filter((s) => s.includes("master"));
        } else {
          this.selected = this.selected.filter((s) => !s.includes("master"));
        }
      }
    },
    isScopeMy(scope) {
      let result = this.myScopes.filter((ms) => {
        if (ms.classification.id === scope.classification.id && !ms.isCustom) {
          return true;
        } else {
          return false;
        }
      });

      if (result.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    checkIsScopeSelected(item) {
      if (item.isCustom) {
        if (this.items.indexOf(item.id) !== -1) {
          return false;
        } else {
          return !!this.itemsToAdd.indexOf(item.id);
        }
      } else {
        if (!this.isScopeMy(item)) {
          return true;
        } else {
          if (this.items.indexOf(item.id.slice(7, item.id.length)) === -1) {
            if (
              this.itemsToAdd.indexOf(item.id.slice(7, item.id.length)) === -1
            ) {
              return true;
            }
          } else {
            return false;
          }
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.add-disciplines {
  min-height: 733px;
}
.disciplines-list {
  padding-left: 50px;
  padding-right: 50px;
  min-height: 680px;
  max-height: 721px;
  overflow: auto;
  .title {
    display: flex;
    font-size: 13px !important;
  }
  .item {
    padding: 10px 0px;
    border-bottom: thin solid rgba(0, 0, 0, 0.12);
    &.bold {
      font-weight: bold;
    }
    display: flex;
    justify-content: space-between;
    &.disabled {
      color: grey;
    }
  }
}
</style>
