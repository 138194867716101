<template>
  <div class="fields">
    <div class="select-row">
      <div>
        <v-autocomplete
          v-model="areaType"
          :value="areaType"
          outlined
          label="Area Type"
          clearable
          :items="json.areaType"
          @change="onFieldChange('areaType', $event)"
        />
      </div>
      <div>
        <v-text-field
          :value="areaTitle"
          outlined
          label="Area Title"
          @input="onFieldChange('areaTitle', $event)"
        />
      </div>
    </div>
    <div class="select-row">
      <div>
        <v-text-field
          :value="locationTag"
          outlined
          label="Location Tag"
          @input="onFieldChange('locationTag', $event)"
        />
      </div>
      <div>
        <v-text-field
          type="number"
          :value="allocateSqmBudget"
          :prefix="'$'"
          outlined
          label="Allocate sqm Budget"
          @input="onFieldChange('allocateSqmBudget', $event)"
        />
      </div>
    </div>
    <div class="select-row">
      <div>
        <v-text-field
          outlined
          label="Area"
          type="number"
          :suffix="'sqm'"
          :value="area"
          @input="onFieldChange('area', $event)"
        />
      </div>
    </div>
    <div>
      <div class="d-flex">
        Documents
        <v-tooltip top color="black">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on">
              mdi-help-circle-outline
            </v-icon>
          </template>
          <span v-html="'Area Brief can be uploaded here'" />
        </v-tooltip>
      </div>
      <div class="d-flex justify-center c-wrapper mt-2">
        <file-upload
          ref="upload"
          v-model="innerFiles"
          class="btn btn-primary"
          post-action="/upload/post"
          extensions="gif,jpg,jpeg,png,webp"
          :multiple="true"
          :size="1024 * 1024 * 10"
          @input="onFileUpload"
        >
          <div class="c-file-upload">
            <v-icon>
              mdi-cloud-upload
            </v-icon>
            <div class="c-file-upload__label">
              Chose files to upload
            </div>
            <div class="c-file-upload__subLabel">
              Or drag and drop them here
            </div>
          </div>
        </file-upload>
      </div>
      <div class="files-wrapper">
        <v-row v-for="(file, i) in files" :key="`file - ${i}`">
          <v-col class="d-flex">
            <div class="icon-wrap">
              <!-- <v-icon> {{ `mdi-file-${getFileExtention(file)}` }} </v-icon> -->
              <v-icon>mdi-file </v-icon>
            </div>
            <div class="d-flex justify-center align-center">
              {{ file.name }}
            </div>
          </v-col>
          <v-col class="d-flex justify-end icon-wrap">
            <v-icon @click="deleteFile(i)">
              mdi-delete
            </v-icon>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>
<script>
import FileUpload from "vue-upload-component";
import json from "@/createNewProjectData.json";

export default {
  name: "AreaFirstStep",
  components: {
    FileUpload,
  },

  props: [
    "areaType",
    "areaTitle",
    "locationTag",
    "allocateSqmBudget",
    "area",
    "files",
  ],
  data: function() {
    return {
      json: json,
      innerFiles: [],
    };
  },
  created() {
    this.innerFiles = this.files;
  },
  methods: {
    deleteFile(id) {
      this.innerFiles = this.innerFiles.filter((e, i) => i !== id);
      this.$emit("setFiles", this.innerFiles);
    },
    formatMoney(val) {
      return val;
    },
    onFieldChange(field, value) {
      console.log(value);
      this.$emit("onFieldChange", field, value);
    },
    onFileUpload() {
      this.$emit("setFiles", this.innerFiles);
    },
  },
};
</script>
<style scoped lang="scss">
.fields {
  margin-top: 34px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
::v-deep.file-uploads {
  width: 613px !important;
}
::v-deep file-upload {
  width: 613px !important;
}
.c-wrapper {
  width: 100%;
}
.files-wrapper {
  height: 148px;
  margin-top: 30px;
  overflow-y: auto;
  overflow-x: hidden;
}
::v-deep .v-select,
.v-input {
  width: 300px;
}
.select-row {
  display: flex;
  column-gap: 10px;
  width: 613px;
}
.files-wrapper {
  height: 148px;
  margin-top: 30px;
  overflow-y: auto;
  overflow-x: hidden;
}
::v-deep .v-list-item__title {
  text-align: left !important;
}
</style>
