import Vue from "vue";
import Vuex from "vuex";
import scopeOfWorksModule from "./module-scope-of-works/index";
import projectsModule from "./module-projects/index";
import controlRoomModule from "./module-control-room/index";
import myFrameworkModule from "./module-my-framework/index";
import workflowsModule from "./module-workflows/index";
import auth from "./module-auth/index";
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    scopeOfWorksModule,
    projectsModule,
    controlRoomModule,
    myFrameworkModule,
    workflowsModule,
    auth,
  },
});
