/* eslint-disable indent */
export const getUniqueConsultants = (projectAreas) => {
  console.log(projectAreas);
  let headers = new Set();
  headers.add("Area");
  projectAreas.forEach((pa) => {
    pa.consultants.forEach((c) => {
      console.log(c);

      headers.add(c.classification.id);
      c.additionalClassification.forEach((ac) => {
        headers.add(ac.id);
      });
    });
  });

  return Array.from(headers);
  // let headers = new Set();

  // projectAreas.forEach((projectArea) => {
  //   projectArea.disciplinesAssigned.forEach((discipline) => {
  //     headers.add(getConsultantAbbreviation(discipline));
  //   });
  // });
  // return Array.from(headers);
};
export const getConsultantAbbreviation = (consultant) => {
  switch (consultant) {
    case "BIM Management Consultant":
      return "BIM";
      break;
    case "ELV Consultant":
      return "ELV";
      break;
    case "Engineer":
      return "Eng";
      break;
    case "FF&E & OS&E Procurement Services":
      return "FFEOSE";
      break;
    case "IT & Cybersecurity Consultant":
      return "ITCC";
      break;
    case "IT Active Components Consultant":
      return "ITACC";
      break;
    case "IT/AV Consultant":
      return "ITAVC";
      break;
    case "Pools and Water Feature Consultant":
      return "PWFC";
      break;
    case "Architect of Record":
      return "AoR";
      break;
    case "Landscape Consultant":
      return "LaC";
      break;
    case "Lighting Consultant":
      return "LiC";
      break;
    case "Parking Management Consultant":
      return "PaMC";
      break;
    case "Program Management Consultant":
      return "ProgMC";
      break;
    case "Security Consultant":
      return "SeC";
      break;
    case "Substation Consultant":
      return "SubC";
      break;

    default:
      let matches = consultant.replace(/[^a-zA-Z\s ]/g, "").match(/\b(\w)/g);
      let acronym = matches.join("");
      return acronym.toUpperCase();
  }
};
