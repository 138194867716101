<template>
  <div>
    <v-dialog v-model="dialog" width="750">
      <div class="dialog-wrapper">
        <div class="icon-close-wrapper pointer" @click="toogleDialog()">
          <v-icon>
            mdi-close
          </v-icon>
        </div>
        <DialogContent />
      </div>
    </v-dialog>

    <div class="d-flex flex-wrap area-top-items">
      <v-btn
        color="primary"
        class="white--text d-flex add-area-btn"
        @click="toogleDialog()"
      >
        Add new area
        <div>
          <v-icon>
            mdi-plus
          </v-icon>
        </div>
      </v-btn>
      <div class="ml-3 search-wrapper">
        <v-text-field
          v-model="searchValue"
          height="56px"
          outlined
          label="Search"
        />
      </div>
    </div>
    <div>
      <AreaTable :headers="headers" :items="items" />
    </div>
  </div>
</template>
<script>
import DialogContent from "./components/DialogContent/DialogContent.vue";
import AreaTable from "./components/AreaTable/AreaTable.vue";
import {
  multiplieSelectFilter,
  itemsFilter,
} from "@/utils/itemsFilter/itemsFilter";
export default {
  name: "Areas",
  components: {
    AreaTable,
    DialogContent,
  },
  data: function() {
    return {
      searchValue: "",
      headers: [
        "Area type",
        "Area title",
        "Brief name",
        "Disciplines assigned",
        "Consultant name",
        "Sub consultant name",
        "",
      ],

      dialog: false,
    };
  },
  computed: {
    items() {
      // return this.$store.getters["projectsModule/getProjectById"](this.id)
      //   .areas;

      if (this.$store.getters["projectsModule/getProjectById"](this.id).areas) {
        return itemsFilter(
          this.$store.getters["projectsModule/getProjectById"](this.id).areas,
          ["areaType", "areaTitle"],
          this.searchValue
        );
      } else {
        return [];
      }
    },
    id() {
      return this.$route.params.id;
    },
  },
  created() {},
  provide() {
    return {
      toggleDialog: this.toogleDialog,
    };
  },

  methods: {
    toogleDialog() {
      this.dialog = !this.dialog;
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-wrapper {
  position: relative;
}
.add-area-btn {
  font-size: 16px !important;
  border-radius: 0px !important;
  min-width: 200px !important;
  padding: 0 20px !important;
  height: 64px !important;
}
.area-top-items {
  min-height: 97px;
}
.search-wrapper {
  position: relative;
  top: 9px;
}
</style>
