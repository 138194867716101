<template>
  <div class="dialog">
    <div class="dialog__title">
      {{ workflow.id }} - {{ title }}
    </div>
    <div class="dialog__content">
      <div class="fields">
        <div class="select-row">
          <v-textarea
            v-model="workflowCopy.description"
            outlined
            name="input-7-4"
            label="Workflow note"
            height="200"
          />
        </div>

        <div class="select-row select-workflow">
          <div class="title">
            Approvals
          </div>
          <div
            v-for="(approver, i) in workflowCopy.actions"
            :key="`approver-${i}`"
            class="worfklow-select-item"
          >
            <div class="d-flex autocomplete-wrapper">
              <v-autocomplete
                clearable
                :class="
                  getDefaultValueFromSelectItems(approver.actor.id) &&
                    'hide-label'
                "
                outlined
                :value="getDefaultValueFromSelectItems(approver.actor.id)"
                :items="
                  removeSelectedUsersBefore(
                    workflowCopy.actions,
                    allUsersEmails
                  )
                "
                :label="`Approver ${i + 1}`"
                @change="(e) => onSelectChange(approver.actor.id, e, true)"
              >
                <template v-slot:item="data">
                  <div>
                    {{ data.item.text }}
                  </div>
                </template>
              </v-autocomplete>
              <div class="autocomplete-value">
                {{
                  getDefaultValueFromSelectItems(approver.userId) &&
                    getDefaultValueFromSelectItems(approver.userId).value.email
                }}
              </div>
              <div class="duration-input-wrapper">
                <v-text-field
                  label="Duration(WD)"
                  type="number"
                  :value="approver.expectedDuration"
                  outlined
                  @input="(e) => onDurationChange(approver.id, e)"
                />
              </div>
              <div
                v-if="i === 0"
                class="select-icon  pointer"
                :class="checkIsApproversItemEmpty() && 'select-icon__disabled'"
              >
                <v-icon
                  :class="checkIsApproversItemEmpty() && `disabled`"
                  @click="addApprover()"
                >
                  mdi-plus
                </v-icon>
              </div>
              <div
                v-else
                class="select-icon pointer"
                @click="deteleItem(approver.actor.id, true)"
              >
                <v-icon>
                  mdi-delete
                </v-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="dialog__buttons">
      <v-btn width="130px" height="45px" @click="onCancel()">
        Cancel
      </v-btn>
      <v-btn
        color="primary"
        width="130px"
        height="45px"
        :disabled="isSaveBtnDisabled"
        @click="onSave()"
      >
        Save
      </v-btn>
    </div>
  </div>
</template>
<script>
export default {
  name: "WorkflowModal",
  props: {
    title: {
      type: String,
      default: "",
    },
    workflow: {
      type: Object,
      default: null,
    },
    projectId: {
      type: String,
      default: "",
    },
  },
  data: function() {
    return {
      workflowCopy: JSON.parse(JSON.stringify(this.workflow)),
    };
  },
  computed: {
    allUsersEmails() {
      return this.$store.state.auth.users.map((u) => {
        return {
          value: {
            userId: u.id,
            name: `${u.firstName} ${u.lastName}`,
          },
          text: `${u.firstName} ${u.lastName}`,
        };
      });
    },
    isSaveBtnDisabled() {
      return !!this.checkIsApproversItemEmpty();
    },
  },
  watch: {
    workflow() {
      this.workflowCopy = JSON.parse(JSON.stringify(this.workflow));
    },
  },
  methods: {
    onSelectChange(id, val, isApprover) {
      console.log(id);
      console.log(val);
      this.workflowCopy.actions = this.workflowCopy.actions.map((a) => {
        if (a.actor.id === id) {
          // eslint-disable-next-line no-param-reassign
          a.actor.name = val ? val.name : "";
          // eslint-disable-next-line no-param-reassign
          a.actor.id = val.userId;
        }
        return a;
      });
    },
    addApprover() {
      this.workflowCopy.actions.push({
        expectedDuration: "",
        id: this.generateActionId(),
        type: "userAction",
        actor: {
          id: "",
          name: "",
        },
      });
    },
    created() {
      this.generateActionId();
    },
    generateActionId() {
      let string = this.workflowCopy.actions[
        this.workflowCopy.actions.length - 1
      ].id;
      let lastChar = string[string.length - 1];
      let idAsNumber = Number.parseInt(lastChar);
      idAsNumber = idAsNumber + 1;
      string = string.slice(0, -1);
      string = string.concat(idAsNumber);

      return string;
    },

    checkIsApproversItemEmpty() {
      return this.workflowCopy.actions.find(
        (a) =>
          a.actor.name === "" ||
          a.expectedDuration === "" ||
          !a.expectedDuration
      );
    },
    onDurationChange(id, val) {
      console.log(id, val);
      this.workflowCopy.actions = this.workflowCopy.actions.map((a) => {
        if (a.id === id) {
          // eslint-disable-next-line no-param-reassign
          a.expectedDuration = Number.parseInt(val);
        }
        return a;
      });
    },
    deteleItem(id, isApprover) {
      this.workflowCopy.actions = this.workflowCopy.actions.filter((w, i) => {
        return w.actor.id !== id;
      });
    },
    getDefaultValueFromSelectItems(userId) {
      return this.allUsersEmails.find((u) => {
        return u.value.userId === userId;
      });
    },
    test(item) {
      console.log(item.value.email === "MichaelJShelton@jourrapide.com");
      return item.value.email === "ichaelJShelton@jourrapide.com";
    },
    removeSelectedUsersBefore(selectedBefore, list, item) {
      const notSelectedBefore = list.filter((l) => {
        if (selectedBefore.map((s) => s.userId).includes(l.value.userId)) {
          return false;
        } else {
          return true;
        }
      });
      return JSON.parse(JSON.stringify(notSelectedBefore));
    },
    onCancel() {
      this.$emit("toggleModal");
      this.workflowCopy = JSON.parse(JSON.stringify(this.workflow));
    },
    onSave() {
      this.$store.commit("projectsModule/editWorkflow", {
        projectId: this.projectId,
        workflow: this.workflowCopy,
      });
      this.$emit("toggleModal");
    },
  },
};
</script>
<style scoped lang="scss">
.dialog {
  background: white;
  height: 697px;
  flex-direction: column;
  overflow: auto;
  overflow-x: hidden;
  display: flex;
  &__content {
    max-height: 509px;
    overflow: auto;
  }
  &__title {
    text-transform: uppercase;
    min-height: 95px;
    width: 100%;
    background-color: var(--v-primary-base) !important;
    display: flex;
    color: white;
    padding-left: 50px;
    align-items: center;
  }
  &__buttons {
    display: flex;
    width: 55%;
    justify-content: center;
    align-items: flex-end;
    align-self: flex-end;
    margin-top: 20px;
    column-gap: 10px;
    position: absolute;
    bottom: 23px;
  }
  .fields {
    // height: 697px;
    margin-top: 34px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .select-row {
    display: flex;
    column-gap: 10px;
    width: 613px;
    &.select-workflow {
      flex-direction: column;
      .title {
        display: flex;
        margin-bottom: 10px;
      }
    }
  }
}
.select-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
  position: relative;
  bottom: 17px;
  &__disabled {
    cursor: not-allowed !important;
  }
}
::v-deep .v-list-item__title {
  text-align: left !important;
}
.duration-input-wrapper {
  width: 146px;
  padding-left: 10px;
}
.disabled {
  pointer-events: none;
}
.autocomplete-value {
  position: absolute;
  top: 15px;
  left: 12px;
}
.autocomplete-wrapper {
  position: relative;
  .v-label {
    display: none !important;
  }
}
::v-deep .hide-label {
  .v-label {
    transform: translateY(-24px) scale(0.75);

    background: white;
    padding-left: 2px;
  }
}
::v-deep .v-input--is-focused {
  .v-label {
    display: block !important;
  }
}
</style>
