<template>
  <div>
    <v-row>
      <v-col sm="12">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th
                  v-for="(header, h) in headers"
                  :key="`table-headers-${h}`"
                  class="text-left"
                >
                  {{ header }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, i) in items" :key="i">
                <td>{{ item.name }}</td>
                <td class="d-flex justify-space-between align-center">
                  <p class="mb-0">
                    {{ item.description }}
                  </p>
                  <v-tooltip color="black" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <img
                        v-bind="attrs"
                        class="export-pdf-icon"
                        src="/img/icons/export-pdf.svg"
                        v-on="on"
                      >
                    </template>
                    <span>Export report to PDF</span>
                  </v-tooltip>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      headers: ["Name", "Description"],
      items: [
        {
          name: "Consultant fee summary",
          description:
            "Summary of total fees in contract, amount paid, amount forecast, variations and fees in dispute.",
        },
        {
          name: "Design Progress",
          description:
            "Update on current design progress showing areas on program and in delay.",
        },
        {
          name: "Deleted Scope & Deliverables",
          description:
            "All scope not in contract removed from an organisations standard suite of SOW documents.",
        },
        {
          name: "Duplicated Scope & Deliverables",
          description:
            "All scope and deliverables recurring in more than one contract.",
        },
        {
          name: "Added Scope and  Deliverables",
          description:
            "All added scope and deliverables not included in an organisations standard suite of SOW documents.",
        },
        {
          name: "Consultant fee comparison",
          description:
            "Compare consultants fees against sqm area, cost of CGI's across all projects managed by organisation.",
        },
        {
          name: "Project directory",
          description: "Details of all organisations on the project.",
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
tbody td {
  text-align: left;
  cursor: pointer;
  .export-pdf-icon {
    width: 24px;
    height: 24px;
    object-fit: contain;
    opacity: 0.54;
    &:hover {
      cursor: pointer;
      opacity: 0.85;
    }
  }
}
</style>
