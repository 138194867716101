import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Dashboard from "../views/Dashboard/Dashboard.vue";
import ControlRoom from "../views/ControlRoom/ControlRoom.vue";
import Areas from "../views/Areas/Areas.vue";
import ScopeOfWorks from "../views/ScopeOfWorks/ScopeOfWorks.vue";
import Workflows from "../views/Workflows/Workflows.vue";
import Projects from "../views/Projects/Projects.vue";
import Login from "../views/Login/Login.vue";
import ResetPassword from "../views/ResetPassword/ResetPassword.vue";
import SignUp from "../views/SignUp/SignUp.vue";
import Setup from "../views/Setup/Setup.vue";
import Etendering from "../views/Etendering/Etendering.vue";
import Faq from "../views/Faq/Faq.vue";
import Directory from "../views/Directory";
import Payments from "../views/Payments";
import store from "../store";

Vue.use(VueRouter);

const redirectIfAuth = (to, from, next) => {
  if (store.getters["auth/isAuth"]) {
    next({ name: "Projects" });
  } else {
    next();
  }
};

const routes = [
  {
    path: "/",
    name: "Login",
    component: Login,
    meta: {
      public: true,
    },
    beforeEnter: redirectIfAuth,
  },
  {
    path: "/reset-password",
    name: "reset-password",
    component: ResetPassword,
    meta: {
      public: true,
    },
    beforeEnter: redirectIfAuth,
  },
  {
    path: "/sign-up",
    name: "sign-up",
    component: SignUp,
    meta: {
      public: true,
    },
    beforeEnter: redirectIfAuth,
  },
  {
    path: "/:id/dashboard",
    name: "dashboard",
    component: Dashboard,
  },
  {
    path: "/dashboard",
    redirect: "1/dashboard/",
    name: "dashboard",
    component: Dashboard,
  },
  {
    path: "/:id/etendering",
    name: "etendering",
    component: Etendering,
  },
  {
    path: "/etendering",
    redirect: "1/etendering/",
    name: "etendering",
    component: Etendering,
  },
  {
    path: "/control-room",
    name: "ControllRoom",
    component: ControlRoom,
  },

  {
    path: "/directory/",
    name: "directory",
    redirect: "/1/directory/",
    component: Directory,
  },
  {
    path: "/:id/directory",
    name: "directory",
    component: Directory,
  },


  {
    path: "/payments/",
    name: "payments",
    redirect: "/1/payments/",
    component: Payments,
  },
  {
    path: "/:id/payments",
    name: "payments",
    component: Payments,
  },

  {
    path: "/:id/areas/",
    name: "areas",
    component: Areas,
  },

  {
    path: "/scope-of-works/",
    name: "scope-of-works",
    redirect: "/1/scope-of-works/",
    component: ScopeOfWorks,
  },
  {
    path: "/:id/scope-of-works",
    name: "scope-of-works",
    component: ScopeOfWorks,
  },

  {
    path: "/workflows/",
    name: "workflows",
    redirect: "/1/workflows/",
    component: Workflows,
  },
  {
    path: "/:id/workflows",
    name: "workflows",
    component: Workflows,
  },

  {
    path: "/projects",
    name: "Projects",
    component: Projects,
  },

  {
    path: "/faq",
    name: "faq",
    component: Faq,
    meta: {
      public: true,
    },
  },
  {
    path: "/setup",
    name: "setup",
    component: Setup,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  props: true,
  params: true,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((r) => !r.meta.public)) {
    if (store.getters["auth/isAuth"]) {
      next();
    } else {
      next({ name: "Login" });
    }
  } else {
    next();
  }
});

export default router;
