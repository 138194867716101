<template>
  <div>
    <v-dialog v-model="isScopeDialogOpen" width="1600">
      <div class="pos-relative">
        <div class="icon-close-wrapper pointer" @click="toogleScopeModalOpen()">
          <v-icon>
            mdi-close
          </v-icon>
        </div>
        <ScopeDialog
          :previewed-scope="consultant"
          :is-edit-mode="false"
          :can-edit="false"
          @toggleDialog="toogleScopeModalOpen"
        />
      </div>
    </v-dialog>

    <div class="d-flex">
      Attachments
    </div>
    <div
      v-for="(attachment, aid) in attachments"
      :key="`attachment -${aid}`"
      class="item mt-1"
    >
      {{ attachment }}
    </div>
    <div class="item pointer mt-1" @click="toogleScopeModalOpen">
      {{ getConsultantDisciplineById(consultant.classification.id) }}
    </div>
  </div>
</template>
<script>
import ScopeDialog from "../../../../../ScopeOfWorks/components/ScopeDialog/ScopeDialog.vue";
import { getConsultantDisciplineById } from "@/utils/getValueFromJSONById/getValueFromJSONById";

export default {
  name: "Attachments",
  components: { ScopeDialog },
  props: {
    attachments: {
      type: Array,
      default: () => [],
    },
    consultant: {
      type: Object,
    },
  },
  data: function() {
    return {
      isScopeDialogOpen: false,
    };
  },
  methods: {
    getConsultantDisciplineById,

    toogleScopeModalOpen() {
      this.$store.commit(
        "scopeOfWorksModule/setCurrentDialog",
        "t-prepAndBrief"
      );
      this.isScopeDialogOpen = !this.isScopeDialogOpen;
    },
  },
};
</script>
.<style lang="scss" scoped>
.item {
  text-align: left;
  color: #5085a5;
  text-decoration: underline;
}
</style>
