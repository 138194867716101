<template>
  <div class="dialog">
    <v-dialog v-model="isAddDialogOpen" width="700px">
      <div class="pos-relative">
        <div class="icon-close-wrapper pointer" @click="toggleAddDialog()">
          <v-icon>
            mdi-close
          </v-icon>
        </div>
        <ScopeDialogAddNewItem
          :type="currentTableTypeClicked"
          :current-edit-delivarable="currentEditDelivarable"
          :is-add-dialog-open="isAddDialogOpen"
          :edit-contract="editContract"
          @onAdd="onScopeItemAdd"
        />
      </div>
    </v-dialog>

    <div class="dialog__head">
      <div class="d-flex justify-space-between">
        <v-btn width="auto" color="primary">
          {{ getConsultantDisciplineById(previewedScope.classification.id) }}
        </v-btn>
        <div class="items-right">
          <v-btn
            v-if="!isEditMode && currentView !== 'master-suite' && canEdit"
            width="200px"
            color="primary"
            @click="toggleEditMode"
          >
            Edit
          </v-btn>

          <v-btn
            v-if="currentView !== 'master-suite' && isEditMode"
            width="200px"
            color="primary"
            @click="onSaveChanges"
          >
            Save changes
          </v-btn>
          <div v-if="currentView !== 'master-suite'" class="export-btn">
            <v-menu left bottom offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on">
                  mdi-dots-vertical
                </v-icon>
              </template>

              <v-list class="dialog-actions-list">
                <div class="pointer dialog-actions-list__item">
                  Send to workflow
                </div>
                <div
                  class="pointer dialog-actions-list__item"
                  @click="onExportToPDF"
                >
                  Export to PDF
                </div>
              </v-list>
            </v-menu>
          </div>
        </div>
      </div>
      <div class="d-flex mt-2">
        <v-btn width="200px" color="primary">
          CONTRACT TYPE
        </v-btn>
        <div class="select-wrapper ml-1">
          <div class="contract-type-item">
            {{ selectItems[0] }}
          </div>

          <!-- <v-select outlined :value="selectItems[0]" :items="selectItems" /> -->
        </div>
      </div>
    </div>
    <div class="dialog__content mt-2">
      <div>
        <ScopeDialogTableController
          :table-controller-items="tableControllerItems"
          :current-controller-item="currentControllerItem"
          @setControlledItem="setControllerItem"
        />
      </div>
      <div class="scope-tables">
        <ScopeDialogTable
          :table-type="'Scope'"
          :items="currentViewedScopes"
          :current-view="currentView"
          :is-edit-mode="isEditMode"
          :scopes-to-delete="scopesToDelete"
          :can-show-edit-actions="canShowEditActions"
          @onCompleteDelivarable="onEditDelevarable"
          @setScopesToDelete="setScopesToDelete"
          @onScopeOrDelivarableAdd="onScopeOrDelivarableAdd"
        />
        <ScopeDialogTable
          :scopes-to-delete="deliverablesToDelete"
          :is-edit-mode="isEditMode"
          :table-type="'Deliverables'"
          :items="currentDeliverables"
          :current-view="currentView"
          :current-route="currentRouteName"
          :can-show-edit-actions="canShowEditActions"
          :edit-contract="editContract"
          @onCompleteDelivarable="onEditDelevarable"
          @setScopesToDelete="setDelivarablesToDelete"
          @onScopeOrDelivarableAdd="onScopeOrDelivarableAdd"
        />
      </div>
    </div>
  </div>
</template>
<script>
import ScopeDialogAddNewItem from "./components/ScopeDialogAddNewItem/ScopeDialogAddNewItem.vue";
import ScopeDialogTable from "./components/ScopeDialogTable/ScopeDialogTable.vue";
import ScopeDialogTableController from "./components/ScopeDialogTableController/ScopeDialogTableController.vue";
import CSelect from "@/components/c-select/c-select.vue";
import { uuid } from "vue-uuid";

import {
  getConsultantDisciplineById,
  getConsultantCodeById,
  getStageCodeById,
} from "@/utils/getValueFromJSONById/getValueFromJSONById";
import { statuses } from "@/utils/constants/index";
export default {
  name: "ScopeDialog",
  components: {
    ScopeDialogTableController,
    ScopeDialogTable,
    ScopeDialogAddNewItem,
  },
  props: {
    currentView: {
      type: String,
      default: "my-scope",
    },
    isEditMode: {
      type: Boolean,
      default: false,
    },
    canEdit: {
      type: Boolean,
      default: true,
    },
    editContract: {
      type: Boolean,
      default: false,
    },
    previewedScope: {
      type: Object,
      default() {
        return {
          contractType: "Traditional",
          cost: 399,
          developmentType: "Mixed Use",
          discipline: "Architect of Record",
          industryType: "Construction",
          milestones: [
            {
              code: "PB",
              deliverables: [
                {
                  code: "AoR PB 1.1",
                  title: "Turpis egestas maecenas pharetra convallis",
                },
              ],
              stageNumber: 1,
              title: "Preparation & Briefing",
              type: "delivery",
            },
          ],
          scopes: [
            {
              code: "AoR S 1.1",
              title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
            },
          ],
        };
      },
    },
  },
  data: function() {
    return {
      statuses,
      scopesToDelete: [],
      deliverablesToDelete: [],

      scopesToBeAdded: [],
      delivarablesToBeAdded: [],

      // currentControllerItem: "Concept design",
      currentEditDelivarable: "",
      tableControllerItems: {
        mainItems: this.getMainItems(),
        subItems: this.getSubItems(),
      },
      selectItems: [this.previewedScope.contractType],
      currentTableTypeClicked: "",
      scopeTableItems: [{ title: "Scope" }],
      isAddDialogOpen: false,
    };
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    currentScopeOfWorksPageData() {
      return this.$store.state.scopeOfWorksModule.pageData.dialog;
    },
    currentViewedScopes() {
      if (this.currentControllerItem === "Deleted scope and deliverables") {
        return this.previewedScope.scopes.filter(
          (s) =>
            s.status === this.statuses.DELETED &&
            s.classification.id === this.previewedScope.classification.id
        );
      }
      if (this.currentControllerItem === "Added scope and deliverables") {
        return this.previewedScope.scopes.filter(
          (s) =>
            s.status === this.statuses.ADDED &&
            s.classification.id === this.previewedScope.classification.id
        );
      } else {
        return this.getOwnConsultantScopes();
      }
    },
    canShowEditActions() {
      return !(
        this.currentControllerItem === "Deleted scope and deliverables" ||
        this.currentControllerItem === "Added scope and deliverables" ||
        !this.canEdit
      );
    },

    currentControllerItem: {
      get: function() {
        return this.$store.state.scopeOfWorksModule.pageData.dialog
          .currentDialog;
      },
      set: function(val) {
        this.$store.commit("scopeOfWorksModule/setCurrentDialog", val);
      },
    },
    currentDeliverables() {
      if (this.currentControllerItem === "Deleted scope and deliverables") {
        let deleted = [];
        this.previewedScope.stages.forEach((s) => {
          s.deliverables.forEach((d) => {
            if (
              d.status === this.statuses.DELETED &&
              d.classification.id === this.previewedScope.classification.id
            ) {
              deleted.push(d);
            }
          });
        });
        return deleted;
      }
      if (this.currentControllerItem === "Added scope and deliverables") {
        let added = [];
        this.previewedScope.stages.forEach((s) => {
          s.deliverables.forEach((d) => {
            if (
              d.status === "added" &&
              d.classification.id === this.previewedScope.classification.id
            ) {
              added.push(d);
            }
          });
        });
        return added;
      } else {
        return this.getOwnConsultanDelivarables();
      }
    },
  },
  created() {
    this.tableControllerItems.subItems = this.getSubItems();
  },

  methods: {
    getConsultantDisciplineById,
    getConsultantCodeById,
    getStageCodeById,
    getOwnConsultantScopes() {
      if (this.currentRouteName === "scope-of-works") {
        return this.previewedScope.scopes.filter((s) => {
          return (
            s.classification.id === this.previewedScope.classification.id &&
            s.status !== "deleted"
          );
        });
      } else {
        return this.previewedScope.scopes;
      }
    },
    getOwnConsultanDelivarables(deleted) {
      return this.previewedScope.stages
        .find(
          (s) =>
            s.type === this.currentControllerItem &&
            s.deliverables.filter(
              (d) =>
                d.classification.id === this.previewedScope.classification.id
            ).length > 0
        )
        ?.deliverables.filter((d) => {
          return (
            d.classification.id === this.previewedScope.classification.id &&
            d.status !== this.statuses.DELETED
          );
        });
    },
    getSubItems() {
      if (this.currentRouteName === "scope-of-works")
        return [
          "Deleted scope and deliverables",
          "Added scope and deliverables",
        ];
      else {
        return [];
      }
    },
    checkClassificationForStage(stage) {
      stage.deliverables.filter(
        (d) => d.classification.id === this.previewedScope.classification.id
      ).length > 0;
    },
    setScopesToDelete(scopesToDelete) {
      this.scopesToDelete = scopesToDelete;
    },
    setDelivarablesToDelete(deliverablesToDelete) {
      this.deliverablesToDelete = deliverablesToDelete;
    },
    toggleEditMode() {
      this.$emit("toggleEditMode");
    },
    onScopeOrDelivarableAdd(type, delivarable) {
      this.currentTableTypeClicked = type;
      if (delivarable) {
        this.currentEditDelivarable = delivarable;
      } else {
        this.currentEditDelivarable = "";
      }
      this.toggleAddDialog();
    },
    onScopeItemAdd(type, val) {
      if (type === "Scope") {
        let item = {
          code: `${this.getConsultantCodeById(
            this.previewedScope.classification.id
          )} S 1.${this.getOwnConsultantScopes().length + 1} `,
          title: val,
          classification: {
            id: this.previewedScope.classification.id,
            scheme: this.previewedScope.classification.scheme,
          },
          status: "added",
        };
        this.previewedScope.scopes.push(item);
        this.scopesToBeAdded.push(item);
        // addScopeToAddedScopes
      }
      if (type === "Deliverables") {
        // console.log(this.previewedScope.stages);
        let currentClassificationStages = this.previewedScope.stages.filter(
          (s) => {
            return (
              s.classification.id === this.previewedScope.classification.id
            );
          }
        );
        currentClassificationStages.map((m) => {
          if (m.type === this.currentControllerItem) {
            let uniqCode = this.getStageCodeById(this.currentControllerItem);
            let length = m.deliverables.filter((d) => {
              return this.currentRouteName === "scope-of-works"
                ? d.classification.id === this.previewedScope.classification.id
                : true;
            }).length;
            let delivarableToAdd = {
              code: `${this.getConsultantCodeById(
                this.previewedScope.classification.id
              )} ${uniqCode} 1.${length + 1}`,
              title: val,
              classification: {
                id: this.previewedScope.classification.id,
                scheme: this.previewedScope.classification.scheme,
              },
              status: "added",
              transmittal: "",
            };
            if (this.editContract) {
              delete delivarableToAdd.transmittal;
              delivarableToAdd.id = uuid.v1();
              this.$store.commit(
                "scopeOfWorksModule/addDocument",
                delivarableToAdd.id
              );
            }

            m.deliverables.push(delivarableToAdd);
            this.delivarablesToBeAdded.push(delivarableToAdd);
          }

          return m;
        });
      }
      if (type === "Transmital") {
        console.log("val", val);
        if (this.editContract) {
          this.$store.commit("scopeOfWorksModule/editDocument", {
            id: this.currentEditDelivarable.id,
            docVal: val,
          });
        } else {
          this.previewedScope.stages.map((m) => {
            // eslint-disable-next-line no-param-reassign
            m.deliverables = m.deliverables.map((d) => {
              if (d.code === this.currentEditDelivarable.code) {
                // eslint-disable-next-line no-param-reassign
                d.transmittal = val;
              }
              return d;
            });

            return m;
          });
          this.onEditDelevarable(this.currentEditDelivarable);
        }
      }
      this.toggleAddDialog();
    },
    onEditDelevarable(delivarable) {
      this.$store.commit("projectsModule/setDelivarableCompleted", {
        projectId: this.$route.params.id,
        areaTitle: this.currentScopeOfWorksPageData.currentArea,
        consultantId: this.currentScopeOfWorksPageData.consultrant,
        newScope: this.previewedScope,
        delivarable: delivarable,
      });
    },
    rewriteDataToStore() {
      //для сторінки контрол рум
      if (this.currentRouteName !== "scope-of-works") {
        this.previewedScope.scopes = this.previewedScope.scopes.filter((s) => {
          if (
            this.scopesToDelete.filter((stdlt) => stdlt.code === s.code)
              .length === 0
          ) {
            return true;
          } else {
            return false;
          }
        });
        this.previewedScope.stages.map((m) => {
          // eslint-disable-next-line no-param-reassign
          m.deliverables = m.deliverables.map((d) => {
            if (this.checkIsDelivarableToDelete(d)) {
              // eslint-disable-next-line no-param-reassign
              d.status = "deleted";
            }
            return d;
          });
        });
        this.$store.commit(
          "controlRoomModule/editMyScope",
          this.previewedScope
        );
      }
      //Для сторінки scope of works
      else {
        this.previewedScope.scopes = this.previewedScope.scopes.map((s) => {
          if (
            this.scopesToDelete.filter((stdlt) => stdlt.code === s.code)
              .length !== 0
          ) {
            // eslint-disable-next-line no-param-reassign
            s.status = "deleted";
          }
          return s;
        });
        this.previewedScope.stages.map((m) => {
          // eslint-disable-next-line no-param-reassign
          m.deliverables = m.deliverables.map((d) => {
            if (this.checkIsDelivarableToDelete(d)) {
              // eslint-disable-next-line no-param-reassign
              d.status = "deleted";
            }
            return d;
          });
        });
        if (!this.editContract) {
          this.$store.commit("projectsModule/editScope", {
            projectId: this.$route.params.id,
            areaTitle: this.currentScopeOfWorksPageData.currentArea,
            consultantId: this.currentScopeOfWorksPageData.consultrant,
            newScope: this.previewedScope,
          });
        } else {
          this.$store.commit("projectsModule/editContractScope", {
            projectId: this.$route.params.id,
            areaTitle: this.currentScopeOfWorksPageData.currentArea,
            consultantId: this.currentScopeOfWorksPageData.consultrant,
            newScope: this.previewedScope,
          });
        }
      }
      this.scopesToBeAdded = [];
      this.delivarablesToBeAdded = [];
      this.scopesToDelete = [];
      this.deliverablesToDelete = [];
    },
    onExportToPDF() {
      window.print();
    },
    onSaveChanges() {
      this.rewriteDataToStore();
      this.$emit("toggleEditMode");
      // this.$emit("toggleDialog");
    },
    toggleAddDialog() {
      this.isAddDialogOpen = !this.isAddDialogOpen;
    },
    checkIsDelivarableToDelete(delivarable) {
      let result = this.deliverablesToDelete.filter((dToDelete) => {
        if (dToDelete.code === delivarable.code) {
          return true;
        } else {
          return false;
        }
      });
      return result.length !== 0;
    },
    setControllerItem(val) {
      this.currentControllerItem = val;
    },
    getMainItems() {
      let filteredStages = this.previewedScope.stages.filter((s) => {
        // let result = s.deliverables.filter((d) => {
        //   if (d.classification.id === this.previewedScope.classification.id) {
        //     return true;
        //   }
        // });

        // return result.length > 0;
        return s.classification.id === this.previewedScope.classification.id;
      });

      return filteredStages.map((s) => s.type);
    },
  },
};
</script>
<style scoped lang="scss">
.dialog-actions-list {
  width: 200px;
  &__item {
    text-align: left;
    margin-left: 10px;
    padding: 10px;
    &:hover {
      background: whitesmoke;
    }
  }
}
.export-btn {
  position: relative;
  //top: 20px;
}
.dialog {
  background: white;
  min-height: 800px;
  padding: 25px;
  max-height: 600px;
  // position: relative;

  overflow: auto;
  .item {
    background: var(--v-primary-base) !important;
    color: white;
    padding: 10px;
  }
  .items-right {
    display: flex;
    align-items: center;
    column-gap: 5px;
  }
  &__content {
    display: grid;
    column-gap: 4px;
    grid-template-columns: 200px 1fr;
  }
}
.scope-tables {
  display: grid;
  grid-template-columns: 1fr 1.1fr;
}
.blured-block-text {
  position: absolute;
  /* top: 0px; */
  right: 0px;
  left: 0px;
  bottom: 298px;
}

::v-deep {
  .select-wrapper {
    width: 200px;
    .v-label {
      top: 8px !important;
    }
    .--is-label-active {
      color: red !important;
    }
    .v-input__append-inner {
      margin-top: 6px !important;
    }
    .v-label--active {
      transform: translateY(-13px) scale(0.75) !important;
    }
    .v-input__control {
      height: 20px !important;
    }
    .v-input__control > .v-input__slot {
      min-height: 38px !important;
    }
  }
}
.contract-type-item {
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 10px;
  text-transform: capitalize;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.38);
}
</style>
