<template>
  <div class="card">
    <slot />
  </div>
</template>
<script>
export default {
  name:"Card"
    
}
</script>
<style lang="scss" scoped>
.card{
    height: 100%;
    display: flex;
    background: grey;
    padding:10px;
    flex-direction: column;
    // &__title{
    // }
   background: whitesmoke;
}

</style>
