<template>
  <div>
    <div>
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="innerValue"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="innerValue"
            outlined
            :label="label"
            readonly
            :disabled="disabled"
            v-bind="attrs"
            :append-icon="
              showIcon
                ? `
             mdi-calendar-month

            `
                : ''
            "
            v-on="on"
          />
        </template>
        <v-date-picker v-model="innerValue" no-title scrollable>
          <v-btn text @click="$refs.menu.save('')">
            Reset
          </v-btn>
          <v-spacer />
          <v-btn text color="primary" @click="menu = false">
            Cancel
          </v-btn>
          <v-btn text color="primary" @click="$refs.menu.save(innerValue)">
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
    </div>
  </div>
</template>
<script>
export default {
  name: "DatePicker",
  props: {
    label: String,
    value: String,
    disabled: {
      type: Boolean,
      default: false,
    },
    showIcon: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    date: "",
    menu: false,
    modal: false,
    menu2: false,
  }),
  computed: {
    innerValue: {
      get: function() {
        return this.value;
      },
      set: function(newValue) {
        this.$emit("update:value", newValue);
      },
    },
  },
};
</script>
<style scoped lang="scss">
::v-deep .v-input__slot {
  max-height: 42px !important;
  min-height: 42px !important;
}
::v-deep .v-text-field--outlined .v-label {
  top: 13px !important;
}
::v-deep input {
  font-size: 14px !important;
}
::v-deep .v-input__append-inner {
  margin-top: 11px !important;
}
</style>
