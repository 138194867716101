<template>
  <div class="table-wrapper">
    <v-dialog v-model="isWorkflowModalOpen" width="750">
      <div class="pos-relative">
        <div class="icon-close-wrapper pointer" @click="toggleWorkflowModal()">
          <v-icon>
            mdi-close
          </v-icon>
        </div>
        <div>
          <WorkflowModal
            :workflow="currentWorkflowSelected.workflow"
            :title="currentWorkflowSelected.title"
            :project-id="id"
            @toggleModal="toggleWorkflowModal"
          />
        </div>
      </div>
    </v-dialog>

    <v-simple-table>
      <thead>
        <td
          v-for="(header, i) in headers"
          :key="`scope-table-header-${i}`"
          :class="'primary-background white--text text-left'"
          class="table-header"
        >
          <div class="table-item-wrapper">
            {{ header }}
          </div>
        </td>
      </thead>
      <tbody>
        <tr
          v-for="(item, i) in workflows"
          :key="`workflow-table-item -${i}`"
          @click="
            setCurrentWorkflowSelected(
              item.title,
              getProjectWorkflowById(item.id)
            )
          "
        >
          <td class="text-left">
            {{ item.id }}
          </td>
          <td class="text-left">
            {{ item.title }}
          </td>
          <td class="text-left">
            {{ getFullPrganizatorRoleName(item.approverRoleId) }}
          </td>
          <td class="text-left">
            {{ getFullPrganizatorRoleName(item.initiatorRoleId) }}
          </td>
          <td class="text-left">
            {{ calcDuration(getProjectWorkflowById(item.id).actions) }}
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>
<script>
import WorkflowModal from "./components/WorkflowModal/WorkflowModal.vue";
import headers from "./headers.json";
import json from "@/simpleData.json";

export default {
  name: "Workflows",
  components: { WorkflowModal },
  data: function() {
    return {
      headers: headers.headers,
      isWorkflowModalOpen: false,
      currentWorkflowSelected: {
        workflow: null,
        title: "",
      },
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    workflows() {
      return this.$store.state.workflowsModule.workflows;
    },
    projectWorkflows() {
      return this.$store.getters["projectsModule/getProjectById"](this.id)
        .approverWorkflowConfigurations;
    },
  },
  methods: {
    getProjectWorkflowById(id) {
      console.log(id);
      return this.projectWorkflows?.find((w) => w.processDefinitionId === id)
        ? this.projectWorkflows.find((w) => w.processDefinitionId === id)
        : [];
    },
    setCurrentWorkflowSelected(title, workflow) {
      // this.currentWorkflowSelected.workflow = workflow;
      // this.currentWorkflowSelected.title = title;

      this.currentWorkflowSelected = {
        ...this.currentWorkflowSelected,
        workflow,
        title,
      };
      this.toggleWorkflowModal();
    },
    calcDuration(approvers) {
      let duration = 0;
      approvers?.forEach((a) => {
        duration = duration + a.expectedDuration;
      });

      return duration;
    },
    toggleWorkflowModal() {
      this.isWorkflowModalOpen = !this.isWorkflowModalOpen;
    },
    getFullPrganizatorRoleName(roleId) {
      return json.organizationRoles.find((o) => o.id === roleId).title;
    },
  },
};
</script>
<style scoped lang="scss">
.table-wrapper {
  margin-top: 93px;
}
::v-deep td {
  padding: 10px;
}

.table-header {
  font-size: 14px;
  min-height: 38px;
}
.table-item-wrapper {
  display: flex;
  min-height: 44px;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  left: 7px;
}
::v-deep tr {
  cursor: pointer;

  // table-layout: fixed !important;
}
</style>
