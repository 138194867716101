<template>
  <div>
    <v-simple-table class="theme-white">
      <thead>
        <tr>
          <th
            v-for="(header, i) in headers"
            :key="`controlRoomTableHeader - ${i}`"
            class="text-left"
          >
            <div :class="i === 0 && 'first-col'">
              {{ header }}
            </div>
          </th>
          <th />
          <th />
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, i) in items"
          :key="`controlRoomTableItem - ${i}`"
          class="text-left pointer"
          @click.stop="onPreviewBtnClick(item)"
        >
          <td>
            <div class="d-flex">
              <!-- <div>
                <v-checkbox />
              </div> -->
              <div class="d-flex align-center">
                {{ getIndustryTypeById(item.industryType) }}
              </div>
            </div>
          </td>
          <!-- <td>
            {{ item.developmentType }}
          </td> -->
          <td>
            {{ getContractTypeById(item.contractType) }}
          </td>
          <td>
            <div class="col-discipline">
              {{ getConsultantDisciplineById(item.classification.id) }}
            </div>
          </td>
          <td>
            <div class="cost-col">
              {{
                `${item.value && canShowItem() ? `$` + item.value.amount : ""}`
              }}
            </div>
          </td>
          <td>
            <!-- <v-btn
              rounded
              color="primary"
              height="29px"
              @click="onPreviewBtnClick(item)"
            >
              Preview
            </v-btn> -->
          </td>
          <td>
            <div class="add-to-card-btn">
              <v-icon
                v-if="canShowItem()"
                rounded
                height="29px"
                @click.stop="addToOrder(item)"
              >
                mdi-cart-plus
              </v-icon>
            </div>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>
<script>
import {
  getConsultantDisciplineById,
  getIndustryTypeById,
  getContractTypeById,
} from "@/utils/getValueFromJSONById/getValueFromJSONById";

export default {
  name: "ControlRoomTable",
  // props: ["headers", "items"],
  props: {
    headers: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    preview: {
      type: String,
      default: () => "master-suite",
    },
  },

  methods: {
    addToOrder(item) {
      this.$emit("addToOrder", item);
    },
    getConsultantDisciplineById,
    getIndustryTypeById,
    getContractTypeById,
    canShowItem() {
      if (this.preview === "master-suite") {
        return true;
      }
      if (this.preview === "my-scope") {
        return false;
      }
    },
    onPreviewBtnClick(scope) {
      this.$emit("onPreviewButtonClick", scope, this.preview);
    },
  },
};
</script>
<style scoped lang="scss">
::v-deep table {
  border-top: thin solid rgba(0, 0, 0, 0.12);
}
.cost-col {
  width: 40px;
  height: 21px;
}
.col-discipline {
  min-width: 291px;
  height: 67px;
  display: flex;
  align-items: center;
  text-align: left;
}
.add-to-card-btn {
  height: 29px;
  color: grey;
}
.first-col {
  position: relative;
  // left: 33px;
}
::v-deep table td {
  table-layout: fixed;
  width: 20px;
  overflow: hidden;
  word-wrap: break-word;
}
</style>
