<template>
  <v-app>
    <v-btn class="restore-btn" @click="restoreBtnClick">
      Save to local storage
    </v-btn>
    <v-btn class="restore-sample-btn" @click="restoreSampleData">
      restore sample data
    </v-btn>
    <v-dialog v-model="isConfMenuOpen" width="1200">
      <div class="dialog-wrapper">
        <div class="icon-close-wrapper pointer" @click="toogleDialog()">
          <v-icon>
            mdi-close
          </v-icon>
        </div>
        <ConfigurationMenu @toggleDialog="toogleDialog" />
      </div>
    </v-dialog>
    <v-app-bar id="#app" app absolute :height="presintationPages ? 200 : 100">
      <div class="icon-settings-wrapper" @click="toogleDialog()">
        <v-icon>
          mdi-cog-outline
        </v-icon>
      </div>
      <Navbar :presintation-pages="presintationPages" />
    </v-app-bar>

    <v-main
      class="mt-3"
      :class="
        ['Login', 'reset-password', 'sign-up'].includes(currentRouteName) &&
          'login-register-pages'
      "
    >
      <v-container fluid>
        <div
          class="main-wrap"
          :class="
            (presintationPages || isFaqPage || isSetupPage || isProjectsPage) &&
              'no-sidebar'
          "
        >
          <div
            v-if="showSidebar"
            class="sidebar-wrapper"
            :class="!isSidebarOpen && 'closed'"
          >
            <Sidebar v-if="isSidebarOpen" :projects="projects" />

            <div class="nav-toggle-btn">
              <v-icon :class="isSidebarOpen && 'open'" @click="toggleSidebar()">
                mdi-play-circle
              </v-icon>
            </div>
          </div>
          <router-view />
        </div>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import { formatStoreDataToCorrectJSON } from "@/utils/formatStoreDataToCoorectJSON/formatStoreDataToCorrectJSON";

import ConfigurationMenu from "./components/configurationMenu/ConfigurationMenu.vue";
import Navbar from "@/components/Navbar/Navbar.vue";
import Sidebar from "@/components/Sidebar/Sidebar.vue";
import LogoSection from "@/components/LogoSection/LogoSection.vue";
import json from "@/simpleData.json";
import { uuid } from "vue-uuid";

export default {
  name: "App",

  components: {
    Navbar,
    Sidebar,
    ConfigurationMenu,
  },
  data: function() {
    return {
      json: json,
      isConfMenuOpen: false,
      isSidebarOpen: true,
      projects: [
        {
          id: 1,
          name: "Harbour District",
          code: "31457",
          industryType: "Construction",
          developmentType: "Mixed Use",
          areas: ["Urban", " Residential Tower", "Office", "Retail"],
          isArchived: false,
        },

        {
          id: 2,
          name: "Luxury Resort Hotel",
          code: "36748",
          industryType: "Hotel",
          developmentType: "Hotel",
          areas: ["Resort"],
          isArchived: false,
        },
        {
          id: 3,
          name: "One Central Tower",
          code: "52969",
          industryType: "Construction",
          developmentType: "High Rise Tower",
          areas: ["Resort"],
          isArchived: false,
        },
      ],
    };
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    presintationPages() {
      return ["Login", "reset-password", "sign-up"].includes(
        this.currentRouteName
      );
    },
    isFaqPage() {
      return this.currentRouteName === "faq";
    },
    isProjectsPage() {
      return this.currentRouteName === "Projects";
    },
    isSetupPage() {
      return this.currentRouteName === "setup";
    },
    showSidebar() {
      return (
        !this.presintationPages &&
        !this.isFaqPage &&
        !this.isSetupPage &&
        !this.isProjectsPage
      );
    },
  },
  created: function() {
    this.setStoreData(json);

    if (localStorage.getItem("data")) {
      this.setStoreData(JSON.parse(localStorage.getItem("data")));
    }

    window.onbeforeunload = (event) => {
      localStorage.setItem(
        "data",
        JSON.stringify(formatStoreDataToCorrectJSON(this.$store.state))
      );
    };
  },
  mounted() {
    window.addEventListener("onbeforeunload", this.restoreBtnClick());
  },
  beforeDestroy() {
    window.removeEventListener("unload", this.someMethod);
  },
  methods: {
    restoreSampleData() {
      this.$store.commit("projectsModule/setProjects", json.projects);
      this.$store.commit("controlRoomModule/setScopes", json.scopeOfWorks);
      this.$store.commit("controlRoomModule/setMyScopes", json.myScopeOfWorks);
      this.$store.commit("myFrameworkModule/setFrameworks", json.myFramework);
      this.$store.commit("auth/setUsers", json.users);
      this.$store.commit(
        "workflowsModule/setWorkflows",
        json.processDefinitions
      );
    },

    restoreBtnClick() {
      localStorage.setItem(
        "data",
        JSON.stringify(formatStoreDataToCorrectJSON(this.$store.state))
      );
    },
    setStoreData(data) {
      this.$store.commit("projectsModule/setProjects", data.projects);
      this.$store.commit("controlRoomModule/setScopes", data.scopeOfWorks);
      this.$store.commit("controlRoomModule/setMyScopes", data.myScopeOfWorks);
      this.$store.commit("myFrameworkModule/setFrameworks", data.myFramework);

      this.$store.commit("auth/setUsers", data.users);
      this.$store.commit("workflowsModule/setWorkflows", data.workflows);
    },
    toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen;
    },
    toogleDialog() {
      this.isConfMenuOpen = !this.isConfMenuOpen;
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-settings-wrapper {
  cursor: pointer;
  position: absolute;
  top: 0px;
  right: 0px;
  .v-icon {
    color: var(--v-primary-base) !important;
  }
}
.dialog-wrapper {
  position: relative;
}
.restore-btn {
  position: absolute;
  bottom: 0px;
  left: 0px;
  z-index: 100;
}
.restore-sample-btn {
  position: absolute;
  bottom: 0px;
  left: 294px;
  z-index: 100;
}
</style>
<style lang="scss" src="./App.scss"></style>
