<template>
  <div class="dialog">
    <div class="dialog__title">
      NEW USER
    </div>
    <v-form ref="form" @submit.prevent="onSubmit">
      <div class="row">
        <div class="col-sm-6">
          <div class="dialog__content pa-5 pl-11">
            <p v-if="showError" class="red--text">
              User with this email already exists.
            </p>
            <v-text-field
              v-model="formData.firstName"
              outlined
              class="form-input"
              label="First Name*"
              :rules="[validators.required]"
            />
            <v-text-field
              v-model="formData.lastName"
              outlined
              class="form-input"
              label="Last Name*"
              :rules="[validators.required]"
            />
            <v-text-field
              v-model="formData.emailAddress"
              outlined
              class="form-input"
              label="Email Address*"
              :rules="[validators.required, validators.email]"
            />
            <v-text-field
              v-model="formData.password"
              outlined
              class="form-input"
              label="Password*"
              :type="showPassword ? 'text' : 'password'"
              :rules="[validators.required, validators.minLength(6)]"
            >
              <template v-if="formData.password" v-slot:append>
                <v-icon @click="showPassword = !showPassword">
                  {{ showPassword ? 'mdi-eye-off-outline' : 'mdi-eye-outline' }}
                </v-icon>
              </template>
            </v-text-field>
            <v-text-field
              v-model="confirmPassword"
              outlined
              class="form-input"
              label="Confirm Password*"
              :type="showConfirmPassword ? 'text' : 'password'"
              :rules="[validators.required, validators.minLength(6), matchPassword]"
            >
              <template v-if="confirmPassword" v-slot:append>
                <v-icon @click="showConfirmPassword = !showConfirmPassword">
                  {{ showConfirmPassword ? 'mdi-eye-off-outline' : 'mdi-eye-outline' }}
                </v-icon>
              </template>
            </v-text-field>
          </div>
        </div>
      </div>
      <div class="dialog_buttons">
        <v-btn width="130px" height="45px" @click="$emit('closeModal')">
          Cancel
        </v-btn>

        <v-btn
          color="primary"
          width="130px"
          height="45px"
          type="submit"
        >
          Create
        </v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
import validators from '@/utils/validators';
export default {
  data() {
    return {
      validators,
      formData: {
        firstName: '',
        lastName: '',
        emailAddress: '',
        password: '',
      },
      confirmPassword: '',
      showPassword: false,
      showConfirmPassword: false,
      showError: false,
    };
  },
  computed: {
    matchPassword() {
      return (v) => v === this.formData.password || 'Passwords don\'t match';
    },
  },
  watch: {
    'formData.emailAddress'() {
      this.showError = false;
    },
  },
  methods: {
    async onSubmit() {
      if (this.$refs.form.validate()) {
        try {
          await this.$store.dispatch('auth/register', this.formData);
          this.$emit('closeModal');
        } catch (e) {
          this.showError = true;
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.dialog {
  background: white;
  min-height: 500px;
  flex-direction: column;
  overflow: auto;
  overflow-x: hidden;
  display: flex;
  &__title {
    text-transform: uppercase;
    min-height: 95px;
    width: 100%;
    background-color: var(--v-primary-base) !important;
    display: flex;
    color: white;
    padding-left: 50px;
    align-items: center;
  }
  &_buttons {
    display: flex;
    justify-content: flex-end;
    margin: 20px 44px 20px 20px;
    column-gap: 10px;
  }
}
</style>