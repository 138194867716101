<template>
  <div class="data">
    <div class="add-discipline-btn">
      <v-btn rounded width="165" height="51" @click="onDisciplinesAddBtnClick">
        + Add Discipline
      </v-btn>
    </div>
    <div class="disciplines-list">
      <div class="title">
        Disciplines
      </div>
      <div class="list">
        <div
          v-for="(item, i) in items"
          :key="`allocated create area -${i}`"
          class="item"
          :class="cheksIsItemToRemove(item) && 'item-to-remove'"
        >
          <div>
            {{
              getConsultantDisciplineById(
                getAllocatedById(item).classification.id
              )
            }}
          </div>
          <div class="pointer" @click="onDisciplineRemove(item)">
            <v-icon v-if="cheksIsItemToRemove(item)">
              mdi-backup-restore
            </v-icon>
            <v-icon v-else>
              mdi-delete
            </v-icon>
          </div>
        </div>
        <div
          v-for="(item, i) in itemsToAdd"
          :key="`allocated createitem - 2area -${i}`"
          class="item"
          :class="cheksIsItemToRemove(item) && 'item-to-remove'"
        >
          <div>
            {{
              getConsultantDisciplineById(
                getAllocatedById(item).classification.id
              )
            }}
          </div>
          <div class="pointer" @click="onDisciplineRemove(item)">
            <v-icon v-if="cheksIsItemToRemove(item)">
              mdi-backup-restore
            </v-icon>
            <v-icon v-else>
              mdi-delete
            </v-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getConsultantDisciplineById } from "@/utils/getValueFromJSONById/getValueFromJSONById";

export default {
  name: "AreaSecondStep",
  components: {},
  props: {
    areaType: {
      type: String,
      default: () => "",
    },
    items: {
      type: Array,
      default: () => [],
    },
    itemsToRemove: {
      type: Array,
      default: () => [],
    },
    itemsToAdd: {
      type: Array,
      default: () => [],
    },
  },
  data: function() {
    return {};
  },

  methods: {
    getConsultantDisciplineById,
    onDisciplinesAddBtnClick() {
      this.$emit("onDisciplinesAddBtnClick");
    },
    getAllocatedById(id) {
      return this.$store.getters["controlRoomModule/getMyScopeById"](id);
    },
    onDisciplineRemove(id) {
      if (this.cheksIsItemToRemove(id)) {
        this.$emit(
          "setItemsToRemove",
          this.itemsToRemove.filter((i) => i !== id)
        );
      } else {
        this.$emit("setItemsToRemove", [...this.itemsToRemove, id]);
      }
    },
    cheksIsItemToRemove(item) {
      if (this.itemsToRemove.indexOf(item) === -1) {
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.add-discipline-btn {
  display: flex;
  position: relative;
  left: 8px;
  bottom: 10px;
}
.disciplines-list {
  padding-left: 50px;
  padding-right: 50px;
  min-height: 680px;
  .title {
    display: flex;
    font-size: 13px !important;
  }
  .item {
    padding: 20px 0px;

    display: flex;
    justify-content: space-between;
    &.item-to-remove {
      color: grey;
    }
  }
}
</style>
