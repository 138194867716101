import json from "../../simpleData.json";
export const getConsultantDisciplineById = (consultantId) => {
  return json.precontraxDisciplines.find((d) => d.id === consultantId)
    ? json.precontraxDisciplines.find((d) => d.id === consultantId).title
    : "";
};
export const getConsultantCodeById = (consultantId) => {
  return json.precontraxDisciplines.find((d) => d.id === consultantId).code;
};
export const getIndustryTypeById = (industryId) => {
  return json.precontraxIndustryTypes.find((d) => d.id === industryId).title;
};
export const getContractTypeById = (contractId) => {
  return json.precontraxContractTypes.find((d) => d.id === contractId).title;
};
export const getStageTypeById = (id) => {
  return json.precontraxStageTypes.find((d) => d.id === id).title;
};
export const getStageCodeById = (id) => {
  return json.precontraxStageTypes.find((d) => d.id === id).code;
};
export const getProccessDefinitionTypeById = (id) => {
  return json.processDefinitions.find((d) => d.id === id).title;
};
export const getStageTypeByTitle = (stageTitle) => {
  return json.precontraxStageTypes.find((s) => s.title === stageTitle).id;
};
export const getStageCodeByType = (type) => {
  return json.precontraxStageTypes.find((s) => s.id === type).code;
};
export const getTenderStatusTitleById = (tenderStatusID) => {
  return json.tenderStatuses.find((s) => s.id === tenderStatusID).title;
};
