<template>
  <v-form ref="form" class="personal-info-form px-5 px-lg-15" @submit.prevent="onSubmit">
    <h3 class="form-title">
      Personal Info
    </h3>
    <v-text-field
      v-model="formData.firstName"
      :rules="[validators.required]"
      outlined
      class="form-input"
      label="First Name"
    />
    <v-text-field
      v-model="formData.lastName"
      :rules="[validators.required]"
      outlined
      class="form-input"
      label="Last Name"
    />
    <v-text-field
      :value="emailAddress"
      :rules="[validators.required, validators.email]"
      outlined
      disabled
      class="form-input"
      label="Email Address"
    />
    <FormButtons class="mt-5" @cancel="setInitialData" />
  </v-form>
</template>

<script>
import FormButtons from "./FormButtons";
import validators from '@/utils/validators';

export default {
  components: {
    FormButtons,
  },
  data() {
    return {
      validators,
      formData: {
        firstName: '',
        lastName: '',
      },
      emailAddress: '',
    };
  },
  created() {
    this.setInitialData();
  },
  methods: {
    setInitialData() {
      this.formData.firstName = this.$store.state.auth.currentUser.firstName;
      this.formData.lastName = this.$store.state.auth.currentUser.lastName;
      this.emailAddress = this.$store.state.auth.currentUser.emailAddress;
    },
    async onSubmit() {
      if (this.$refs.form.validate()) {
        try {
          await this.$store.dispatch('auth/updateInfo', this.formData);
        } catch (e) {
          console.log(e);
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.form-title {
  margin-bottom: 20px;
  font-size: 16px;
  text-transform: uppercase;
}
</style>