import { render, staticRenderFns } from "./AreaAddDisciplines.vue?vue&type=template&id=14d3ebec&scoped=true&"
import script from "./AreaAddDisciplines.vue?vue&type=script&lang=js&"
export * from "./AreaAddDisciplines.vue?vue&type=script&lang=js&"
import style0 from "./AreaAddDisciplines.vue?vue&type=style&index=0&id=14d3ebec&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14d3ebec",
  null
  
)

export default component.exports