<template>
  <tbody class="no-td-borders ">
    <v-snackbar v-model="snackbar" :vertical="vertical" color="primary">
      {{ snackbarText }}
      <template>
        <div class="snackbar-close">
          <v-icon @click="closeSnackbar()">
            mdi-close
          </v-icon>
        </div>
      </template>
    </v-snackbar>

    <tr class="text-center no-border">
      <td>
        <div class="d-flex">
          <div class="pointer" @click="toggleOpen">
            <v-icon v-if="isOpen">
              mdi-arrow-up-drop-circle-outline
            </v-icon>
            <v-icon v-else>
              mdi-arrow-down-drop-circle-outline
            </v-icon>
          </div>
          <div class="ml-2">
            {{ item.variationId }}
          </div>
        </div>
      </td>
      <td>
        {{ consultants[0].area[0].name }}
      </td>
      <td class="text-left ">
        <div class="table-item-description">
          {{ getConsultantDisciplineById(consultants[0].classification.id) }}
        </div>
      </td>

      <td class="text-center">
        <div>
          <ButtonAdd
            v-if="!isCurrentTabTypeApproved()"
            :consultant="consultants[0]"
            :items="
              getConsultantsFromAreaByAreaTitle(consultants[0].area[0].name)
            "
            @onSubConsultantsAdd="onSubConsultantsAdd"
          />
        </div>
      </td>
      <td v-for="b in 4" :key="`b-${b}`" class="text-center">
        <v-btn
          rounded
          color="primary"
          height="29px"
          @click="openScopeDialog(b, consultants[0], item)"
        >
          Open
        </v-btn>
      </td>
      <td class="d-flex justify-end align-center">
        <div class="export-btn">
          <v-menu left bottom offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on">
                mdi-dots-vertical
              </v-icon>
            </template>

            <v-list class="dialog-actions-list">
              <div
                class="pointer dialog-actions-list__item"
                @click="onSendToWorkflow()"
              >
                Send for workflow approval
              </div>
              <div
                class="pointer dialog-actions-list__item"
                @click="onContractRemove(item.variationId)"
              >
                Remove
              </div>
              <div
                class="pointer dialog-actions-list__item"
                @click="onExportToPDF"
              >
                Export to PDF
              </div>
            </v-list>
          </v-menu>
        </div>
      </td>
    </tr>
    <template v-for="(consultantItem, i) in consultants">
      <tr
        v-if="i !== 0 && isOpen && !consultantItem.isSub"
        :key="`discipline-element-${i}`"
      >
        <td />
        <td>
          {{ consultantItem.area[0].name }}
        </td>
        <td class="text-left">
          <div class="table-item-description">
            {{ getConsultantDisciplineById(consultantItem.classification.id) }}
          </div>
        </td>
        <td class="text-center">
          <ButtonAdd
            v-if="!isCurrentTabTypeApproved()"
            :consultant="consultantItem"
            :items="
              getConsultantsFromAreaByAreaTitle(consultantItem.area[0].name)
            "
            @onSubConsultantsAdd="onSubConsultantsAdd"
          />
        </td>
        <td v-for="b in 4" :key="`b-${b}`" class="text-center">
          <v-btn
            rounded
            color="primary"
            height="29px"
            @click="openScopeDialog(b, consultantItem, item)"
          >
            Open
          </v-btn>
        </td>
        <!-- <td>
          <v-btn rounded color="primary">
            Add+
          </v-btn>
        </td> -->
        <td class="actions-row">
          <div class="export-btn">
            <v-menu left bottom offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on">
                  mdi-dots-vertical
                </v-icon>
              </template>

              <v-list class="dialog-actions-list">
                <div
                  class="pointer dialog-actions-list__item"
                  @click="onExportToPDF"
                >
                  Export to PDF
                </div>
              </v-list>
            </v-menu>
          </div>
        </td>
      </tr>
      <tr
        v-for="subConsultant in consultantItem.additionalClassification"
        :key="getUniqCode() + `${subConsultant.id}`"
      >
        <template v-if="isOpen">
          <td />
          <td />
          <td />
          <td class="d-flex remove-col-wrapper">
            <div class="table-item-description text-left d-flex ">
              <div class="sub-consultant-id">
                {{ getConsultantDisciplineById(subConsultant.id) }}
              </div>
              <div
                class="remove-icon pointer"
                @click="onRemove(consultantItem, subConsultant)"
              >
                <v-icon>
                  mdi-delete
                </v-icon>
              </div>
            </div>
          </td>
          <td v-for="b in 4" :key="`b-${b}`" class="text-center">
            <v-btn
              rounded
              color="primary"
              height="29px"
              @click="
                openScopeDialogSubConsultant(
                  b,
                  consultantItem,
                  item,
                  subConsultant
                )
              "
            >
              Open
            </v-btn>
          </td>
          <td class="actions-row">
            <div class="export-btn">
              <v-menu left bottom offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on">
                    mdi-dots-vertical
                  </v-icon>
                </template>

                <v-list class="dialog-actions-list">
                  <div
                    class="pointer dialog-actions-list__item"
                    @click="onExportToPDF"
                  >
                    Export to PDF
                  </div>
                </v-list>
              </v-menu>
            </div>
          </td>
        </template>
      </tr>
    </template>
  </tbody>
</template>
<script>
import ButtonAdd from "./ButtonAdd/ButtonAdd.vue";
import { uuid } from "vue-uuid";
import { getConsultantDisciplineById } from "@/utils/getValueFromJSONById/getValueFromJSONById";
/* eslint-disable vue/valid-v-for */
/* eslint-disable no-param-reassign */

export default {
  name: "VariationTableItem",
  components: { ButtonAdd },

  props: {
    item: Object,
    currentTab: String,
  },
  data: function() {
    return {
      isOpen: false,
      buttonTypes: ["Concept design", "", "Deleted scope", "Added scope"],
      uuid: uuid.v1(),
      snackbar: false,
      vertical: true,
      snackbarText: "",
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    consultants() {
      return this.item.consultants;
    },
    currentUser() {
      return this.$store.state.auth.currentUser;
    },
  },
  methods: {
    getConsultantDisciplineById,
    getConsultantsFromAreaByAreaTitle(areatitle) {
      return this.$store.getters["projectsModule/getProjectById"](
        this.id
      ).areas.find((a) => a.areaTitle === areatitle).consultants;
    },
    toggleOpen() {
      this.isOpen = !this.isOpen;
    },
    getUniqCode() {
      return uuid.v1();
    },
    onSendToWorkflow() {
      this.$store.commit("projectsModule/addProccessInstance", {
        id: this.id,
        instance: {
          endDate: null,
          history: [],
          initiator: {
            name: `${this.currentUser.firstName} ${this.currentUser.lastName}`,
            id: this.currentUser.id,
          },
          processDefinitionId: "002",
          startDate: `${new Date().toISOString()}`,
          status: "active",
          token: {
            activityId: uuid.v1(),
            startDate: `${new Date().toISOString()}`,
          },
          variables: [
            {
              id: "projectId",
              value: this.id,
            },
            {
              id: "areaId",
              value: this.item.id,
            },
          ],
        },
      });
      this.snackbar = true;
      this.snackbarText = `Area ${this.item.areaTitle} has been successfully sent for workflow approval`;
    },
    isCurrentTabTypeApproved() {
      return this.currentTab === "Approved scope";
    },
    onExportToPDF() {
      window.print();
    },
    closeSnackbar() {
      this.snackbar = false;
    },
    onSubConsultantsAdd(consultant, subConsultantsIds) {
      //Виконуємо операцію додаванння саб консультанта тут ,а не на рівні vuex
      console.log("consultant", consultant);
      console.log("subConsultantsIds", subConsultantsIds);
      // eslint-disable-next-line no-param-reassign
      subConsultantsIds.forEach((sc) => {
        // eslint-disable-next-line no-param-reassign
        consultant.additionalClassification = [
          ...consultant.additionalClassification,
          sc.classification,
        ];
        consultant.stages = [...consultant.stages, ...sc.stages];
        consultant.scopes = [...consultant.scopes, ...sc.scopes];
        this.item.implementation.milestones = [
          ...this.item.implementation.milestones,
          ...sc.milestones,
        ];
      });
    },
    onContractRemove(contractId) {
      this.$emit("onContractRemove", contractId);
    },
    onRemove(consultant, subConsultantClassification) {
      //Аналогічно операцію видалення виконуємо на рівні компоненти(у Vuex дані теж видаляться і сторінка перерендериться)

      consultant.additionalClassification = consultant.additionalClassification.filter(
        (ac) => ac.id !== subConsultantClassification.id
      );
      consultant.stages = consultant.stages.filter(
        (s) => s.classification.id !== subConsultantClassification.id
      );
      consultant.scopes = consultant.scopes.filter(
        (s) => s.classification.id !== subConsultantClassification.id
      );
      this.item.implementation.milestones = this.item.implementation.milestones.filter(
        (m) => m.classification.id !== subConsultantClassification.id
      );
    },
    openScopeDialogSubConsultant(id, item, area, newClassification) {
      let copy = JSON.parse(JSON.stringify(item));
      copy.classification = newClassification;
      console.log(copy);
      this.openScopeDialog(id, copy, area);
    },

    openScopeDialog(id, item, area) {
      let copyItem = JSON.parse(JSON.stringify(item));
      console.log("copyItem", copyItem);
      copyItem.milestones = this.item.implementation.milestones;
      if (id !== 2) {
        this.$emit("setCurrentScopeItemInDialog", {
          item,
          area,
          id,
        });
        this.$store.commit(
          "scopeOfWorksModule/setDocuments",
          this.item.implementation.documents
        );
      }
      if (id === 2) {
        this.$emit("openScopeDialog", id === 2 ? "setProgram" : "scope");
        this.$emit("setCurrentScopeItemInDialog", {
          item: copyItem,
          area,
          id,
          isDisabled: false,
        });
      }
    },
  },
};
</script>
<style scoped lang="scss">
.actions-row {
  display: flex !important;
  justify-content: flex-end !important;
}
.remove-col-wrapper {
  width: 250px;
  z-index: 5;
  display: flex;
  position: relative;
  top: 6px;
  justify-content: space-between;
  align-items: center;
}
.snackbar-close {
  position: absolute;
  top: 0px !important;
  right: 0px !important;
}
::v-deep td {
  overflow: hidden;
}
.sub-consultant-id {
  width: 122px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.remove-icon {
  // background: whitesmoke;
}
</style>
