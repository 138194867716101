<template>
  <div class="select-area-wrapper">
    <div class="select-area">
      <div class="filters-title">
        Filters:
      </div>
      <div class="d-flex ">
        <div class=" ml-2">
          <v-autocomplete
            :value="industryType"
            outlined
            label="Industry Type"
            class="min-height-select filters-item"
            :items="json.industryTypes"
            clearable
            return-object
            @change="onFieldChange($event, 'industryType')"
          />
        </div>
      </div>
      <div v-if="currentTab === 'My Framework'">
        <div class=" ml-2">
          <v-autocomplete
            label="Development Type"
            :value="developmentType"
            outlined
            class="min-height-select filters-item"
            :items="json.developmentTypes"
            clearable
            @change="onFieldChange($event, 'developmentType')"
          />
        </div>
      </div>
      <div class="d-flex">
        <div class=" ml-2">
          <v-autocomplete
            :value="contractType"
            outlined
            label="Contract Type"
            class="min-height-select filters-item"
            :items="json.contractTypes"
            clearable
            @change="onFieldChange($event, 'contractType')"
          />
        </div>
      </div>
      <div v-if="currentTab !== 'My Framework'" class="d-flex ">
        <div class=" ml-2">
          <v-autocomplete
            label="Discipline"
            :value="discipline"
            item-value="id"
            item-text="title"
            outlined
            class="min-height-select filters-item"
            :items="sampleData.precontraxDisciplines"
            clearable
            @change="onFieldChange($event, 'discipline')"
          />
        </div>
      </div>
    </div>

    <v-row class="mt-3">
      <v-col class="d-flex ">
        <div class="super-search-wrap">
          <v-text-field
            outlined
            label="Super search"
            :value="searchValue"
            class="min-height-select"
            @change="onFieldChange($event, 'searchValue')"
          />
        </div>
        <div class="btn-search-wrap pl-3">
          <v-btn
            height="25px"
            width="100px"
            color="primary"
            @click="handleSuperSearch"
          >
            Search
          </v-btn>
          <v-btn
            v-if="currentTab === 'My Suite' || currentTab === 'My Framework'"
            height="25px"
            width="120px"
            color="primary"
            class="btn-search-wrap ml-3"
            @click="onCreateNewScope"
          >
            Create new
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import json from "@/createNewProjectData.json";
import sampleData from "@/simpleData.json";

export default {
  name: "SearchBar",
  props: {
    currentTab: String,
    industryType: String,
    contractType: String,
    discipline: String,
    developmentType: String,
    searchValue: String,
  },
  data: function() {
    return {
      json: json,
      sampleData,
    };
  },
  methods: {
    onFieldChange(e, fieldName) {
      this.$emit("fieldChange", e, fieldName);
    },
    handleSuperSearch() {
      this.$emit("handleSuperSearch");
    },
    onCreateNewScope() {
      this.currentTab === "My Suite"
        ? this.$emit("onCreateNewScope")
        : this.$emit("onCreateNewFramework");
    },
  },
};
</script>
<style scoped lang="scss">
.filters-item {
  width: 240px;
}
.super-search-wrap {
  width: 770px;
  margin-left: 9px;
}
.btn-search-wrap {
  height: 64%;
  margin-left: 17px;
  display: flex;
}
.select-area-wrapper {
  padding-left: 50px;
  padding-right: 50px;
}
.select-area {
  display: flex;
  column-gap: 17px;
}
.filters-title {
  position: absolute;
  top: 22px;
  left: 16px;
}
::v-deep .v-menu__content,
.menuable__content__active,
.v-list-item__content,
.v-list-item__title {
  color: red;
}
::v-deep .v-list-item__title {
  max-width: 240px !important;
  text-align: left;
}
</style>
