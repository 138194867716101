<template>
  <div class="table-wrapper">
    <v-simple-table>
      <thead>
        <td
          v-for="(header, i) in appointedScopeHeaders
            ? appointedScopeHeaders
            : headers"
          :key="`scope-table-header-${i}`"
          :class="header.background && 'primary-background white--text'"
          class="table-header"
        >
          <div class="table-item-wrapper">
            <div v-html="header.title" />
            <div v-if="header.tooltip" class="ml-1">
              <v-tooltip top color="black">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on">
                    mdi-help-circle-outline
                  </v-icon>
                </template>
                <span v-html="header.tooltip" />
              </v-tooltip>
            </div>
          </div>
        </td>
      </thead>
      <template v-if="contracts && contracts.length > 0">
        <template v-for="(item, i) in contracts">
          <AppointedScopeTableItem
            v-if="appointedScopeHeaders && !item.variationId"
            :key="`table-scope-item-${i}`"
            :item="item"
            @setCurrentScopeItemInDialog="setCurrentScopeItemInDialog"
            @openScopeDialog="openScopeDialog"
            @onVariationCreate="onVariationCreate"
          />
          <VariationTableItem
            v-if="currentTab === 'Variations' && !!item.variationId"
            :key="`table-scope-item-${i}`"
            :item="item"
            @onContractRemove="onContractRemove"
            @setCurrentScopeItemInDialog="setCurrentScopeItemInDialog"
            @openScopeDialog="openScopeDialog"
          />
        </template>
      </template>
    </v-simple-table>
  </div>
</template>
<script>
import VariationTableItem from "../ScopeTable/components/VariationTableItem.vue";
import AppointedScopeTableItem from "../ScopeTable/components/AppointedScopeTableItem.vue";
export default {
  name: "AppointedScopeTable",
  components: {
    AppointedScopeTableItem,
    AppointedScopeTableItem,
    VariationTableItem,
  },
  props: {
    headers: Array,
    currentTab: String,
    items: Array,
    appointedScopeHeaders: Array,
  },
  computed: {
    contracts() {
      let contracts = [];
      this.items?.forEach((i) => {
        i.contracts?.forEach((c) => {
          contracts.push(c);
        });
      });
      return contracts;
    },
    variations() {
      return this.contracts.filter((c) => {
        if (c.variationId) {
          return true;
        }
      });
    },
    id() {
      return this.$route.params.id;
    },
  },
  methods: {
    openScopeDialog(val) {
      this.$emit("openScopeDialog", val);
    },
    setCurrentScopeItemInDialog(val) {
      this.$emit("setCurrentScopeItemInDialog", val);
    },
    onContractRemove(id) {
      console.log(id);
      this.$store.commit("projectsModule/removeVariation", {
        projectId: this.id,
        variationId: id,
      });
      // this.contracts = this.contracts.filter((c) => {
      //   if (c.variationId && c.variationId === id) {
      //     return false;
      //   } else {
      //     return true;
      //   }
      // });
    },

    onVariationCreate(item) {
      // eslint-disable-next-line no-param-reassign
      console.log("var to create", item);

      // eslint-disable-next-line no-param-reassign
      item.variationId =
        this.variations.length < 10
          ? `VA-00${this.variations.length + 1}`
          : `VA-0${this.variations.length + 1}`;

      this.contracts.push(item);
      this.$store.commit("projectsModule/addVariation", {
        projectId: this.id,
        variation: item,
      });

      this.$emit("setCurrentTab", "Variations");
    },
  },
};
</script>
<style scoped lang="scss">
::v-deep td {
  padding: 10px;
}

.table-header {
  font-size: 14px;
  min-height: 38px;
}
.table-item-wrapper {
  display: flex;
  min-height: 44px;
  justify-content: center;
  align-items: center;
}
::v-deep table {
  table-layout: fixed !important;
}
</style>
