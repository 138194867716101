/* eslint-disable vue/html-closing-bracket-newline */
<template>
  <div class="dialog">
    <div class="dialog__title">
      {{
        getConsultantDisciplineById(
          innerCurrentScopeItemInDialog.classification.id
        )
      }}
    </div>
    <div class="mt-4 dialog__content">
      <div>
        <div class="items-row">
          <div sm="2" class="milestone-title" />
          <div class="centered-col">
            Start
          </div>
          <div sm="1" class="centered-col">
            Finish
          </div>
          <div sm="1" class="centered-col">
            Actual Start
          </div>
          <div sm="1" class="centered-col">
            Projected Finish
          </div>
          <div sm="1" class="centered-col">
            Actual Finish
          </div>
          <div sm="1" class="centered-col">
            Approval to proceed
          </div>
        </div>
        <div class="mt-4">
          <template v-for="(stage, s) in filteredStages" class="d-flex">
            <div :key="`set-program-dialog-${s}`" class="items-row">
              <div class="d-flex align-center pt-0 pb-0 milestone-title">
                {{ s + 1 }}. {{ getStageTypeById(stage.type) }}
              </div>

              <div sm="1" class="centered-col pt-0 pb-0 inp-item">
                <DatePicker
                  :disabled="isAllItemsDisabled || isTwoFirstColumsDisabled"
                  label="Date"
                  :value.sync="getMilestonesByStageId(stage.id)[0].dateMet"
                />
              </div>

              <div sm="1" class="centered-col pt-0 pb-0 inp-item">
                <DatePicker
                  :disabled="isAllItemsDisabled || isTwoFirstColumsDisabled"
                  label="Date"
                  :value.sync="getMilestonesByStageId(stage.id)[1].dateMet"
                />
              </div>

              <div sm="1" class="centered-col pt-0 pb-0 inp-item">
                <DatePicker
                  :disabled="isAllItemsDisabled"
                  label="Date"
                  :value.sync="getMilestonesByStageId(stage.id)[0].dueDate"
                />
              </div>
              <div sm="1" class="centered-col pt-0 pb-0 inp-item">
                <DatePicker
                  label="Date"
                  disabled
                  :value="
                    calcProjectedFinish({
                      start: getMilestonesByStageId(stage.id)[0].dateMet,
                      finish: getMilestonesByStageId(stage.id)[1].dateMet,
                      actualStart: getMilestonesByStageId(stage.id)[0].dueDate,
                    })
                  "
                />
              </div>
              <div sm="1" class="centered-col pt-0 pb-0 inp-item">
                <DatePicker
                  :disabled="isAllItemsDisabled"
                  label="Date"
                  :value.sync="getMilestonesByStageId(stage.id)[1].dueDate"
                />
              </div>
              <div sm="1" class="centered-col pt-0 pb-0 inp-item">
                <DatePicker
                  :disabled="isAllItemsDisabled"
                  label="Date"
                  :value.sync="getMilestonesByStageId(stage.id)[2].dateMet"
                />
              </div>
            </div>
          </template>
        </div>
      </div>
      <div class="actions d-flex justify-end">
        <v-btn color="primary" class="d-flex" @click="onExportToPDF">
          <div>
            Export to PDF
          </div>
          <v-icon>
            mdi-play-circle
          </v-icon>
        </v-btn>
      </div>
    </div>
    <div class="dialog__actions">
      <v-btn width="130" height="45" @click="onClose">
        Cancel
      </v-btn>
      <v-btn color="primary" width="130" height="45" @click="onSave">
        Save
      </v-btn>
    </div>
  </div>
</template>
<script>
import DatePicker from "../../../../components/DatePicker/DatePicker.vue";
import { getStageTypeById } from "@/utils/getValueFromJSONById/getValueFromJSONById";
import { getConsultantDisciplineById } from "@/utils/getValueFromJSONById/getValueFromJSONById";

export default {
  name: "SetProgramDialog",
  components: { DatePicker, DatePicker },
  props: {
    currentScopeItemInDialog: {
      type: Object,
      default: () => null,
    },
    isAppointed: {
      type: Boolean,
      default: false,
    },
    isAllItemsDisabled: {
      type: Boolean,
      default: false,
    },
    isTwoFirstColumsDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data: function() {
    return {
      innerCurrentScopeItemInDialog: null,
    };
  },
  computed: {
    currentScopeOfWorksPageData() {
      return this.$store.state.scopeOfWorksModule.pageData.dialog;
    },
    filteredStages() {
      return this.innerCurrentScopeItemInDialog.stages.filter((s) => {
        return (
          s.classification.id ===
          this.currentScopeItemInDialog.classification.id
        );
      });
    },
  },
  watch: {
    currentScopeItemInDialog() {
      this.innerCurrentScopeItemInDialog = this.currentScopeItemInDialog;
    },
  },

  created() {
    this.innerCurrentScopeItemInDialog = this.currentScopeItemInDialog;
  },

  methods: {
    getConsultantDisciplineById,
    getStageTypeById,
    calcProjectedFinish({ start, finish, actualStart }) {
      if (start && finish && actualStart) {
        let diffBetweenStartAndFinish = new Date(finish) - new Date(start);
        let result = new Date(
          new Date(actualStart).valueOf() + diffBetweenStartAndFinish
        );
        return result.toISOString().slice(0, 10);
      }
    },

    onSave() {
      this.$store.commit("projectsModule/editScope", {
        projectId: this.$route.params.id,
        areaTitle: this.currentScopeOfWorksPageData.currentArea,
        consultantId: this.currentScopeOfWorksPageData.consultrant,
        newScope: this.innerCurrentScopeItemInDialog,
      });
      this.$emit("closeDialog");
    },
    onDatePickerValChange(val) {},
    onClose() {
      this.$emit("closeDialog");
    },
    onExportToPDF() {
      window.print();
    },
    getMilestonesByStageId(stageId) {
      // console.log("stageId", stageId);

      return this.innerCurrentScopeItemInDialog.milestones.filter((m) => {
        return (
          m.stageId === stageId &&
          m.classification.id ===
            this.currentScopeItemInDialog.classification.id
        );
      });
    },
  },
};
</script>
<style scoped lang="scss">
.milestone-title {
  width: 200px;
}
.dialog {
  min-height: 916px;
  background: white;
  &__title {
    text-transform: uppercase;
    min-height: 95px;
    width: 100%;
    background-color: var(--v-primary-base) !important;
    display: flex;
    color: white;
    padding-left: 50px;
    align-items: center;
  }
  &__content {
    padding-left: 49px;
    padding-right: 49px;
  }
  &__actions {
    margin-top: 60px;
    column-gap: 10px;
    display: flex;
    justify-content: flex-end;
    padding-left: 49px;
    padding-right: 49px;
  }
}
.data {
}
.items-row {
  display: flex;
  column-gap: 10px;
  text-align: left;
}
.centered-col {
  display: flex;
  width: 100px;
  /* justify-content: center; */
  text-align: left;
}
::v-deep .v-label {
  font-size: 13px !important;
}
.inp-item {
  position: relative;
  top: 16px;
}
</style>
