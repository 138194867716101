<template>
  <div>
    <v-dialog v-model="isScopeDialogOpen" width="1500">
      <div class="pos-relative">
        <div class="icon-close-wrapper pointer" @click="toogleScopeModalOpen()">
          <v-icon>
            mdi-close
          </v-icon>
        </div>
        <ScopeDialog />
      </div>
    </v-dialog>
    <h3 class="card-title">
      RECENT ACTIVITIES
    </h3>
    <div class="content">
      <div class="d-flex justify-space-between">
        <div class="d-flex">
          <div class="select-small">
            <v-select
              v-model="selectValue"
              open-on-clear
              :items="months"
              label="Month"
              outlined
              class="min-height-select small-select"
              :menu-props="{ offsetY: true }"
              clearable
            />
          </div>
          <div class="select-small ml-1">
            <v-text-field
              v-model="searchValue"
              outlined
              class="min-height-select"
              label="Search"
            />
          </div>
        </div>

        <div>
          <v-btn
            color="primary"
            dark
            height="25px"
            width="120px"
            @click="exportToPDF()"
          >
            Export
            <v-icon class="ml-3">
              mdi-play-circle
            </v-icon>
          </v-btn>
        </div>
      </div>
      <div
        v-for="(aviableMonth, a) in aviableMonths"
        :key="`aviable month - ${a}`"
      >
        <v-row v-if="aviableMonth === selectValue || !selectValue" class="mt-2">
          <v-col sm="12">
            <h4 class="d-flex">{{ aviableMonth }} 2021</h4>
          </v-col>
        </v-row>
        <v-row
          v-for="(activity, i) in getActivitiesByAviableMonths(
            filtredItems,
            aviableMonth
          )"
          :key="`activity - ${i}`"
        >
          <v-col sm="2">
            {{ activity.day + " " + activity.month }}
            <!-- 1 October -->
          </v-col>
          <v-col sm="8">
            {{ activity.text }}
          </v-col>
          <v-col sm="2">
            <v-btn
              rounded
              color="primary"
              height="29px"
              @click="toogleScopeModalOpen()"
            >
              View
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>
<script>
import ScopeDialog from "../../../ScopeOfWorks/components/ScopeDialog/ScopeDialog.vue";
export default {
  name: "RecentActivities",
  components: { ScopeDialog },
  data: function() {
    return {
      isScopeDialogOpen: false,
      searchValue: "",
      selectValue: "",

      months: [
        "Junuary",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      activities: [
        {
          day: "2",
          month: "April",
          text: "Completion of interior design 02 concept design",
        },
        {
          day: "1",
          month: "April",
          text: "Completion of interior design 01 concept design",
        },
        {
          day: "2",
          month: "March",
          text: "Completion of interior design 02 concept design",
        },
      ],
    };
  },
  computed: {
    aviableMonths() {
      return new Set(this.activities.map((a) => a.month));
    },
    filtredItems() {
      return this.activities
        .filter((item) => {
          if (!this.selectValue) {
            return item.text
              .toLowerCase()
              .includes(this.searchValue.toLowerCase());
          } else {
            return (
              item.text
                .toLowerCase()
                .includes(this.searchValue.toLowerCase()) &&
              item.month === this.selectValue
            );
          }
        })
        .sort((a, b) => (a.month > b.month ? 1 : b.month > a.month ? -1 : 0));
    },
  },
  methods: {
    exportToPDF() {
      window.print();
    },
    getActivitiesByAviableMonths(activities, month) {
      return activities.filter((a) => {
        return a.month === month;
      });
    },

    toogleScopeModalOpen() {
      this.isScopeDialogOpen = !this.isScopeDialogOpen;
    },
  },
};
</script>
<style scoped lang="scss">
.content {
  margin-top: 20px;
  font-size: 14px;
}
</style>
