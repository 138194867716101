export default {
  namespaced: true,
  state: {
    workflows: [],
  },
  mutations: {
    setWorkflows(state, workflows) {
      state.workflows = workflows;
    },
  },
};
