export default {
  minLength(min) {
    return function (v) {
      return v.length >= min || `Field must contain at least ${min} characters`;
    };
  },
  required(v) {
    return !!v || 'Field is required';
  },
  email(v) {
    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return pattern.test(v) || 'Value should be a valid email address';
  },
}