<template>
  <v-row>
    <v-col sm="12">
      <v-simple-table>
        <thead>
          <tr>
            <th
              v-for="(header, h) in headers"
              :key="`table-headers-area ${h}`"
              class="text-left"
            >
              <div
                class="header-wrapper"
                :class="header !== 'Area' && 'rotated'"
              >
                {{ header !== "Area" ? getConsultantCodeById(header) : header }}
              </div>
            </th>
          </tr>
        </thead>
        <tbody v-if="items && items.length > 0">
          <tr
            v-for="(item, i) in items"
            :key="`table-items-area ${i}`"
            class="text-left"
          >
            <td>
              <div class="d-flex align-center">
                <!-- <v-checkbox /> -->
                {{ item.areaTitle }}
              </div>
            </td>
            <td
              v-for="(testItem, i) in getItrableItems(item)"
              :key="`test -dsaddasasdada ${i}`"
            >
              <v-icon v-if="testItem">
                mdi-check
              </v-icon>
              <div v-else>
                -
              </div>
            </td>
            <td>
              <v-tooltip color="black" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <img
                    v-bind="attrs"
                    class="export-pdf-icon"
                    src="/img/icons/export-pdf.svg"
                    v-on="on"
                  />
                </template>
                <span>Export report to PDF</span>
              </v-tooltip>
            </td>
          </tr>
        </tbody>
        <div v-else class="no-data-message">
          There is no data yet
        </div>
      </v-simple-table>
    </v-col>
  </v-row>
</template>
<script>
import CSelect from "@/components/c-select/c-select.vue";
import {
  getUniqueConsultants,
  getConsultantAbbreviation,
} from "@/utils/getUniqueConsultants/getUniqueConsultants";
import {
  getStageCodeByType,
  getConsultantDisciplineById,
  getConsultantCodeById,
} from "@/utils/getValueFromJSONById/getValueFromJSONById";
export default {
  name: "AreaTab",
  components: {
    // "c-select": CSelect,
  },
  props: ["items"],
  data: function() {
    return {
      actions: ["Export report"],
    };
  },
  computed: {
    headers() {
      if (this.items) {
        return this.getUniqueConsultants(this.items);
      } else {
        return [];
      }
    },
  },
  methods: {
    getUniqueConsultants,
    getConsultantDisciplineById,
    getConsultantCodeById,
    getItrableItems(area) {
      let consultantsList = area.consultants.map((c) => c.classification.id);
      let subConsultantsList = [];
      area.consultants.forEach((c) => {
        c.additionalClassification.forEach((ac) => {
          consultantsList.push(ac.id);
        });
      });

      return this.headers.slice(1).map((h) => {
        if (consultantsList.indexOf(h) === -1) {
          return false;
        } else {
          return true;
        }
      });

      // return area.consultants.map((c, i) => {
      //   // console.log("c", c.discipline);
      //   // console.log("i", i);
      //   console.log("h", this.headers);

      //   return c;
      // });
    },
  },
};
</script>
<style scoped lang="scss">
.header-wrapper {
  &.rotated {
    position: absolute;
    transform: rotate(338deg);
  }
}
.export-pdf-icon {
  width: 24px;
  height: 24px;
  object-fit: contain;
  opacity: 0.54;
  &:hover {
    cursor: pointer;
    opacity: 0.85;
  }
}
.no-data-message {
  position: absolute;
  left: 0px;
  right: 0px;
  color: grey;

  top: 215px;
}
</style>
