<template>
  <div class="control-room">
    <v-dialog v-model="isPaymentOpen" width="800">
      <div class="pos-relative">
        <div class="icon-close-wrapper pointer" @click="tooglePaymentModal()">
          <v-icon>
            mdi-close
          </v-icon>
        </div>
        <Payment
          :amount="totalAmount"
          @onBuySuite="onBuySuite"
          @onCancel="onCancelBtnClick"
        />
      </div>
    </v-dialog>
    <v-dialog v-model="isCreateNewScopeOpen" width="800">
      <div class="pos-relative">
        <div
          class="icon-close-wrapper pointer"
          @click="toogleCreateNewScopeModal()"
        >
          <v-icon>
            mdi-close
          </v-icon>
        </div>
        <CreateNewScope @onBtnContinueClick="onBtnContinueClick" />
      </div>
    </v-dialog>
    <v-dialog v-model="isScopeDialogOpen" width="1600">
      <div class="pos-relative">
        <div class="icon-close-wrapper pointer" @click="toogleScopeModalOpen()">
          <v-icon>
            mdi-close
          </v-icon>
        </div>
        <ScopeDialog
          :previewed-scope="previewedScope"
          :current-view="currentView"
          :is-edit-mode="isEditModeInWScopeActive"
          @toggleEditMode="toggleEditMode"
          @toggleDialog="closeScopeDialog"
        />
      </div>
    </v-dialog>

    <v-dialog v-model="isCreateNewFrameworkOpen" width="750">
      <div class="pos-relative">
        <div
          class="icon-close-wrapper pointer"
          @click="toggleCreateNewFramewokModal()"
        >
          <v-icon>
            mdi-close
          </v-icon>
        </div>
        <div>
          <CreateNewFrameworkDialog @toggle="toggleCreateNewFramewokModal" />
        </div>
      </div>
    </v-dialog>
    <div>
      <div class="tab-list">
        <div class="d-flex flex-wrap">
          <div
            v-for="(tab, i) in tabs"
            :key="`control-room-tab-${i}`"
            class="tab-item"
            :class="currentTab === tab && 'tab-item_active'"
            @click="setCurrentTab(tab)"
          >
            {{ tab }}
          </div>
        </div>
        <div v-if="isSpinnerActive" class="spinner-loading">
          <v-progress-circular
            indeterminate
            color="primary"
            :size="400"
            class="spinner-ico"
          />
        </div>
        <div
          class="main-content"
          :class="currentTab === 'My Framework' && 'my-framework-view'"
        >
          <div class="content">
            <v-col sm="12">
              <SearchBar
                :current-tab="currentTab"
                :industry-type="industryType"
                :development-type="developmentType"
                :discipline="discipline"
                :contract-type="contractType"
                :search-value="searchValue"
                @fieldChange="onFilterChange"
                @onCreateNewScope="onCreateNewScope"
                @onCreateNewFramework="toggleCreateNewFramewokModal"
                @handleSuperSearch="handleSuperSearch"
              />
              <div v-if="currentTab === 'Master Suite'" class="tab-conten">
                <ControlRoomTable
                  :headers="masterSuiteHeaders"
                  :items="withSearch(scopes)"
                  :preview="'master-suite'"
                  @addToOrder="addItemToOrder"
                  @onPreviewButtonClick="onPreviewButtonClick"
                />
              </div>
              <div v-if="currentTab === 'My Suite'" class="tab-conten">
                <ControlRoomTable
                  :headers="mySuiteHeaders"
                  :items="withSearch(myScopes)"
                  :preview="`my-scope`"
                  @onPreviewButtonClick="onPreviewButtonClick"
                />
              </div>
              <div v-if="currentTab === 'My Framework'" class="tab-conten">
                <MyFrameworkTable
                  :headers="myFrameworkHeaders"
                  :items="withSearch(myFrameworks)"
                  @onPreviewButtonClick="onPreviewButtonClick"
                />
              </div>
            </v-col>
          </div>
          <div v-if="currentTab === 'Master Suite'">
            <OrderSummary
              :orders="selectedItems"
              @deleteFromOrder="deleteFromOrder"
              @onPay="onPay"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CreateNewFrameworkDialog from "../../components/createNewFramewokDialog/CreateNewFrameworkDialog.vue";
import CreateNewScope from "./components/CreateNewScope/CreateNewScope.vue";
import Payment from "../../components/payment/Payment.vue";
import ScopeDialog from "../ScopeOfWorks/components/ScopeDialog/ScopeDialog.vue";
import OrderSummary from "./components/OrderSummary/OrderSummary.vue";
import MyFrameworkTable from "./components/MyFrameworkTable/MyFrameworkTable.vue";
import ControlRoomTable from "./components/ControlRoomTable/ControlRoomTable.vue";
import SearchBar from "./components/SearchBar/SearchBar.vue";
import {
  multiplieSelectFilter,
  itemsFilter,
} from "@/utils/itemsFilter/itemsFilter";

export default {
  name: "ControlRoom",
  components: {
    SearchBar,
    ControlRoomTable,
    MyFrameworkTable,
    OrderSummary,
    ScopeDialog,
    Payment,
    CreateNewScope,
    CreateNewFrameworkDialog,
  },
  data: function() {
    return {
      tabs: ["Master Suite", "My Suite", "My Framework"],
      selectedItems: [],
      previewedScope: null,
      isEditModeInWScopeActive: false,
      isPaymentOpen: false,
      isScopeDialogOpen: false,
      isSpinnerActive: false,
      isCreateNewScopeOpen: false,
      isCreateNewFrameworkOpen: false,
      industryType: "",
      contractType: "",
      discipline: "",
      developmentType: "",
      searchValue: "",
      currentView: "",
      totalAmount: 0,
      isButtonSearchClicked: false,
      scopesBeforeSearch: [],
      currentTab: "Master Suite",
      masterSuiteHeaders: [
        "Industry Type",
        // "Development Type",
        "Contract Type",
        "Discipline",
        "Cost",
      ],

      mySuiteHeaders: [
        "Industry Type",
        // "Development Type",
        "Contract Type",
        "Discipline",
        "",
      ],

      myFrameworkHeaders: [
        "Development Type",
        "Contract Type",
        "Allocated",
        "My Suite",
      ],
      myFrameworkItems: [
        {
          developmentType: "Hotel",
          contractType: "Traditional",
          consultatntListCount: 7,
          mySyuiteCount: 13,
        },
      ],

      searchFields: [
        "contractType",
        "developmentType",
        "classification",
        "industryType",
      ],
    };
  },
  computed: {
    scopes() {
      return multiplieSelectFilter(
        this.$store.state.controlRoomModule.scopes,
        this.searchFields,
        this.getSearchVariblesObject()
      );
    },
    myScopes() {
      return multiplieSelectFilter(
        this.$store.state.controlRoomModule.myScopes,
        this.searchFields,
        this.getSearchVariblesObject()
      );
    },
    myFrameworks() {
      return multiplieSelectFilter(
        this.$store.state.myFrameworkModule.frameworks,
        this.searchFields,
        this.getSearchVariblesObject()
      );
    },
  },
  methods: {
    setCurrentTab(tab) {
      this.industryType = "";
      this.developmentType = "";
      this.contractType = "";
      this.discipline = "";
      this.searchValue = "";
      this.currentTab = tab;
    },
    //filters logic
    getSearchVariblesObject() {
      return {
        contractType: this.contractType ? this.contractType.toLowerCase() : "",
        developmentType: this.developmentType
          ? this.developmentType.toLowerCase()
          : "",
        classification: {
          id: this.discipline ? this.discipline : "",
        },
        industryType: this.industryType ? this.industryType.toLowerCase() : "",
      };
    },
    onFilterChange(value, field) {
      this[field] = value;
    },
    handleSuperSearch() {
      this.isButtonSearchClicked = true;
    },
    withSearch(items) {
      if (this.isButtonSearchClicked && this.searchValue !== "") {
        return itemsFilter(items, this.searchFields, this.searchValue);
      } else {
        return items;
      }
    },
    ///

    //scope preview
    toggleEditMode() {
      this.isEditModeInWScopeActive = !this.isEditModeInWScopeActive;
    },
    onPreviewButtonClick(previewedScope, currentView) {
      this.previewedScope = previewedScope;
      this.currentView = currentView;
      this.$store.commit(
        "scopeOfWorksModule/setCurrentDialog",
        this.previewedScope.stages[0].type
      );

      this.toogleScopeModalOpen();
    },
    closeScopeDialog() {
      this.isScopeDialogOpen = false;
    },
    toogleScopeModalOpen() {
      this.isScopeDialogOpen = !this.isScopeDialogOpen;
      this.isEditModeInWScopeActive = false;
    },

    //Create new scope ----------------
    toogleCreateNewScopeModal() {
      this.isCreateNewScopeOpen = !this.isCreateNewScopeOpen;
    },
    toggleCreateNewFramewokModal() {
      this.isCreateNewFrameworkOpen = !this.isCreateNewFrameworkOpen;
    },

    onCreateNewScope() {
      this.toogleCreateNewScopeModal();
    },

    onCreateNewFramewrok() {},
    onBtnContinueClick(newScope) {
      this.previewedScope = newScope;
      this.toogleCreateNewScopeModal();
      this.toogleScopeModalOpen();

      this.isEditModeInWScopeActive = true;
    },
    //------------------------------------------
    ///
    //orders  logic
    addItemToOrder(item) {
      this.selectedItems.push(item);
    },
    deleteFromOrder(id) {
      this.selectedItems = this.selectedItems.filter((o, i) => i !== id);
    },
    onPay(amount) {
      this.totalAmount = amount;
      this.tooglePaymentModal();
    },
    tooglePaymentModal() {
      this.isPaymentOpen = !this.isPaymentOpen;
    },

    onBuySuite() {
      let copySelected = JSON.parse(JSON.stringify(this.selectedItems));

      this.$store.commit("controlRoomModule/setMyScopes", [
        ...this.$store.state.controlRoomModule.myScopes,
        ...copySelected.map((si) => {
          // eslint-disable-next-line no-param-reassign
          si.id = si.id.slice(7);
          return si;
        }),
      ]);
      this.selectedItems.forEach((si) => {
        let result = this.$store.state.myFrameworkModule.frameworks.filter(
          (item) => {
            return (
              item.contractType === si.contractType &&
              item.developmentType === si.developmentType &&
              item.industryType === si.industryType
            );
          }
        );
      });

      this.$store.state.myFrameworkModule.frameworks;

      this.tooglePaymentModal();
      this.currentTab = "My Suite";
      this.selectedItems = [];
      this.isSpinnerActive = true;
      setTimeout(() => {
        this.isSpinnerActive = false;
      }, 3000);
    },
    onCancelBtnClick() {
      this.tooglePaymentModal();
    },
    ///
  },
};
</script>
<style scoped lang="scss">
.control-room {
  display: grid;
}
.main-content {
  display: grid;
  grid-template-columns: 2fr 1fr;
  column-gap: 10px;
  &.my-framework-view {
    height: 600px;
    grid-template-columns: 1679px;
  }
  .content {
    position: relative;
    padding: 10px;
    padding-right: 0px !important;
    padding-left: 0px !important;
    border: 1px solid #929da3;
  }
}

.tab-item {
  background: whitesmoke;
  padding: 20px;
  cursor: pointer;
  min-width: 200px;
  height: 64px;
  text-align: center;
  &:not(:last-of-type) {
    margin-right: 10px;
  }
  &_active {
    background: #5085a5;
    color: white;
  }
}

.tab-conten {
  padding-top: 24px;
}
.spinner-loading {
  position: absolute;
  top: -3px;
  z-index: 10;
  bottom: 0px;
  left: 0px;
  right: 0px;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}
.spinner-ico {
  position: relative;
  top: 200px;
}
</style>
