export const generateMiletones = (classification) => {
  return [
    {
      title: "Preparation & Briefing",
      type: "initiation",
      dueDate: "",
      dateMet: "",
      status: "scheduled",
      stageId: "PB",
      classification,
    },
    {
      title: "Preparation & Briefing",
      type: "delivery",
      dueDate: "",
      dateMet: "",
      status: "scheduled",
      stageId: "PB",
      classification,
    },
    {
      title: "Preparation & Briefing",
      type: "approval",
      dateMet: "",
      status: "scheduled",
      stageId: "PB",
      classification,
    },
    {
      title: "Concept Design",
      type: "initiation",
      dueDate: "",
      dateMet: "",
      status: "scheduled",
      stageId: "CD",
      classification,
    },
    {
      title: "Concept Design",
      type: "delivery",
      dueDate: "",
      dateMet: "",
      status: "scheduled",
      stageId: "CD",
      classification,
    },
    {
      title: "Concept Design",
      type: "approval",
      dateMet: "",
      status: "scheduled",
      stageId: "CD",
      classification,
    },
    {
      title: "Schematic Design",
      type: "initiation",
      dueDate: "",
      dateMet: "",
      status: "scheduled",
      stageId: "SD",
      classification,
    },
    {
      title: "Schematic Design",
      type: "delivery",
      dueDate: "",
      dateMet: "",
      status: "scheduled",
      stageId: "SD",
      classification,
    },
    {
      title: "Schematic Design",
      type: "approval",
      dateMet: "",
      status: "scheduled",
      stageId: "SD",
      classification,
    },
    {
      title: "Detailed Design",
      type: "initiation",
      dueDate: "",
      dateMet: "",
      status: "scheduled",
      stageId: "DD",
      classification,
    },
    {
      title: "Detailed Design",
      type: "delivery",
      dueDate: "",
      dateMet: "",
      status: "scheduled",
      stageId: "DD",
      classification,
    },
    {
      title: "Detailed Design",
      type: "approval",
      dateMet: "",
      status: "scheduled",
      stageId: "DD",
      classification,
    },
    {
      title: "Issued For Tender",
      type: "initiation",
      dueDate: "",
      dateMet: "",
      status: "scheduled",
      stageId: "IFT",
      classification,
    },
    {
      title: "Issued For Tender",
      type: "delivery",
      dueDate: "",
      dateMet: "",
      status: "scheduled",
      stageId: "IFT",
      classification,
    },
    {
      title: "Issued For Tender",
      type: "approval",
      dateMet: "",
      status: "scheduled",
      stageId: "IFT",
      classification,
    },
    {
      title: "Tender Services",
      type: "initiation",
      dueDate: "",
      dateMet: "",
      status: "scheduled",
      stageId: "TS",
      classification,
    },
    {
      title: "Tender Services",
      type: "delivery",
      dueDate: "",
      dateMet: "",
      status: "scheduled",
      stageId: "TS",
      classification,
    },
    {
      title: "Tender Services",
      type: "approval",
      dateMet: "",
      status: "scheduled",
      stageId: "TS",
      classification,
    },
    {
      title: "Issued For Construction",
      type: "initiation",
      dueDate: "",
      dateMet: "",
      status: "scheduled",
      stageId: "IFC",
      classification,
    },
    {
      title: "Issued For Construction",
      type: "delivery",
      dueDate: "",
      dateMet: "",
      status: "scheduled",
      stageId: "IFC",
      classification,
    },
    {
      title: "Issued For Construction",
      type: "approval",
      dateMet: "",
      status: "scheduled",
      stageId: "IFC",
      classification,
    },
    {
      title: "Post Contract Services",
      type: "initiation",
      dueDate: "",
      dateMet: "",
      status: "scheduled",
      stageId: "PC",
      classification,
    },
    {
      title: "Post Contract Services",
      type: "delivery",
      dueDate: "",
      dateMet: "",
      status: "scheduled",
      stageId: "PC",
      classification,
    },
    {
      title: "Post Contract Services",
      type: "approval",
      dateMet: "",
      status: "scheduled",
      stageId: "PC",
      classification,
    },
  ];
};
