<template>
  <div class="dialog">
    <div class="dialog__title">
      <div v-if="step === 2">{{ areaType }} Consultants</div>
      <div v-if="step === 1">
        CREATE NEW AREA
      </div>
      <div v-if="step === 3">
        ADD discipline
      </div>
    </div>
    <div class="dialog__content">
      <AreaFirstStep
        v-if="step === 1"
        :area-type="areaType"
        :area-title="areaTitle"
        :location-tag="locationTag"
        :allocate-sqm-budget="allocateSqmBudget"
        :area="area"
        :files="files"
        @setFiles="setFiles"
        @onFieldChange="onFieldChange"
      />
      <AreaSecondStep
        v-if="step === 2"
        :area-type="areaType"
        :items="items"
        :items-to-remove="itemsToRemove"
        :items-to-add="itemsToAdd"
        @setItemsToRemove="setItemsToRemove"
        @onDisciplinesAddBtnClick="onDisciplinesAddBtnClick"
      />
      <AreaAddDisciplines
        v-if="step === 3"
        ref="addDisciplines"
        :current-developmemnt-type="areaType"
        :items="items"
        :items-to-add="itemsToAdd"
        @setItemsToAdd="setItemsToAdd"
      />
    </div>

    <div class="dialog_buttons">
      <v-btn width="130px" height="45px" @click="backToPrevStep">
        Cancel
      </v-btn>

      <v-btn
        color="primary"
        width="130px"
        height="45px"
        :disabled="canContinue"
        @click="nextStep()"
      >
        {{ step === 2 ? "Save" : step === 3 ? "Add" : "Continue" }}
      </v-btn>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-param-reassign */
import AreaAddDisciplines from "./components/Steps/AreaAddDisciplines.vue";
import AreaSecondStep from "./components/Steps/AreaSecondStep.vue";
import AreaFirstStep from "./components/Steps/AreaFirstStep.vue";
import {
  getConsultantDisciplineById,
  getStageTypeByTitle,
} from "@/utils/getValueFromJSONById/getValueFromJSONById";
import { generateMiletones } from "@/utils/generateMilestonesForConsultant/index";
import { uuid } from "vue-uuid";

export default {
  name: "DialogContent",
  components: { AreaFirstStep, AreaSecondStep, AreaAddDisciplines },
  data: function() {
    return {
      step: 1,
      areaType: "",
      areaTitle: "",
      locationTag: "",
      allocateSqmBudget: "",
      area: "",
      uuid: uuid.v1(),

      files: [],
      itemsToRemove: [],
      itemsToAdd: [],
    };
  },

  inject: ["toggleDialog"],
  computed: {
    items: {
      get: function() {
        return this.$store.getters["myFrameworkModule/getAllocatedByAreaType"](
          this.areaType
        );
      },
      set: function(val) {
        this.items = val;
      },
    },
    id() {
      return this.$route.params.id;
    },
    canContinue() {
      return (
        this.checkStr(this.areaType) ||
        this.checkStr(this.areaTitle) ||
        this.checkStr(this.locationTag)
      );
    },
  },
  methods: {
    getStageTypeByTitle,
    onFieldChange(fieldName, value) {
      if (fieldName === "allocateSqmBudget" || fieldName === "area") {
        this[fieldName] = Number.parseFloat(value);
      } else {
        this[fieldName] = value;
      }
    },
    getMyScopeById(id) {
      return this.$store.getters["controlRoomModule/getMyScopeById"](id);
    },
    setFiles(newFiles) {
      this.files = newFiles;
    },
    setItemsToAdd(newItems) {
      this.itemsToAdd = newItems;
    },
    onDisciplinesAddBtnClick() {
      this.step = 3;
    },
    setItemsToRemove(items) {
      this.itemsToRemove = items;
    },
    checkStr(str) {
      return str === "";
    },
    backToPrevStep() {
      if (this.step === 1) {
        this.toggleDialog();
      } else {
        this.step = this.step - 1;
      }
    },
    nextStep() {
      if (this.step === 1) {
        this.step = 2;
        return;
      }
      if (this.step === 3) {
        this.itemsToAdd = [
          ...this.itemsToAdd,
          ...this.$refs.addDisciplines.$data.selected.map((i) => {
            if (i.includes("master")) {
              return i.slice(7, i.length);
            } else {
              return i;
            }
          }),
        ];
        this.step = 2;
        return;
      }
      if (this.step === 2) {
        this.onSave();
        Object.assign(this.$data, this.$options.data());

        this.toggleDialog();

        return;
      }
    },
    onSave() {
      let newAreaObj = {
        allocateSqmBudget: this.allocateSqmBudget,
        area: this.area,
        id: this.uuid,
        areaTitle: this.areaTitle,
        areaType: this.areaType,
        briefs: this.getBriefs(),
        consultants: this.getConsultants(),
        locationTag: this.locationTag,
        disciplinesAssigned: this.getDisciplinesAssigned(),
      };

      this.$store.commit("projectsModule/addArea", {
        projectId: this.id,
        // projectId: this.id,
        area: newAreaObj,
      });
      this.uuid = uuid.v1();
    },
    getBriefs() {
      return this.files.map((b) => {
        return {
          file: b.file,
          name: b.name,
        };
      });
    },
    getDisciplinesAssigned() {
      let disciplines = [...this.items, ...this.itemsToAdd];
      disciplines = disciplines.filter((d) => {
        return !this.itemsToRemove.includes(d);
      });
      return disciplines.map((d) => {
        let disciplineobj = JSON.parse(JSON.stringify(this.getMyScopeById(d)));
        return getConsultantDisciplineById(disciplineobj.classification.id);
      });
    },

    getConsultants() {
      let consultants = [...this.items, ...this.itemsToAdd];
      consultants = consultants.filter((c) => {
        if (this.itemsToRemove.indexOf(c) !== -1) {
          return false;
        } else {
          return true;
        }
      });
      consultants = consultants.map((c) => {
        let consultantObj = JSON.parse(JSON.stringify(this.getMyScopeById(c)));
        consultantObj.stages = consultantObj.stages.map((s) => {
          s.deliverables = s.deliverables.filter((d) => {
            return d.status !== "deleted";
          });
          s.deliverables = s.deliverables.map((d) => {
            d.status = "inherited";
            d.isComplete = false;

            d.transmittal = "";
            return d;
          });
          return s;
        });
        consultantObj.additionalClassification = [];
        consultantObj.status = "draft";
        delete consultantObj.value;
        consultantObj.milestones = generateMiletones(
          consultantObj.classification
        );
        consultantObj.stages = consultantObj.stages.map((s) => {
          let uuidForStage = uuid.v1();
          s.id = uuidForStage;
          consultantObj.milestones = consultantObj.milestones.map((m) => {
            if (s.type === this.getStageTypeByTitle(m.title)) {
              m.stageId = uuidForStage;
            }
            return m;
          });

          return s;
        });

        consultantObj.area = [
          {
            id: this.uuid,
            name: this.areaTitle,
          },
        ];
        return consultantObj;
      });
      return consultants;
    },
  },
};
</script>
<style lang="scss" scoped>
.dialog {
  background: white;
  height: 916px;
  flex-direction: column;
  overflow: auto;
  overflow-x: hidden;
  display: flex;
  &__title {
    text-transform: uppercase;
    min-height: 95px;
    width: 100%;
    background-color: var(--v-primary-base) !important;
    display: flex;
    color: white;
    padding-left: 50px;
    align-items: center;
  }
  &_buttons {
    display: flex;
    width: 55%;
    justify-content: center;
    align-items: flex-end;
    align-self: flex-end;
    margin-top: 20px;
    column-gap: 10px;
  }
}
</style>
