<template>
  <div class="table-wrapper">
    <v-simple-table class="users-table">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              First Name
            </th>
            <th class="text-left">
              Last Name
            </th>
            <th class="text-left">
              Email Address
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="user in users"
            :key="user.id"
          >
            <td>{{ user.firstName }}</td>
            <td>{{ user.lastName }}</td>
            <td>{{ user.emailAddress }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <div class="d-flex justify-end">
      <v-dialog v-model="dialog" width="750">
        <div class="dialog-wrapper">
          <div class="icon-close-wrapper pointer" @click="dialog = !dialog">
            <v-icon>
              mdi-close
            </v-icon>
          </div>
          <DialogContent @closeModal="dialog = false" />
        </div>
      </v-dialog>
      <v-btn
        class="ma-2"
        rounded
        @click="dialog = true"
      >
        <v-icon dark>
          mdi-plus
        </v-icon>
        Create User
      </v-btn>
    </div>
  </div>
</template>

<script>
import DialogContent from "@/views/Setup/Users/DialogContent/DialogContent";

export default {
  components: {
    DialogContent
  },
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    users() {
      return this.$store.state.auth.users;
    },
  }
};
</script>

<style scoped lang="scss">
.dialog-wrapper{
  position: relative;
}
.table-wrapper {
  height: 100%;
  .users-table {
    background-color: #fff !important;
    height: calc(100% - 62px);
    overflow: auto;
  }
}
</style>
