var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-simple-table',{staticClass:"theme-white theme--light"},[(_vm.currentView === 'master-suite')?_c('div',{staticClass:"blured-block-text"},[_vm._v(" Buy to see the other "+_vm._s(_vm.items.length - 3)+" "+_vm._s(_vm.tableType === "Scope" ? "scopes" : "deliverables")+" ")]):_vm._e(),_c('thead',[_c('th',{staticClass:"code-header"}),_c('th',{staticClass:"text-left pa-2"},[_c('div',{staticClass:"d-flex justify-space-between c-col"},[_c('div',{staticClass:"d-flex align-center"},[_c('div',[_vm._v(" "+_vm._s(_vm.tableType)+" ")]),(
              _vm.isEditMode &&
                _vm.currentView !== 'master-suite' &&
                _vm.canShowEditActions
            )?_c('div',{staticClass:"ml-2"},[_c('v-btn',{attrs:{"rounded":"","height":"26px","color":"primary"},on:{"click":_vm.onAddClickHadnle}},[_vm._v(" Add+ ")])],1):_vm._e()]),(_vm.currentView !== 'master-suite' && _vm.canShowEditActions)?_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"sub-th"},[(_vm.isEditMode)?_c('div',{staticClass:"title"},[_vm._v(" Remove ")]):_vm._e()]),(
              _vm.tableType === 'Deliverables' &&
                _vm.currentRoute === 'scope-of-works'
            )?_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"sub-th"},[_c('div',{staticClass:"title"},[_vm._v(" Completed ")])]),_c('div',{staticClass:"sub-th"},[_c('div',{staticClass:"title"},[_vm._v(" Transmittal ")])])]):_vm._e()]):_vm._e()])])]),_c('tbody',_vm._l((_vm.items),function(item,i){return _c('tr',{key:("scope-dialog-table-row-" + i),class:_vm.currentView === 'master-suite' && i > 2 && 'blured-block'},[(_vm.tableType === 'Scope')?_c('td',{staticClass:"text-left td-code",class:_vm.checkIsScopeToDelete(item) && 'scope-to-delete'},[_vm._v(" "+_vm._s(item.code)+" ")]):_vm._e(),(_vm.tableType === 'Deliverables')?_c('td',{staticClass:"text-left td-code",class:_vm.checkIsScopeToDelete(item) && 'scope-to-delete'},[_vm._v(" "+_vm._s(item.code)+" ")]):_vm._e(),_c('td',{staticClass:"text-left"},[_c('div',{staticClass:"d-flex justify-space-between c-col"},[_c('div',{staticClass:"d-flex align-center title-row",class:_vm.checkIsScopeToDelete(item) && 'scope-to-delete'},[_c('div',[_vm._v(" "+_vm._s(item.title)+" ")])]),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:" sub-th ml-1 d-flex justify-center checkbox-wrapper"},[(_vm.isEditMode && _vm.canShowEditActions)?_c('div',[_c('v-checkbox',{attrs:{"value":_vm.checkIsScopeToDelete(item),"disabled":_vm.currentView === 'master-suite' && i > 2},on:{"change":function (e) {
                      _vm.setScopeToDelete(e, item);
                    }}})],1):_vm._e()]),(
                _vm.tableType === 'Deliverables' &&
                  _vm.currentRoute === 'scope-of-works' &&
                  _vm.canShowEditActions
              )?_c('div',{staticClass:" sub-th ml-1 d-flex justify-center checkbox-wrapper"},[_c('div',[_c('v-checkbox',{attrs:{"value":item.isComplete},on:{"change":function (e) { return _vm.onDelivarableComplete(e, item); }}})],1)]):_vm._e(),(
                _vm.tableType === 'Deliverables' &&
                  _vm.currentRoute === 'scope-of-works'
              )?_c('div',{staticClass:" sub-th ml-1 d-flex justify-center checkbox-wrapper"},[(_vm.canShowEditActions && !_vm.editContract)?_c('div',{staticClass:"d-flex justify-center pointer"},[(item.transmittal === '')?_c('v-icon',{on:{"click":function($event){return _vm.onTransimalAdd(item)}}},[_vm._v(" mdi-plus ")]):_c('div',{staticClass:"d-flex justify-center",on:{"click":function($event){return _vm.onTransimalEdit(item)}}},[_c('v-icon',[_vm._v(" mdi-open-in-new ")])],1)],1):_vm._e(),(_vm.canShowEditActions && _vm.editContract)?_c('div',{staticClass:"d-flex justify-center pointer"},[(_vm.checkIsTransmittalEmpty(item))?_c('v-icon',{on:{"click":function($event){return _vm.onTransimalAdd(item)}}},[_vm._v(" mdi-plus ")]):_c('div',{staticClass:"d-flex justify-center",on:{"click":function($event){return _vm.onTransimalEdit(item)}}},[_c('v-icon',[_vm._v(" mdi-open-in-new ")])],1)],1):_vm._e()]):_vm._e()])])])])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }