<template>
  <div class="items">
    <div
      v-for="(mainItem, i) in tableControllerItems.mainItems"
      :key="`table-main-item-${i}`"
      class="item pointer"
      :class="mainItem === currentControllerItem && 'active'"
      @click="setItem(mainItem)"
    >
      <div class="d-flex">
        <div>
          {{ i + 1 }}
        </div>
        <div class="ml-1 text-left">
          {{ getStageTypeById(mainItem) }}
        </div>
      </div>

      <div>
        <v-icon v-if="mainItem === currentControllerItem">
          mdi-arrow-right-drop-circle-outline
        </v-icon>
        <v-icon v-else>
          mdi-arrow-down-drop-circle-outline
        </v-icon>
      </div>
    </div>
    <hr>
    <div
      v-for="(subItem, i) in tableControllerItems.subItems"
      :key="`table-sub-item-${i}`"
      class="item pointer sub"
      :class="subItem === currentControllerItem && 'active'"
      @click="setItem(subItem)"
    >
      <div class="ml-1 text-left">
        {{ subItem }}
      </div>
      <div>
        <v-icon v-if="subItem === currentControllerItem">
          mdi-arrow-right-drop-circle-outline
        </v-icon>
        <v-icon v-else>
          mdi-arrow-down-drop-circle-outline
        </v-icon>
      </div>
    </div>
  </div>
</template>
<script>
import { getStageTypeById } from "@/utils/getValueFromJSONById/getValueFromJSONById";

export default {
  name: "ScopeDialogTableController",
  props: {
    tableControllerItems: Object,
    currentControllerItem: String,
  },
  methods: {
    getStageTypeById,

    setItem(val) {
      this.$emit("setControlledItem", val);
    },
  },
};
</script>
<style scoped lang="scss">
.item {
  display: flex;
  align-items: center;
  padding: 3px;
  width: 100%;
  justify-content: space-between;
  white-space: nowrap;
  height: 36px;
  font-size: 14px;
  &.sub {
    height: 60px;
    white-space: normal !important;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  &.active {
    background-color: var(--v-primary-base) !important;
    color: white;
    &::v-deep .theme--light.v-icon {
      color: white;
    }
  }
}
.sub-item {
  white-space: normal !important;
}
</style>
