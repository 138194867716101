<template>
  <div class="sidebar-wrapper">
    <div class="items">
      <div>
        All Projects
      </div>

      <router-link
        v-for="(project, i) in filtredProjects"
        :key="`projects-${i}`"
        :to="getPath(project.id)"
        class="item pointer"
        :class="project.id === id && 'active'"
      >
        {{ project.projectCode }}
        <br>
        {{ project.projectName }}
      </router-link>
    </div>
  </div>
</template>
<script>
import Card from "@/components/Card/Card.vue";

export default {
  name: "Sidebar",
  components: {
    // Card,
  },
  props: ["projects"],
  computed: {
    id() {
      return this.$route.params.id;
    },
    filtredProjects: {
      get: function() {
        return this.$store.state.projectsModule.projects.filter(
          (p) => p.status === "active"
        );
      },
    },
  },
  methods: {
    getPath(id) {
      if (this.$route.name !== "ControllRoom") {
        return { path: `/${id}/${this.$route.name}` };
      } else {
        return { path: `/${id}/dashboard` };
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.sidebar-wrapper {
  height: 100%;
  padding-left: 10px;
  padding-right: 10px;
  .items {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    .item {
      text-decoration: none;
      color: #333333;
      background: whitesmoke;
      &.active {
        background: #333333 !important;
        color: white !important;
      }
    }
  }
}
::v-deep .router-link-active {
  background: #333333 !important;
  color: white !important;
}
.sidebar-wrapper {
  border-right: 0px !important;
}
</style>
